import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import StaffTab from './StaffTab';
import Fieldset from '../../components/UI/Form/Fieldset';
import Button from '../../components/UI/Button';
import SplitDiv from '../../components/UI/Div/SplitDiv';
import FieldsetFile from '../../components/UI/Form/FieldsetFile';
import useInterface from '../../Lib/useInterface';
import InfoDeskFaqQuestions from './InfoDeskFaqQuestions';
import * as StateAction from '../../Redux/actions/State';
import NavButton from '../../components/UI/NavButton';

const InfoDesk = () => {
  const { sendRestErrorMessage, sendMessage } = useInterface();
  const [uploadingData, setUploadingData] = useState({
    tutorialVideo: null,
  });

  const config = useSelector((state) => state.stateDataReducer.config);
  const dispatch = useDispatch();

  return (
    <StaffTab titre="INFO DESK">
      <h2 className="stdSubTitle midMargin-y">Configuration</h2>
      <Formik
        initialValues={{
          whatsappNumberKsa: config.whatsappNumberKsa,
          whatsappNumberOtherRegions: config.whatsappNumberOtherRegions,
          tutorialVideo: config.tutorialVideo,
        }}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            const result = await axios.put('/staff/config', values);
            dispatch({ type: StateAction.SET_CONFIG, config: result.data });

            sendMessage('The data has been saved');
          } catch (err) {
            sendRestErrorMessage(err);
          }
          setSubmitting(false);
        }}

        validationSchema={Yup.object().shape({
          whatsappNumberKsa: Yup.string().required('You must provide this field'),
          whatsappNumberOtherRegions: Yup.string().required('You must provide this field'),
          tutorialVideo: Yup.string().required('You must provide this field').url('You must provide a url'),
        })}
      >
        {({ values, isSubmitting }) => (
          <Form className="stdForm form-100">

            <SplitDiv>
              <Fieldset placeholder="Whatsapp number ksa" name="whatsappNumberKsa">Whatsapp number ksa</Fieldset>
              <Fieldset placeholder="Whatsapp number other regions" name="whatsappNumberOtherRegions">Whatsapp number other regions</Fieldset>
            </SplitDiv>
            <SplitDiv>
              <Fieldset name="tutorialVideo" placeholder="https://www.youtube.com/watch?v=XXXXXXXXXXX">Youtube video</Fieldset>
              <div />
            </SplitDiv>

            <div className="rightAlign stdForm-submitLine">
              <NavButton to="/" variant="transparent">Back to home</NavButton>
              <Button type="submit" disabled={isSubmitting}>Save changes</Button>
            </div>
          </Form>
        )}
      </Formik>

      <hr className="stdHr bigMargin-y" />

      <InfoDeskFaqQuestions />
    </StaffTab>
  );
};

export default InfoDesk;
