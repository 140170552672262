const salutation = (input, withUpper = false, withoutMrMs = false) => {
  if (!input) {
    return '';
  }
  const corrsponspondances = {
    ambassador: 'Ambassador',
    dr: 'Dr.',
    honorable: 'Honorable',
    mayor: 'Mayor',
    minister: 'Minister',
    mr: 'Mr.',
    mrs: 'Mrs.',
    ms: 'Ms.',
    president: 'President',
    professor: 'Professor',
    senator: 'Senator',
    heshaikh: 'H.E. Shaikh',
    'h.e.': 'H.E.',
    'h.r.h.': 'H.R.H.',
    sir: 'Sir',
    'h.h.': 'H.H.',
    hrhprince: 'H.R.H. Prince',
    hedr: 'H.E. Dr.',
    eng: 'Eng.',
    governor: 'Governor',
    hedrshaikh: 'H.E. Dr. Shaikh',
    'he.eng': 'H.E. Eng.',
    'he.mr': 'H.E. Mr.',
  };

  if (!input) {
    return '';
  }

  if (['Mr.', 'Ms.', 'Mrs.'].includes(corrsponspondances[input]) || ['Mr.', 'Ms.', 'Mrs.'].includes(input)) {
    return '';
  }
  if (!corrsponspondances[input]) {
    return withUpper ? input.toUpperCase() : input;
  }
  return withUpper ? corrsponspondances[input].toUpperCase() : corrsponspondances[input];
};

export default salutation;
