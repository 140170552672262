import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Field, Form, getIn } from 'formik';
import clsx from 'clsx';
import Photo from '../Photo';
import FileInput from "./FileInput";

const FieldsetFile = ({
  children, doDelete, extensions, value, onUpload, withPhoto, ...props
}) => {
  const [error, setError] = useState(null);
  return (
    <Field name={name} {...props}>
      {({ field, form }) => (
        <fieldset className={clsx(
          'stdFieldset',
          'stdFieldset-inputFile',
          getIn(form.errors, field.name) && 'stdFieldset-error',
        )}
        >
          <label htmlFor={name} className="stdLabel">{children}</label>

          <div className="stdFieldset-inputFile-inputs">
            <FileInput
              value={value}
              withPhoto={withPhoto}
              doDelete={doDelete}
              onChange={(files) => {
                const file = files[0];

                if (extensions) {
                  const uploadedExtension = file.name.split('.').pop().toLowerCase();
                  if (!extensions.includes(uploadedExtension)) {
                    setError(`The extension you provided (${uploadedExtension}) is not supported. Supported extensions : ${extensions.join(', ')}`);
                    return;
                  }
                }

                onUpload(file);
              }}
            />
          </div>

          { error && <div className="error">{error}</div> }
        </fieldset>
      )}
    </Field>
  );
};

FieldsetFile.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  onUpload: PropTypes.func.isRequired,
  doDelete: PropTypes.func,
  extensions: PropTypes.array,
  value: PropTypes.string,
  withPhoto: PropTypes.bool,
};

FieldsetFile.defaultProps = {
  extensions: null,
  value: null,
  doDelete: null,
  withPhoto: false,
};

export default FieldsetFile;
