import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../components/UI/Button';
import SpeakerDesc from '../../components/UI/SpeakerDesc';
import useRestClient from '../../Lib/useRestClient';
import Chargement from '../../components/UI/Chargement';

const ProgramSpeaker = ({ utilisateurId, onBack, forSpeakers }) => {
  const [utilisateur] = useRestClient(`utilisateurs/${utilisateurId}`);
  if (!utilisateur) {
    return <Chargement height50 />;
  }

  const getAllSessions = () => [...utilisateur.programmeSpeakers, ...utilisateur.programmeModerators].map(({ programme }) => programme).sort((pa, pb) => pa.realDateDebut.valueOf() - pb.realDateDebut.valueOf());

  return (
    <div className="component-programSpeaker">
      <div className="component-programSpeaker-top">
        <SpeakerDesc utilisateur={utilisateur} />
      </div>

      {/*<hr className="stdHr" />*/}
      <div className="component-programSpeaker-bottom">
        <p className="wysiwyg" dangerouslySetInnerHTML={{ __html: utilisateur.description }} />

        <Button icon="back" small className="floatRight stdBackFloatingBtn" onClick={onBack}>{ forSpeakers ? <>Back to speakers</> : <>Back to session</> }</Button>
        <h2>Session(s)</h2>
        { getAllSessions().length ? (
          <div className="component-programSpeaker-session-container">{getAllSessions().map(({
            name, jour, realDateDebut, realDateFin,
          }, key) => (
            <div className="component-programSpeaker-session" key={key}>
              <h3>{name}</h3>
              <div>DAY {jour + 1} - {realDateDebut.toFormat('DD HH:mm')} to {realDateFin.toFormat('HH:mm')}</div>
            </div>
          ))}
          </div>
        ) : <div>No session upcoming</div>}

      </div>
    </div>
  );
};

ProgramSpeaker.propTypes = {
  utilisateurId: PropTypes.number.isRequired,
  onBack: PropTypes.func.isRequired,
  forSpeakers: PropTypes.bool,
};

ProgramSpeaker.defaultProps = {
  forSpeakers: false,
};
export default ProgramSpeaker;
