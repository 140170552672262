import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Carousel from '../../components/UI/Carousel';
import img1 from '../../../img/FII/Presentation/1.jpg';
import img2 from '../../../img/FII/Presentation/2.jpg';
import img3 from '../../../img/FII/Presentation/3.jpg';
import img4 from '../../../img/FII/Presentation/4.jpg';
import img5 from '../../../img/FII/Presentation/5.jpg';
import img6 from '../../../img/FII/Presentation/6.jpg';
import img7 from '../../../img/FII/Presentation/7.jpg';
import img8 from '../../../img/FII/Presentation/8.jpg';
import img9 from '../../../img/FII/Presentation/9.jpg';
import img10 from '../../../img/FII/Presentation/10.jpg';
import img11 from '../../../img/FII/Presentation/11.jpg';
import img12 from '../../../img/FII/Presentation/12.jpg';
import img13 from '../../../img/FII/Presentation/13.jpg';
import img14 from '../../../img/FII/Presentation/14.jpg';
import img15 from '../../../img/FII/Presentation/15.jpg';
import img16 from '../../../img/FII/Presentation/16.jpg';
import img17 from '../../../img/FII/Presentation/17.jpg';
import img18 from '../../../img/FII/Presentation/18.jpg';
import img19 from '../../../img/FII/Presentation/19.jpg';
import img20 from '../../../img/FII/Presentation/20.jpg';
import img21 from '../../../img/FII/Presentation/21.jpg';
import img22 from '../../../img/FII/Presentation/22.jpg';
import img23 from '../../../img/FII/Presentation/23.jpg';
import imgHealth01 from '../../../img/FII/Presentation/Healthcare/01.jpg';
import imgHealth02 from '../../../img/FII/Presentation/Healthcare/02.jpg';
import imgHealth03 from '../../../img/FII/Presentation/Healthcare/03.jpg';
import imgHealth04 from '../../../img/FII/Presentation/Healthcare/04.jpg';
import imgHealth05 from '../../../img/FII/Presentation/Healthcare/05.jpg';
import imgHealth06 from '../../../img/FII/Presentation/Healthcare/06.jpg';
import imgHealth07 from '../../../img/FII/Presentation/Healthcare/07.jpg';
import imgHealth08 from '../../../img/FII/Presentation/Healthcare/08.jpg';
import imgHealth09 from '../../../img/FII/Presentation/Healthcare/09.jpg';
import imgHealth10 from '../../../img/FII/Presentation/Healthcare/10.jpg';
import imgHealth11 from '../../../img/FII/Presentation/Healthcare/11.jpg';
import imgHealth12 from '../../../img/FII/Presentation/Healthcare/12.jpg';
import imgHealth13 from '../../../img/FII/Presentation/Healthcare/13.jpg';
import imgHealth14 from '../../../img/FII/Presentation/Healthcare/14.jpg';
import imgHealth15 from '../../../img/FII/Presentation/Healthcare/15.jpg';
import imgHealth16 from '../../../img/FII/Presentation/Healthcare/16.jpg';
import imgHealth17 from '../../../img/FII/Presentation/Healthcare/17.jpg';
import imgHealth18 from '../../../img/FII/Presentation/Healthcare/18.jpg';
import imgHealth19 from '../../../img/FII/Presentation/Healthcare/19.jpg';
import imgHealth20 from '../../../img/FII/Presentation/Healthcare/20.jpg';
import imgHealth21 from '../../../img/FII/Presentation/Healthcare/21.jpg';
import imgHealth22 from '../../../img/FII/Presentation/Healthcare/22.jpg';
import imgHealth23 from '../../../img/FII/Presentation/Healthcare/23.jpg';
import imgHealth24 from '../../../img/FII/Presentation/Healthcare/24.jpg';
import imgHealth25 from '../../../img/FII/Presentation/Healthcare/25.jpg';
import imgHealth26 from '../../../img/FII/Presentation/Healthcare/26.jpg';
import imgHealth27 from '../../../img/FII/Presentation/Healthcare/27.jpg';
import imgHealth28 from '../../../img/FII/Presentation/Healthcare/28.jpg';
import imgHealth29 from '../../../img/FII/Presentation/Healthcare/29.jpg';
import imgHealth30 from '../../../img/FII/Presentation/Healthcare/30.jpg';
import imgHealth31 from '../../../img/FII/Presentation/Healthcare/31.jpg';
import imgHealth32 from '../../../img/FII/Presentation/Healthcare/32.jpg';
import imgHealth33 from '../../../img/FII/Presentation/Healthcare/33.jpg';
import imgHealth34 from '../../../img/FII/Presentation/Healthcare/34.jpg';
import imgHealth35 from '../../../img/FII/Presentation/Healthcare/35.jpg';
import imgHealth36 from '../../../img/FII/Presentation/Healthcare/36.jpg';
import imgHealth37 from '../../../img/FII/Presentation/Healthcare/37.jpg';
import imgHealth38 from '../../../img/FII/Presentation/Healthcare/38.jpg';
import imgHealth39 from '../../../img/FII/Presentation/Healthcare/39.jpg';
import imgHealth40 from '../../../img/FII/Presentation/Healthcare/40.jpg';
import imgHealth41 from '../../../img/FII/Presentation/Healthcare/41.jpg';
import imgHealth42 from '../../../img/FII/Presentation/Healthcare/42.jpg';
import imgHealth43 from '../../../img/FII/Presentation/Healthcare/43.jpg';
import imgHealth44 from '../../../img/FII/Presentation/Healthcare/44.jpg';
import imgHealth45 from '../../../img/FII/Presentation/Healthcare/45.jpg';
import imgHealth46 from '../../../img/FII/Presentation/Healthcare/46.jpg';
import imgHealth47 from '../../../img/FII/Presentation/Healthcare/47.jpg';
import imgHealth48 from '../../../img/FII/Presentation/Healthcare/48.jpg';
import imgHealth49 from '../../../img/FII/Presentation/Healthcare/49.jpg';
import imgHealth50 from '../../../img/FII/Presentation/Healthcare/50.jpg';
import imgHealth51 from '../../../img/FII/Presentation/Healthcare/51.jpg';
import imgHealth52 from '../../../img/FII/Presentation/Healthcare/52.jpg';
import imgHealth53 from '../../../img/FII/Presentation/Healthcare/53.jpg';
import imgHealth54 from '../../../img/FII/Presentation/Healthcare/54.jpg';
import imgHealth55 from '../../../img/FII/Presentation/Healthcare/55.jpg';
import imgHealth56 from '../../../img/FII/Presentation/Healthcare/56.jpg';
import imgHealth57 from '../../../img/FII/Presentation/Healthcare/57.jpg';

import ButtonClose from '../../components/UI/ButtonClose';
import useBackButton from '../../Lib/useBackButton';

const InstitutePresentation = ({ healthcareReport, onClose }) => {
  useBackButton(onClose);

  return (
    <div className={clsx('component-library component-institutePresentation')}>
      <ButtonClose backgrounded onClick={onClose} />
      <div>

        { !healthcareReport ? (
          <Carousel count={1}>
            <div className="component-institutePresentation-el"><img src={img1} /></div>
            <div className="component-institutePresentation-el"><img src={img2} /></div>
            <div className="component-institutePresentation-el"><img src={img3} /></div>
            <div className="component-institutePresentation-el"><img src={img4} /></div>
            <div className="component-institutePresentation-el"><img src={img5} /></div>
            <div className="component-institutePresentation-el"><img src={img6} /></div>
            <div className="component-institutePresentation-el"><img src={img7} /></div>
            <div className="component-institutePresentation-el"><img src={img8} /></div>
            <div className="component-institutePresentation-el"><img src={img9} /></div>
            <div className="component-institutePresentation-el"><img src={img10} /></div>
            <div className="component-institutePresentation-el"><img src={img11} /></div>
            <div className="component-institutePresentation-el"><img src={img12} /></div>
            <div className="component-institutePresentation-el"><img src={img13} /></div>
            <div className="component-institutePresentation-el"><img src={img14} /></div>
            <div className="component-institutePresentation-el"><img src={img15} /></div>
            <div className="component-institutePresentation-el"><img src={img16} /></div>
            <div className="component-institutePresentation-el"><img src={img17} /></div>
            <div className="component-institutePresentation-el"><img src={img18} /></div>
            <div className="component-institutePresentation-el"><img src={img19} /></div>
            <div className="component-institutePresentation-el"><img src={img20} /></div>
            <div className="component-institutePresentation-el"><img src={img21} /></div>
            <div className="component-institutePresentation-el"><img src={img22} /></div>
            <div className="component-institutePresentation-el"><img src={img23} /></div>
          </Carousel>
        ) : (
          <Carousel count={1}>
            <div className="component-institutePresentation-el"><img src={imgHealth01} /></div>
            <div className="component-institutePresentation-el"><img src={imgHealth02} /></div>
            <div className="component-institutePresentation-el"><img src={imgHealth03} /></div>
            <div className="component-institutePresentation-el"><img src={imgHealth04} /></div>
            <div className="component-institutePresentation-el"><img src={imgHealth05} /></div>
            <div className="component-institutePresentation-el"><img src={imgHealth06} /></div>
            <div className="component-institutePresentation-el"><img src={imgHealth07} /></div>
            <div className="component-institutePresentation-el"><img src={imgHealth08} /></div>
            <div className="component-institutePresentation-el"><img src={imgHealth09} /></div>
            <div className="component-institutePresentation-el"><img src={imgHealth10} /></div>
            <div className="component-institutePresentation-el"><img src={imgHealth11} /></div>
            <div className="component-institutePresentation-el"><img src={imgHealth12} /></div>
            <div className="component-institutePresentation-el"><img src={imgHealth13} /></div>
            <div className="component-institutePresentation-el"><img src={imgHealth14} /></div>
            <div className="component-institutePresentation-el"><img src={imgHealth15} /></div>
            <div className="component-institutePresentation-el"><img src={imgHealth16} /></div>
            <div className="component-institutePresentation-el"><img src={imgHealth17} /></div>
            <div className="component-institutePresentation-el"><img src={imgHealth18} /></div>
            <div className="component-institutePresentation-el"><img src={imgHealth19} /></div>
            <div className="component-institutePresentation-el"><img src={imgHealth20} /></div>
            <div className="component-institutePresentation-el"><img src={imgHealth21} /></div>
            <div className="component-institutePresentation-el"><img src={imgHealth22} /></div>
            <div className="component-institutePresentation-el"><img src={imgHealth23} /></div>
            <div className="component-institutePresentation-el"><img src={imgHealth24} /></div>
            <div className="component-institutePresentation-el"><img src={imgHealth25} /></div>
            <div className="component-institutePresentation-el"><img src={imgHealth26} /></div>
            <div className="component-institutePresentation-el"><img src={imgHealth27} /></div>
            <div className="component-institutePresentation-el"><img src={imgHealth28} /></div>
            <div className="component-institutePresentation-el"><img src={imgHealth29} /></div>
            <div className="component-institutePresentation-el"><img src={imgHealth30} /></div>
            <div className="component-institutePresentation-el"><img src={imgHealth31} /></div>
            <div className="component-institutePresentation-el"><img src={imgHealth32} /></div>
            <div className="component-institutePresentation-el"><img src={imgHealth33} /></div>
            <div className="component-institutePresentation-el"><img src={imgHealth34} /></div>
            <div className="component-institutePresentation-el"><img src={imgHealth35} /></div>
            <div className="component-institutePresentation-el"><img src={imgHealth36} /></div>
            <div className="component-institutePresentation-el"><img src={imgHealth37} /></div>
            <div className="component-institutePresentation-el"><img src={imgHealth38} /></div>
            <div className="component-institutePresentation-el"><img src={imgHealth39} /></div>
            <div className="component-institutePresentation-el"><img src={imgHealth40} /></div>
            <div className="component-institutePresentation-el"><img src={imgHealth41} /></div>
            <div className="component-institutePresentation-el"><img src={imgHealth42} /></div>
            <div className="component-institutePresentation-el"><img src={imgHealth43} /></div>
            <div className="component-institutePresentation-el"><img src={imgHealth44} /></div>
            <div className="component-institutePresentation-el"><img src={imgHealth45} /></div>
            <div className="component-institutePresentation-el"><img src={imgHealth46} /></div>
            <div className="component-institutePresentation-el"><img src={imgHealth47} /></div>
            <div className="component-institutePresentation-el"><img src={imgHealth48} /></div>
            <div className="component-institutePresentation-el"><img src={imgHealth49} /></div>
            <div className="component-institutePresentation-el"><img src={imgHealth50} /></div>
            <div className="component-institutePresentation-el"><img src={imgHealth51} /></div>
            <div className="component-institutePresentation-el"><img src={imgHealth52} /></div>
            <div className="component-institutePresentation-el"><img src={imgHealth53} /></div>
            <div className="component-institutePresentation-el"><img src={imgHealth54} /></div>
            <div className="component-institutePresentation-el"><img src={imgHealth55} /></div>
            <div className="component-institutePresentation-el"><img src={imgHealth56} /></div>
            <div className="component-institutePresentation-el"><img src={imgHealth57} /></div>
          </Carousel>
        )}
      </div>
    </div>
  );
};

InstitutePresentation.propTypes = {
  onClose: PropTypes.func.isRequired,
  healthcareReport: PropTypes.bool,
};

InstitutePresentation.defaultProps = {
  healthcareReport: false,
};

export default InstitutePresentation;
