import React, { useState } from 'react';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import { Form, Formik } from 'formik';
import axios from 'axios';
import * as Yup from 'yup';
import useInterface from '../../Lib/useInterface';
import useRestClient from '../../Lib/useRestClient';
import Chargement from '../../components/UI/Chargement';
import Button from '../../components/UI/Button';
import FieldsetTextarea from '../../components/UI/Form/FieldsetTextarea';
import FieldsetFile from '../../components/UI/Form/FieldsetFile';
import FieldsetSelect from '../../components/UI/Form/FieldsetSelect';
import { arNotificationMessageTypes } from '../../Lib/NotificationMessageType';

const EditNotificationMessage = ({ notificationMessageId, onCancel, onSuccess }) => {
  const { sendRestErrorMessage, sendMessage } = useInterface();


  const [notificationMessage] = notificationMessageId ? useRestClient(`notification-messages/${notificationMessageId}`) : [{
    type: null,
    content: '',
  }];
  if (!notificationMessage) {
    return <Chargement />;
  }

  return (
    <Formik
      initialValues={notificationMessage}
      enableReinitialize
      onSubmit={async (values, { setSubmitting }) => {
        try {
          if (notificationMessageId) {
            await axios.patch(`/staff/notification-messages/${notificationMessageId}`, values);
            sendMessage('The message has been modified');
          } else {
            await axios.post('/staff/notification-messages', values);
            sendMessage('The message has been created');
          }
          onSuccess();
        } catch (err) {
          sendRestErrorMessage(err);
        }
        setSubmitting(false);
      }}

      validationSchema={Yup.object().shape({
        type: Yup.string().required('You must provide this field').typeError('You must provide this field'),
        content: Yup.string().required('You must provide this field'),
      })}
    >
      {({ values, setFieldValue, isSubmitting }) => (
        <Form className="stdForm form-100">

          <FieldsetSelect  name="type" options={arNotificationMessageTypes}>Type</FieldsetSelect>
          <FieldsetTextarea placeholder="Content" name="content">Content</FieldsetTextarea>

          <div className="rightAlign stdForm-submitLine">
            <Button onClick={onCancel} variant="transparent">Back to messages list</Button>
            <Button type="submit" disabled={isSubmitting}>Save and quit</Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

EditNotificationMessage.propTypes = {
  notificationMessageId: PropTypes.number,
  onSuccess: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};
EditNotificationMessage.defaultProps = {
  notificationMessageId: null,
};
export default EditNotificationMessage;
