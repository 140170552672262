import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Carousel from '../../components/UI/Carousel';
import pic1 from '../../../img/FII/Quotes/1---John-Zhao.jpg';
import pic2 from '../../../img/FII/Quotes/2---Prime-Minister-Narenda.jpg';
import pic3 from '../../../img/FII/Quotes/3---Lubna-Olayan.jpg';
import pic4 from '../../../img/FII/Quotes/4---H.E.-Uhuru-Kenyatta.jpg';
import pic5 from '../../../img/FII/Quotes/5---Stephen-A.-Schwarzman.jpg';
import pic6 from '../../../img/FII/Quotes/6---Dr.-Won-Pyo-Hong.jpg';
import pic7 from '../../../img/FII/Quotes/7---Nasser-Sulaiman-Al--Nasser.jpg';
import pic8 from '../../../img/FII/Quotes/8---Anand-Mahindra.jpg';
import pic9 from '../../../img/FII/Quotes/9---Omar-han.jpg';
import pic10 from '../../../img/FII/Quotes/10---Richard-Arnold.jpg';
import pic11 from '../../../img/FII/Quotes/11---Chris-Dercon.jpg';
import pic12 from '../../../img/FII/Quotes/12---will.i.am.jpg';
import pic13 from '../../../img/FII/Quotes/13---HRH-Prince-Faisal-bin-Bandar-bin-Sultan-Al-Saud.jpg';
import pic14 from '../../../img/FII/Quotes/14---Sam-Kulkarni.jpg';
import pic15 from '../../../img/FII/Quotes/15---Charles-Boorady.jpg';
import pic16 from '../../../img/FII/Quotes/16---Dr.-Ali-Parsa.jpg';
import pic17 from '../../../img/FII/Quotes/17---Larry-Fink.jpg';
import pic18 from '../../../img/FII/Quotes/18---Yousef-Al-Benyan.jpg';
import pic19 from '../../../img/FII/Quotes/19---HE-Khaldoon-Khalifa-Al-Mubarak.jpg';
import pic20 from '../../../img/FII/Quotes/20---Alireza-Zaimi.jpg';
import pic21 from '../../../img/FII/Quotes/21---Ben-Way.jpg';
import pic22 from '../../../img/FII/Quotes/22---Jacques-Atalli.jpg';
import pic23 from '../../../img/FII/Quotes/23---Dr.-Sahar-Nasr.jpg';
import pic25 from '../../../img/FII/Quotes/25---Taavi-Kotka.jpg';
import pic26 from '../../../img/FII/Quotes/26---Iyinoluwa-Aboyeji.jpg';
import pic27 from '../../../img/FII/Quotes/27---Frederic-Chesnais.jpg';
import pic28 from '../../../img/FII/Quotes/28---Rolf-Najork.jpg';
import pic29 from '../../../img/FII/Quotes/29---H.E.-Ueli-Maurer.jpg';

import logo from '../../../img/FII2/logoSmall.svg';
import ButtonClose from '../../components/UI/ButtonClose';
import useBackButton from '../../Lib/useBackButton';

const LibraryQuotes = ({ onClose }) => {
  useBackButton(onClose);
  return (
    <div className={clsx('component-library component-library-withCarousel component-libraryQuotes')}>
      <ButtonClose backgrounded onClick={onClose} />
      <div>
        <div><img src={logo} /></div>
        <Carousel count={1}>
          <div className="component-libraryQuotes-el">
            <div><img src={pic1} /></div>
            <div>
              <h2 className="stdTitle stdGradientedText"><span>John Zhao</span></h2>
              <hr className="stdHr stdHr-gradiented" />
              <h4><span>Founder, Chairman & CEO</span>of Hony Capital Ltd </h4>
              <p>The rise of China is changing the world, so much so that there is a lot of geopolitical tension as a result of this rise. And that will continue.</p>
            </div>
          </div>
          <div className="component-libraryQuotes-el">
            <div><img src={pic2} /></div>
            <div>
              <h2 className="stdTitle stdGradientedText"><span>Narenda Modi </span></h2>
              <hr className="stdHr stdHr-gradiented" />
              <h4><span>Prime Minister <span>of India</span></span></h4>
              <p>Today the world has changed. Today we live in a multipolar world. Today each and every country is interconnected.</p>
            </div>
          </div>
          <div className="component-libraryQuotes-el">
            <div><img src={pic3} /></div>
            <div>
              <h2 className="stdTitle stdGradientedText"><span>Lubna Olayan</span></h2>
              <hr className="stdHr stdHr-gradiented" />
              <h4><span>Chairman, </span>Olayan Financing Company</h4>
              <p>Diversity is very important because it allows different thinking and any effective leadership requires input from various parts of the society.</p>
            </div>
          </div>
          <div className="component-libraryQuotes-el">
            <div><img src={pic4} /></div>
            <div>
              <h2 className="stdTitle stdGradientedText"><span>H.E. Uhuru Kenyatta </span></h2>
              <hr className="stdHr stdHr-gradiented" />
              <h4><span>President <span>of Kenya</span></span></h4>
              <p>Through integration, through creating a common market, through bringing our people together, we are able to create an abundance of opportunities.</p>
            </div>
          </div>
          <div className="component-libraryQuotes-el">
            <div><img src={pic5} /></div>
            <div>
              <h2 className="stdTitle stdGradientedText"><span>Stephen A. Schwarzman</span></h2>
              <hr className="stdHr stdHr-gradiented" />
              <h4><span>Chairman, CEO, & Co-Founder</span>The Blackstone Group L.P.</h4>
              <p>Now whenever you make an investment, you look to see whether that company's business model is resilient, knowing it's going to be challenged.</p>
            </div>
          </div>
          <div className="component-libraryQuotes-el">
            <div><img src={pic6} /></div>
            <div>
              <h2 className="stdTitle stdGradientedText"><span>Dr. Won-Pyo Hong</span></h2>
              <hr className="stdHr stdHr-gradiented" />
              <h4><span>President & CEO, </span> Samsung SDS </h4>
              <p>Among a number of new and disruptive technologies, in our view, AI, 5G and IoT will have a significant influence in almost every industry. So they’re the top priority to focus on for Samsung.</p>
            </div>
          </div>
          <div className="component-libraryQuotes-el">
            <div><img src={pic7} /></div>
            <div>
              <h2 className="stdTitle stdGradientedText"><span>Nasser Sulaiman Al-Nasser</span></h2>
              <hr className="stdHr stdHr-gradiented" />
              <h4><span>Group CEO, </span>Saudi Telecom Co</h4>
              <p>If you take the 5G with the cloud with multi-access edge computing, it will revolutionize almost everything we do in life today.</p>
            </div>
          </div>
          <div className="component-libraryQuotes-el">
            <div><img src={pic8} /></div>
            <div>
              <h2 className="stdTitle stdGradientedText"><span>Anand Mahindra</span></h2>
              <hr className="stdHr stdHr-gradiented" />
              <h4><span>Chairman, </span>Mahindra Group</h4>
              <p>Precision agriculture, whether it’s through drones or autonomous tractors, [is] an enormous area of opportunity as the world is going to struggle to feed itself.</p>
            </div>
          </div>
          <div className="component-libraryQuotes-el">
            <div><img src={pic9} /></div>
            <div>
              <h2 className="stdTitle stdGradientedText"><span>Omar Khan</span></h2>
              <hr className="stdHr stdHr-gradiented" />
              <h4><span>Chief Product Officer </span>of Magic Leap</h4>
              <p>Spatial computing is also for consumers.... It will revolutionize how they communicate, how they’re productive, and how they’re entertained, while being free of all these screens in their lives. We imagine a world without screens very, very soon.</p>
            </div>
          </div>
          <div className="component-libraryQuotes-el">
            <div><img src={pic10} /></div>
            <div>
              <h2 className="stdTitle stdGradientedText"><span>Richard Arnold</span></h2>
              <hr className="stdHr stdHr-gradiented" />
              <h4><span>Managing Director </span>of Manchester United Group</h4>
              <p>If you went back to [Manchester United's] founding in 1878 with railway workers watching a game on a Saturday morning, they paid at the gate and we didn’t know who they are. Now we have over 1 billion fans around the world ... We're directly in touch with 200 million of them. Technology has totally changed how we engage with the fans.</p>
            </div>
          </div>
          <div className="component-libraryQuotes-el">
            <div><img src={pic11} /></div>
            <div>
              <h2 className="stdTitle stdGradientedText"><span>Chris Dercon</span></h2>
              <hr className="stdHr stdHr-gradiented" />
              <h4><span>President, </span>RMN- Grand Palais</h4>
              <p>The more we live in the hyper- present, the more we need culture. Culture is about bridging differences. We all know in the most difficult times of our lives, of our societies ... [we need to] let culture <speak className=""></speak></p>
            </div>
          </div>
          <div className="component-libraryQuotes-el">
            <div><img src={pic12} /></div>
            <div>
              <h2 className="stdTitle stdGradientedText"><span>will.i.am</span></h2>
              <hr className="stdHr stdHr-gradiented" />
              <h4><span>Founder & CEO, </span>I.AM.PLUS</h4>
              <p>I’m not a musician from a Quincy Jones standpoint. My instrument is the computer. And the computer changed my <life className=""></life></p>
            </div>
          </div>
          <div className="component-libraryQuotes-el">
            <div><img src={pic13} /></div>
            <div>
              <h2 className="stdTitle stdGradientedText"><span>HRH Prince Faisal bin Bandar bin Sultan Al Saud</span></h2>
              <hr className="stdHr stdHr-gradiented" />
              <h4><span>President </span>of Saudi Arabian Federation for Electronic and Intellectual Sports</h4>
              <p>What differentiates e-sports from traditional sports is the fan engagement. ... With e- sports, you have a personal interaction. You can sometimes play against these guys <online className=""></online></p>
            </div>
          </div>
          <div className="component-libraryQuotes-el">
            <div><img src={pic14} /></div>
            <div>
              <h2 className="stdTitle stdGradientedText"><span>Sam Kulkarni</span></h2>
              <hr className="stdHr stdHr-gradiented" />
              <h4><span>CEO, </span>CRISPR Therapeutics AG</h4>
              <p>[Gene editing] is probably once-in- a-generation technology that we see every 30 or 40 years that could completely transform the health system and has the potential to transform <humanity className=""></humanity></p>
            </div>
          </div>
          <div className="component-libraryQuotes-el">
            <div><img src={pic15} /></div>
            <div>
              <h2 className="stdTitle stdGradientedText"><span>Charles Boorady</span></h2>
              <hr className="stdHr stdHr-gradiented" />
              <h4><span>Founding Managing Director,</span>Health Catalyst</h4>
              <p>How optimism can reshape the business landscape globally is something that we’re often asked as healthcare investors because, of course, a lot of opportunity in health care requires optimism and investment in technology and the <future className=""></future></p>
            </div>
          </div>
          <div className="component-libraryQuotes-el">
            <div><img src={pic16} /></div>
            <div>
              <h2 className="stdTitle stdGradientedText"><span>Dr. Ali Parsa</span></h2>
              <hr className="stdHr stdHr-gradiented" />
              <h4><span>Founder & CEO, </span>Babylon Health</h4>
              <p>Today with the technology we have, it is possible to make healthcare accessible, affordable and put it in the hands of every human being on <earth className=""></earth></p>
            </div>
          </div>
          <div className="component-libraryQuotes-el">
            <div><img src={pic17} /></div>
            <div>
              <h2 className="stdTitle stdGradientedText"><span>Larry Fink</span></h2>
              <hr className="stdHr stdHr-gradiented" />
              <h4><span>Founder Chairman & CEO</span>of BlackRock, Inc.</h4>
              <p>There is no question that there is going to be a greater need to focus on how to elevate more [people] to achieve more inclusive capitalism. ... If capitalism and businesses don't try to solve some of these issues in their own companies, we're going to have government telling us what to <do className=""></do></p>
            </div>
          </div>
          <div className="component-libraryQuotes-el">
            <div><img src={pic18} /></div>
            <div>
              <h2 className="stdTitle stdGradientedText"><span>Yousef Al-Benyan</span></h2>
              <hr className="stdHr stdHr-gradiented" />
              <h4><span>Vice Chairman & CEO,</span>SABIC</h4>
              <p>At the end of the day, we need to look for the bottom line. But in addition to the bottom line, you need to bring something that can bring wider benefits to society, to environments, and the wider <economy className=""></economy></p>
            </div>
          </div>
          <div className="component-libraryQuotes-el">
            <div><img src={pic19} /></div>
            <div>
              <h2 className="stdTitle stdGradientedText"><span>HE Khaldoon Khalifa Al Mubarak</span></h2>
              <hr className="stdHr stdHr-gradiented" />
              <h4><span>CEO & Managing Director, </span>Mubadala Investment Company</h4>
              <p>What we’re doing in the short- term... is looking at mechanisms to invest with downside protection, mechanisms that give us the ability to manage through potential downturns in an effective, risk-adjusted <way className=""></way></p>
            </div>
          </div>
          <div className="component-libraryQuotes-el">
            <div><img src={pic20} /></div>
            <div>
              <h2 className="stdTitle stdGradientedText"><span>Alireza Zaimi</span></h2>
              <hr className="stdHr stdHr-gradiented" />
              <h4><span>Executive Director, Head of Corporate Finance,</span>Public Investment Fund</h4>
              <p>We see three main global concerns: a slow down of economies across the world, the disruption of technology, and increased awareness about climate change. These are really the three main themes that come out of the discussions that we have internally and with our partners across the <world className=""></world></p>
            </div>
          </div>
          <div className="component-libraryQuotes-el">
            <div><img src={pic21} /></div>
            <div>
              <h2 className="stdTitle stdGradientedText"><span>Ben Way</span></h2>
              <hr className="stdHr stdHr-gradiented" />
              <h4><span>CEO, </span>Macquarie Group Asia</h4>
              <p>The investment price point of infrastructure is being brought down so it’s more accessible to communities that perhaps couldn’t afford it, and it’s much more nimble.</p>
            </div>
          </div>
          <div className="component-libraryQuotes-el">
            <div><img src={pic22} /></div>
            <div>
              <h2 className="stdTitle stdGradientedText"><span>Jacques Atalli</span></h2>
              <hr className="stdHr stdHr-gradiented" />
              <h4><span>President, </span>Positive Planet Foundation</h4>
              <p>There are a lot of positive things happening. Positive doesn’t mean optimistic... It means that it's possible to succeed... and useful for the next generations. ... If we were doing what was good for the next generations 20 years ago, the world would be better today.</p>
            </div>
          </div>
          <div className="component-libraryQuotes-el">
            <div><img src={pic23} /></div>
            <div>
              <h2 className="stdTitle stdGradientedText"><span>Dr. Sahar Nasr</span></h2>
              <hr className="stdHr stdHr-gradiented" />
              <h4><span>Minister of Investment and International Cooperation </span> of Egypt (2017- 2019)</h4>
              <p>
                  Empowering women is critical if you really want to achieve sustainable growth... We are more than 50% of the population.</p>
            </div>
          </div>
          <div className="component-libraryQuotes-el">
            <div><img src={pic25} /></div>
            <div>
              <h2 className="stdTitle stdGradientedText"><span>Taavi Kotka</span></h2>
              <hr className="stdHr stdHr-gradiented" />
              <h4><span>Former CIO <span>of Estonia; </span></span><span> Founder, <span>Proud Engineers LLC</span></span></h4>
              <p>You can’t build a digital society alone. It can't only be government, and it can’t only be private sector. There has to be a very strong relationship between the private sector and the government to build the society together.</p>
            </div>
          </div>
          <div className="component-libraryQuotes-el">
            <div><img src={pic26} /></div>
            <div>
              <h2 className="stdTitle stdGradientedText"><span>Iyinoluwa Aboyeji</span></h2>
              <hr className="stdHr stdHr-gradiented" />
              <h4><span>Co-founder & Director, <span>Flutterwave</span></span> <span>Founder, <span>Andela</span></span></h4>
              <p>The beautiful thing is that because these are internet jobs, they work out of Lagos, Nigeria, and they can serve companies all over the world.</p>
            </div>
          </div>
          <div className="component-libraryQuotes-el">
            <div><img src={pic27} /></div>
            <div>
              <h2 className="stdTitle stdGradientedText"><span>Frédéric Chesnais </span></h2>
              <hr className="stdHr stdHr-gradiented" />
              <h4><span>CEO <span>of Atari</span></span></h4>
              <p>I see us with a very significant presence and community online, with a blockchain technology that will be made available to those who are connected.</p>
            </div>
          </div>
          <div className="component-libraryQuotes-el">
            <div><img src={pic28} /></div>
            <div>
              <h2 className="stdTitle stdGradientedText"><span>Rolf Najork </span></h2>
              <hr className="stdHr stdHr-gradiented" />
              <h4><span>Board of Management & CEO,</span> Bosch Rexroth AG</h4>
              <p> I think the blockchain technology will help us improve the sustainability of banking and other industries.</p>
            </div>
          </div>
          <div className="component-libraryQuotes-el">
            <div><img src={pic29} /></div>
            <div>
              <h2 className="stdTitle stdGradientedText"><span>H.E. Ueli Maurer </span></h2>
              <hr className="stdHr stdHr-gradiented" />
              <h4><span>President <span>of the Swiss Federation</span></span></h4>
              <p>Today, with Industry 4.0 and total connectivity of the 5G net, we know what every machine [in the factory] is doing at any point in time. And that will allow us to go to completely different business models.</p>
            </div>
          </div>

        </Carousel>
      </div>
    </div>
  );
};































LibraryQuotes.propTypes = {
  onClose: PropTypes.func.isRequired,
};

LibraryQuotes.defaultProps = {};

export default LibraryQuotes;
