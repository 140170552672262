const truncate = (input, length, showEllipse = true) => {
  if (!input) {
    return null;
  }
  if (input.length > length) {
    return `${input.substring(0, length)}${showEllipse ? '...' : ''}`;
  }
  return input;
};

export default truncate;
