const MgfVideoType = {
  VIDEO: 'VIDEO',
  REPLAY: 'REPLAY',
  EWC: 'EWC',
};

const arMgfVideoTypes = [
  { value: MgfVideoType.VIDEO, label: 'Video' },
  { value: MgfVideoType.REPLAY, label: 'Replay' },
  { value: MgfVideoType.EWC, label: 'Ewc' },
];

export { arMgfVideoTypes };
export default MgfVideoType;
