import { useEffect } from 'react';

const useGetMessage = (onMessageReceive, dependencies = []) => {
  useEffect(() => {
    function getPostMessageTarget() {
      return '*';
    }

    window.getPostMessageTarget = getPostMessageTarget;

    window.addEventListener('message', onMessageReceive, false);
    return () => {
      window.removeEventListener('message', onMessageReceive);
    };
  }, dependencies);
};

export default useGetMessage;
