import React, { useState, useEffect, useRef } from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import Panorama, {
  PANORAMA_PAGE_INSTITUTE_AI,
  PANORAMA_PAGE_INSTITUTE_ROBOTICS,
  PANORAMA_PAGE_INSTITUTE_SUSTAINABILITY,
  PANORAMA_PAGE_LIBRARY_CONTENTCOFFEETAB,
  PANORAMA_PAGE_LIBRARY_QUOTES,
  PANORAMA_PAGE_LIBRARY_VIDEOGALLERY,
  PANORAMA_PAGE_LOBBY_FIIINSTUTELOUNGE,
  PANORAMA_PAGE_LOBBY_HELPDESK,
  PANORAMA_PAGE_LOBBY_WELCOME,
  PANORAMA_PAGE_LIBRARY_HEALTHCARE,
  PANORAMA_PAGE_LIBRARY_PRESENTATION,
  PANORAMA_PAGE_LOBBY_LOUNGE,
  PANORAMA_PAGE_LIBRARY_REPORTS,
  PANORAMA_PAGE_LIBRARY_MAGAZINE,
  PANORAMA_PAGE_LIBRARY_ESG, PANORAMA_PAGE_LIBRARY_POSTEVENT_REPORTS, PANORAMA_PAGE_LOBBY_PLENARY, PANORAMA_PAGE_LIBRARY_CONTENTBOOK,
} from '../Panorama';
import WhoIsWho from '../WhoIsWho/WhoIsWho';
import {
  PAGE_CONCLAVE, PAGE_INTERVIEW, PAGE_LIVE, PAGE_LOBBY, PAGE_NETWORKING,
} from '../Header/Header';
import InfoDesk from '../InfoDesk/InfoDesk';
import { LiveWithChat } from '../Live/Live';
import ButtonClose from '../../components/UI/ButtonClose';
import Button from '../../components/UI/Button';
import useInterface from '../../Lib/useInterface';
import Program from '../Program/Program';
import Interviews from '../Interview/Interviews';
import LibraryQuotes from '../Library/LibraryQuotes';
import LibraryVideos from '../Library/LibraryVideos';
import LibraryCoffeeTab, { FiiInstituteLoungeLogos } from '../Library/LibraryCoffeeTab';
import InstitutePresentation from '../Institute/InstitutePresentation';
import LibraryBooks from '../Library/LibraryBooks';
import useBackButton from '../../Lib/useBackButton';
import isMobile from '../../Lib/misc/isMobile';
import Video from '../../components/UI/Video';
import LobbyLounge from '../Lobby/LobbyLounge';
import LibraryESG from '../Library/LibraryESG';
import videoIntro from '../../../img/FII2/videoIntro.mp4';
import isOs from '../../Lib/misc/isOs';

const INTRO_VIDEO_STATE_STOPPED = 'stopped';
const INTRO_VIDEO_STATE_PLAYING = 'playing';
const INTRO_VIDEO_STATE_FINISHED = 'finished';

const TopWindowVideoPopup = ({ setCurrentTopPanoramaPage, url, withBack }) => {
  useBackButton(() => setCurrentTopPanoramaPage());
  return (
    <div className="withBack">
      <ButtonClose
        backgrounded
        onClick={() => {
          setCurrentTopPanoramaPage(null);
        }}
      />
      <Video url={url} autoPlay />
    </div>
  );
};

const TopWindowLivePart = ({
  introVideoState, changePage, isFinished, programOpened, setProgramOpened,
}) => {
  const [shouldShow, setShouldShow] = useState(false);
  useEffect(() => {
    if (isOs()) {
      window.setTimeout(() => {
        setShouldShow(true);
      }, 1000);
    } else {
      setShouldShow(true);
    }
  }, []);

  if (!shouldShow) {
    return false;
  }
  return (
    <>
      <div>
        <ButtonClose
          backgrounded
          onClick={() => {
            changePage(PAGE_LOBBY);
          }}
        />
        <LiveWithChat introVideoState={introVideoState} />

        {(!isFinished && introVideoState === INTRO_VIDEO_STATE_FINISHED)
          && (
          <>
            {(!programOpened) ? (
              <div className="stdMainSpace-topWindow-liveProgramButton">
                <Button
                  variant="gradiented big rounded"
                  onClick={() => setProgramOpened(!programOpened)}
                >Program
                </Button>
              </div>
            ) : <Program onClose={() => setProgramOpened(false)} withBackground />}
          </>
          )}

      </div>
    </>
  );
};

const TopWindow = () => {
  // const currentPage = PAGE_LIVE;
  const currentPage = useSelector((state) => state.interfaceDataReducer.currentPage);

  const isFinished = parseInt(useSelector((state) => state.stateDataReducer.config.isFinished)) === 1;
  const [currentTopPanoramaPage, setCurrentTopPanoramaPage] = useState(null);
  // const [currentTopPanoramaPage, setCurrentTopPanoramaPage] = useState(PANORAMA_PAGE_LIBRARY_REPORTS);
  const [programOpened, setProgramOpened] = useState(false);
  const [introVideoState, setIntroVideoState] = useState(INTRO_VIDEO_STATE_STOPPED);
  const { changePage } = useInterface();
  const introVideoRef = useRef();

  useEffect(() => {
    if (currentPage === PAGE_LIVE && introVideoState === INTRO_VIDEO_STATE_STOPPED) {
      setIntroVideoState(INTRO_VIDEO_STATE_PLAYING);
      if (introVideoRef.current) {
        introVideoRef.current.defaultMuted = true;
        introVideoRef.current.muted = true;
        introVideoRef.current.play();
      }
    }
    if (currentPage !== PAGE_LIVE && introVideoState === INTRO_VIDEO_STATE_FINISHED) {
      setIntroVideoState(INTRO_VIDEO_STATE_STOPPED);
      if (introVideoRef.current) {
        introVideoRef.current.pause();
        introVideoRef.current.currentTime = 0;
      }
    }
  }, [introVideoState, currentPage]);

  const getAddedClasses = () => {
    if (currentPage === PAGE_LIVE) {
      return 'stdMainSpace-topWindow-overlayPage-forLive';
    }
    if ([
    ].includes(currentTopPanoramaPage)) {
      return 'stdMainSpace-topWindow-overlayPage-smallVideo';
    }
    if ([
      PANORAMA_PAGE_LIBRARY_REPORTS,
      PANORAMA_PAGE_LIBRARY_MAGAZINE,
      PANORAMA_PAGE_LIBRARY_POSTEVENT_REPORTS,
    ].includes(currentTopPanoramaPage)) {
      return 'stdMainSpace-topWindow-overlayPage-coverAll stdMainSpace-topWindow-overlayPage-forBooks';
    }
    if ([
      PANORAMA_PAGE_LOBBY_WELCOME,
      PANORAMA_PAGE_INSTITUTE_AI,
    ].includes(currentTopPanoramaPage)) {
      return 'stdMainSpace-topWindow-overlayPage-bigVideo stdMainSpace-topWindow-overlayPage-bigVideo-1';
    }
    if ([
      PANORAMA_PAGE_INSTITUTE_ROBOTICS,
      PANORAMA_PAGE_INSTITUTE_SUSTAINABILITY,
    ].includes(currentTopPanoramaPage)) {
      return 'stdMainSpace-topWindow-overlayPage-bigVideo stdMainSpace-topWindow-overlayPage-bigVideo-2';
    }
    if ([
      PANORAMA_PAGE_LOBBY_HELPDESK,
      PANORAMA_PAGE_LIBRARY_QUOTES,
      PANORAMA_PAGE_LIBRARY_VIDEOGALLERY,
      PANORAMA_PAGE_LIBRARY_PRESENTATION,
      PANORAMA_PAGE_LIBRARY_HEALTHCARE,
      PANORAMA_PAGE_LOBBY_LOUNGE,
      PANORAMA_PAGE_LIBRARY_ESG,
    ].includes(currentTopPanoramaPage)) {
      return 'stdMainSpace-topWindow-overlayPage-coverAll';
    }
    return null;
  };

  return (
    <>
      <div className={clsx('stdMainSpace-topWindow')}>
        { (currentTopPanoramaPage || currentPage === PAGE_LIVE) && (
          <div className={clsx('stdMainSpace-topWindow-overlayPage', getAddedClasses())}>
            {(() => {
              if (currentPage === PAGE_LIVE) {
                return <TopWindowLivePart introVideoState={introVideoState} changePage={changePage} isFinished={isFinished} programOpened={programOpened} setProgramOpened={setProgramOpened} />;
              }
              switch (currentTopPanoramaPage) {
                case PANORAMA_PAGE_LOBBY_WELCOME: {
                  return <TopWindowVideoPopup withBack url="https://www.youtube.com/watch?v=cuo2TQLbOcU" setCurrentTopPanoramaPage={setCurrentTopPanoramaPage} />;
                }
                case PANORAMA_PAGE_LOBBY_FIIINSTUTELOUNGE: {
                  return <FiiInstituteLoungeLogos onClose={() => { setCurrentTopPanoramaPage(null); }} />;
                }
                case PANORAMA_PAGE_INSTITUTE_AI: {
                  return <TopWindowVideoPopup url="https://www.youtube.com/watch?v=zjyZ1aTRqk0&t=4s" setCurrentTopPanoramaPage={setCurrentTopPanoramaPage} />;
                }
                case PANORAMA_PAGE_INSTITUTE_ROBOTICS: {
                  return <TopWindowVideoPopup url="https://www.youtube.com/watch?v=ZC6u0GTMyCM" setCurrentTopPanoramaPage={setCurrentTopPanoramaPage} />;
                }
                case PANORAMA_PAGE_INSTITUTE_SUSTAINABILITY: {
                  return <TopWindowVideoPopup url="https://www.youtube.com/watch?v=vAEKwBKjmXY" setCurrentTopPanoramaPage={setCurrentTopPanoramaPage} />;
                }
                case PANORAMA_PAGE_LOBBY_HELPDESK: {
                  return <InfoDesk withBackground onClose={() => { setCurrentTopPanoramaPage(null); }} />;
                }
                case PANORAMA_PAGE_LIBRARY_QUOTES: {
                  return <LibraryQuotes onClose={() => { setCurrentTopPanoramaPage(null); }} />;
                }
                case PANORAMA_PAGE_LIBRARY_REPORTS:
                case PANORAMA_PAGE_LIBRARY_MAGAZINE:
                case PANORAMA_PAGE_LIBRARY_POSTEVENT_REPORTS: {
                  return <LibraryBooks type={currentTopPanoramaPage} onClose={() => { setCurrentTopPanoramaPage(null); }} />;
                }
                case PANORAMA_PAGE_LIBRARY_VIDEOGALLERY: {
                  return <LibraryVideos onClose={() => { setCurrentTopPanoramaPage(null); }} />;
                }
                case PANORAMA_PAGE_LIBRARY_CONTENTCOFFEETAB: {
                  return <LibraryCoffeeTab onClose={() => { setCurrentTopPanoramaPage(null); }} />;
                }

                case PANORAMA_PAGE_LIBRARY_PRESENTATION: {
                  return <InstitutePresentation onClose={() => { setCurrentTopPanoramaPage(null); }} />;
                }
                case PANORAMA_PAGE_LIBRARY_HEALTHCARE: {
                  return <InstitutePresentation healthcareReport onClose={() => { setCurrentTopPanoramaPage(null); }} />;
                }
                case PANORAMA_PAGE_LOBBY_LOUNGE: {
                  return <LobbyLounge onClose={() => { setCurrentTopPanoramaPage(null); }} />;
                }
                case PANORAMA_PAGE_LIBRARY_ESG: {
                  return <LibraryESG onClose={() => { setCurrentTopPanoramaPage(null); }} />;
                }
                default: {
                  return null;
                }
              }
            })()}

          </div>
        )}
        {(() => {
          switch (currentPage) {
            case PAGE_NETWORKING: {
              return <WhoIsWho />;
            }
            case PAGE_CONCLAVE: {
              return <Interviews isConclave />;
            }
            case PAGE_INTERVIEW: {
              return <Interviews />;
            }
            default:
            case PAGE_LIVE:
            case PAGE_LOBBY: {
              return (
                <>
                  <div className={clsx('component-panorama-introVideo', `component-panorama-introVideo-${introVideoState}`)}>
                    <video
                      playsInline
                      muted
                      ref={introVideoRef}
                      onEnded={() => {
                        setIntroVideoState(INTRO_VIDEO_STATE_FINISHED);
                      }}
                    >
                      <source src={videoIntro} type="video/mp4" />
                    </video>
                  </div>
                  <Panorama onSetPanoramaPage={setCurrentTopPanoramaPage} />
                </>
              );
            }
          }
        })()}
      </div>
    </>
  );
};

export default TopWindow;
