import * as InterfaceAction from '../actions/Interface';

const defaultInterfaceState = {
  cookies: false,
  currentPage: null,
};

function interfaceDataReducer(state = defaultInterfaceState, action) {
  switch (action.type) {
    case InterfaceAction.SET_PAGE: {
      return { ...state, currentPage: action.page };
    }
    case InterfaceAction.HIDE_COOKIES: {
      return { ...state, cookies: true };
    }
    default:
      return state;
  }
}

export { defaultInterfaceState };
export default interfaceDataReducer;
