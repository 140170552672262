import React from 'react';
import MenuWithLink from '../../../components/UI/Menu/MenuWithLink';

const StaffMenu = () => (
  <>
    <MenuWithLink to="/">Home</MenuWithLink>
    <MenuWithLink to="/info-desk">Info desk</MenuWithLink>
    <MenuWithLink to="/program">Program</MenuWithLink>
    <MenuWithLink to="/polls">Polls management</MenuWithLink>
    <MenuWithLink to="/speakers">Speakers / moderators</MenuWithLink>
    <MenuWithLink to="/settings">Settings</MenuWithLink>
    <MenuWithLink to="/interviews">Interviews / conclaves</MenuWithLink>
    <MenuWithLink to="/users">Users</MenuWithLink>
  </>

);

export default StaffMenu;
