import React, {Suspense} from 'react';
import Chargement from "../../Chargement";

const WysiwygTextareaContent = React.lazy(() => import(/* webpackChunkName: "wysiwyg" */ './WysiwygTextareaContent'));

const WysiwygTextarea = ({ ...props }) => {
  return <Suspense fallback={<Chargement />}><WysiwygTextareaContent {...props} /></Suspense>;
};

WysiwygTextarea.propTypes = {};

WysiwygTextarea.defaultProps = {};

export default WysiwygTextarea;
