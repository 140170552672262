import React from 'react';

import twitter from '../../../img/FII/twitter.svg';
import linkedIn from '../../../img/FII/linkedIn.svg';

import youtube from '../../../img/FII/youtube.svg';
import instagram from '../../../img/FII/instagram.svg';
import flickr from '../../../img/FII/flickr.svg';
import facebook from '../../../img/FII/facebook.svg';

const StickyFooter = () => (
  <div className="stdStickyFooter">
    <div>

      <a href="https://twitter.com/fiiksa" target="_blank" rel="noopener"><img src={twitter} /></a>
      <a href="https://www.linkedin.com/company/fiiksa/" target="_blank" rel="noopener"><img src={linkedIn} /></a>
      <a href="https://www.youtube.com/channel/UCXEQ3MCLUTyUv_Hk_XLyC6g" target="_blank" rel="noopener"><img src={youtube} /></a>
      <a href="https://www.instagram.com/fiiksa/" target="_blank" rel="noopener"><img src={instagram} /></a>
      <a href="https://www.flickr.com/photos/future_investment_initiative" target="_blank" rel="noopener"><img src={flickr} /></a>
      <a href="https://www.facebook.com/FIIKSA" target="_blank" rel="noopener"><img src={facebook} /></a>
    </div>
  </div>
);

export default StickyFooter;
