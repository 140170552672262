import React from 'react';
import { useHistory } from 'react-router';
import footerLogos from '../../../img/FII2/Login/footerLogos.svg';

// DEPRECATED /////////////////////////////////////
const Footer = () => {
  const history = useHistory();

  return (
    <div className="stdFooter">
      <img src={footerLogos} />
    </div>
  );
};

export default Footer;
