import React from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import {
  Redirect, Route, Switch, useHistory,
} from 'react-router';
import TopWindow from './TopWindow';
import { PAGE_LIVE, PAGE_LOBBY } from '../Header/Header';
import privacyPolicy from '../../../img/FII2/FII_Privacy_Policy_2021.pdf';
import StickyFooter from "./StickyFooter";

const MainSpace = () => {
  const currentPage = useSelector((state) => state.interfaceDataReducer.currentPage);

  return (
    <div className="stdMainSpace">
      <div
        className={clsx('stdContainer', ![PAGE_LOBBY, PAGE_LIVE].includes(currentPage) && 'stdContainer-withPadding')}
      >
        <TopWindow />
        <Switch>
          <Route exact path="/" render={() => (<></>)} />
          <Redirect from="*" to="/" />
        </Switch>

      </div>

      <div className="stdContainer">
        <div className="stdPrivacyLink">
          <a href={privacyPolicy} target="_blank" rel="noopener">Privacy Policy</a>
        </div>
      </div>

       <StickyFooter/>

    </div>
  );
};

export default MainSpace;
