

const InterviewType = {
  INTERVIEW: 'INTERVIEW',
  CONCLAVE: 'CONCLAVE',
};

const arInterviewTypes = [
  { value: InterviewType.INTERVIEW, label: 'Interview' },
  { value: InterviewType.CONCLAVE, label: 'Conclave' },
];

export { arInterviewTypes, getLabelForInterviewType };
const getLabelForInterviewType = (statut) => (arInterviewTypes.find(({ value }) => value === statut) ? arInterviewTypes.find(({ value }) => value === statut).label : '?');
export default InterviewType;
