import React, { useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { DateTime } from 'luxon';
import { useHistory } from 'react-router';
import StaffContenu from '../StaffContenu';
import useRestClient from '../../../Lib/useRestClient';
import Chargement from '../../../components/UI/Chargement';
import useInterface from '../../../Lib/useInterface';
import ButtonAjouter from '../../../components/UI/ButtonAjouter';
import LogoForPartner from '../../../components/UI/LogoForPartner';

const Partner = ({ partner, onEdit, onDelete }) => (
  <li>
    <div className="stdStaffList-titre">
      <LogoForPartner partner={partner} variant="tiny" onClick={onEdit} />
      {partner.name}
    </div>
    <div className="stdSplitActions">
      <button type="button" onClick={onEdit}>edit</button>
      <button type="button" onClick={onDelete}>delete</button>
    </div>
  </li>
);

Partner.propTypes = {
  onDelete: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  partner: PropTypes.shape({
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
  }).isRequired,
};

const AllPartners = () => {
  const { sendRestErrorMessage, sendMessage } = useInterface();
  const [deletingPartner, setDeletingPartner] = useState(null);
  const history = useHistory();

  const [lastAction, setLastAction] = useState(DateTime.local());
  const [partners] = useRestClient('partners', null, [lastAction]);
  if (!partners) {
    return <Chargement />;
  }

  const doDeletePartner = async () => {
    try {
      await axios.delete(`/partners/${deletingPartner.id}`);
      setDeletingPartner(null);
      setLastAction(DateTime.local());
      sendMessage('The partner has been deleted');
    } catch (error) {
      sendRestErrorMessage(error);
    }
    return false;
  };


  return (
    <StaffContenu titre="List of partners" rightContent={<ButtonAjouter onClick={() => history.push('/learning-space/partners/add')}>Add a partner</ButtonAjouter>}>
      <ul className="stdStaffList">
        { partners.map((partner) => (deletingPartner && deletingPartner.id === partner.id ? (
          <li><div>Are you sure ?</div>
            <div className="stdSplitActions stdSplitActions-confirm">
              <button onClick={() => setDeletingPartner(null)}><i className="ptl-close2" /> no</button>
              <button onClick={() => doDeletePartner(deletingPartner)}><i className="ptl-valid" /> yes</button>
            </div>
          </li>
        ) : (
          <Partner
            key={partner.id}
            partner={partner}
            onDelete={() => setDeletingPartner(partner)}
            onEdit={() => { history.push(`/learning-space/partners/${partner.id}`); }}
          />
        )))}
      </ul>
    </StaffContenu>
  );
};

export default AllPartners;
