import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const LogoForPartner = ({
  partner, variant, className, ...props
}) => {
  if (partner.logo) {
    return (
      <button
        className={clsx(
          'stdLogo',
          'stdLogo-squared',
          variant && variant.split(' ').map((el) => `stdLogo-${el}`),
          className,
        )}
        {...props}
      ><img src={`${process.env.UPLOADS_URL}${partner.logo}`} />
      </button>
    );
  }
  return (
    <button
      className={clsx(
        'stdLogo',
        'stdLogo-empty',
        'stdLogo-squared',
        variant && variant.split(' ').map((el) => `stdLogo-${el}`),
        className,
      )}
      type="button"
      {...props}
    >Logo
    </button>
  );
};

LogoForPartner.propTypes = {
  partner: PropTypes.shape({
    logo: PropTypes.string.isRequired,
  }).isRequired,
  className: PropTypes.string,
  variant: PropTypes.string,
};
LogoForPartner.defaultProps = {
  className: '',
  variant: null,
};

export default LogoForPartner;
