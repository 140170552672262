import React from 'react';
import Videos from './Videos';
import Reports from './Reports';

const MgfLibrary = () => (
  <>
    <Videos />
    <br />
    <Reports />
  </>
);
export default MgfLibrary;
