import React, { useState } from 'react';
import axios from 'axios';
import { DateTime } from 'luxon';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import useRestClient from '../../../Lib/useRestClient';
import Chargement from '../../../components/UI/Chargement';
import StaffTab from '../StaffTab';
import Button from '../../../components/UI/Button';
import useInterface from '../../../Lib/useInterface';
import SplitDiv from '../../../components/UI/Div/SplitDiv';
import Fieldset from '../../../components/UI/Form/Fieldset';
import SelectUtilisateur from '../../../components/UI/Form/Select/SelectUtilisateur';
import Category from '../../../Lib/Category';
import InterviewType, {arInterviewTypes, getLabelForInterviewType} from '../../../Lib/InterviewType';
import FieldsetSelect from '../../../components/UI/Form/FieldsetSelect';
import FieldsetDatetime from '../../../components/UI/Form/FieldsetDatetime';
import truncate from '../../../Lib/misc/truncate';
import ButtonAjouter from '../../../components/UI/ButtonAjouter';
import Select from '../../../components/UI/Form/Select/Select';
import StaffContenu from '../StaffContenu';

const EditInterview = ({
  idInterview, onCancel, onSuccess, createZoom,
}) => {
  const [lastAction, setLastAction] = useState(DateTime.local());
  const { sendRestErrorMessage, sendMessage } = useInterface();
  const [zoomUsers] = useRestClient('staff/zoom-users');
  const [programme] = idInterview ? useRestClient(`interviews/${idInterview}`, null, [lastAction]) : [{
    titre: '',
    type: InterviewType.CONCLAVE,
    duree: '',
    zoomUser: null,
    utilisateurs: [],
  }];
  if (!programme || !zoomUsers) {
    return <Chargement />;
  }

  return (
    <Formik
      initialValues={programme}
      enableReinitialize
      onSubmit={async (values, { setSubmitting }) => {
        try {
          if (idInterview) {
            await axios.patch(`/staff/interviews/${idInterview}`, values);
            sendMessage('The programme has been modified');
          } else {
            await axios.post('/staff/interviews', values);
            sendMessage('The programme has been created');
          }
          onSuccess();
        } catch (err) {
          sendRestErrorMessage(err);
        }
        setSubmitting(false);
      }}

      validationSchema={Yup.object().shape({
        titre: Yup.string().required('You must provide this field'),
        zoomUser: Yup.object().required('You must provide this field').typeError('You must provide the zoom user'),
        date: Yup.object().test('luxon', 'failure message', function (dateValue) {
          const { path, createError } = this;
          if (dateValue.invalid) {
            return createError({ path, message: 'This date is note valid, please enter mm/dd/YYYY HH:mm' });
          }
          return dateValue;
        }).typeError('This date is note valid, please enter mm/dd/YYYY HH:mm').required('You must provide this field'),
        duree: Yup.number()
          .moreThan(0, 'The duration should be at least 0 minutes')
          .required('You must provide this field'),
      })}
    >
      {({ values, setFieldValue, isSubmitting }) => (
        <Form className="stdForm form-100">

          <SplitDiv>
            <Fieldset placeholder="Name" name="titre" autoFocus>Name</Fieldset>
            <Fieldset placeholder="Duration" name="duree" type="number" min="0" max="600">Duration (mins)</Fieldset>
          </SplitDiv>
          <SplitDiv>
            <FieldsetSelect options={arInterviewTypes} name="type" required>Type</FieldsetSelect>
            <FieldsetDatetime name="date">Date</FieldsetDatetime>

          </SplitDiv>

          <Fieldset
            placeholder="Add users"
            name="zoomUser"
            input={(otherProps) => (
              <Select
                required
                value={values.zoomUser ? values.zoomUser.id : null}
                options={zoomUsers.map(({ id, email }) => ({ label: email, value: id }))}
                onChange={(zoomUserId) => {
                  setFieldValue('zoomUser', zoomUserId ? zoomUsers.find(({ id }) => id === zoomUserId) : null);
                }}
              />
            )}
          >Zoom user
          </Fieldset>

          <Fieldset
            placeholder="Add users"
            name="utilisateurs"
            input={(otherProps) => (
              <SelectUtilisateur
                byRole={[Category.GUEST, Category.SPEAKER, Category.MEDIA, Category.VIP, Category.CONCIERGE]}
                value={values.utilisateurs ? values.utilisateurs : []}
                onChange={(newMap) => {
                  setFieldValue('utilisateurs', newMap);
                }}
              />
            )}
          >Users
          </Fieldset>

          {!!idInterview && programme.zoomReunionId && (
            <SplitDiv>
              <Fieldset disabled name="zoomReunionId" autoFocus>Zoom reunion id (readonly)</Fieldset>
              <Fieldset disabled name="zoomPassword" autoFocus>Zoom password (readonly)</Fieldset>
            </SplitDiv>
          )}

          <div className="rightAlign stdForm-submitLine">
            {!!idInterview && !programme.zoomReunionId && <Button onClick={() => createZoom(idInterview)} variant="transparent">Create zoom room</Button>}
            <Button onClick={onCancel} variant="transparent">Back to interviews list</Button>
            <Button type="submit" disabled={isSubmitting}>Save and quit</Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

EditInterview.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  createZoom: PropTypes.func.isRequired,
  idInterview: PropTypes.number,
};
EditInterview.defaultProps = {
  idInterview: 0,
};

const Interviews = () => {
  const { sendRestErrorMessage, sendMessage } = useInterface();
  const [lastAction, setLastAction] = useState(-1);
  const [adding, setAdding] = useState(false);
  const [deletingInterviewId, setDeletingInterviewId] = useState(null);
  const [editingInterview, setEditingInterview] = useState(null);
  const [interviews] = useRestClient('staff/interviews', null, [lastAction]);
  if (!interviews) {
    return <Chargement />;
  }

  const createZoom = async (idInterview) => {
    try {
      await axios.post(`/staff/interviews/create-zoom/${idInterview}`);
      setLastAction(DateTime.local());
      sendMessage('The zoom room has been created');
    } catch (error) {
      sendRestErrorMessage(error);
    }
    return false;
  };

  const doDeleteInterview = async (idInterview) => {
    try {
      await axios.delete(`/staff/interviews/${idInterview}`);
      setDeletingInterviewId(null);
      setLastAction(DateTime.local());
      sendMessage('The interview has been deleted');
    } catch (error) {
      sendRestErrorMessage(error);
    }
    return false;
  };

  return (
    <StaffContenu
      titre="Interviews"
      className="component-staffInterviews"
      rightContent={(
        <ButtonAjouter onClick={() => setAdding(true)}>Add a conclave/interview</ButtonAjouter>
      )}
    >

      {(adding || editingInterview) ? (
        <EditInterview
          createZoom={createZoom}
          idInterview={editingInterview}
          onCancel={() => {
            setAdding(null);
            setEditingInterview(null);
          }}
          onSuccess={() => {
            setAdding(null);
            setEditingInterview(null);
            setLastAction(DateTime.local());
          }}
        />
      ) : (
        <> { interviews.length ? (
          <table className="stdTable table-100 midMargin-t">
            <thead>
              <tr>
                <th>Title</th>
                <th>Type</th>
                <th>Date</th>
                <th>Duration</th>
                <th>Users</th>
                <th>Zoom</th>
                <th>Zoom user</th>
                <th />
              </tr>

            </thead>
            <tbody>
              {interviews.map(({
                id, titre, type, date, duree, utilisateurs, zoomReunionId, zoomPassword, zoomUser,
              }) => (
                <tr>
                  <td>{titre}</td>
                  <td>{getLabelForInterviewType(type)}</td>
                  <td>{date.toFormat('DD - HH:mm')}</td>
                  <td>{duree} min</td>
                  <td style={{ maxWidth: '10rem' }}>{truncate(utilisateurs.map(({ firstname, lastname }) => `${firstname} ${lastname}`).join(', '), 120)}</td>
                  <td>{zoomReunionId ? <>{zoomReunionId} <br />   {zoomPassword}</> : <button type="button" onClick={() => createZoom(id)} disabled={zoomReunionId}>create</button>}</td>
                  <td>{zoomUser && zoomUser.email}</td>
                  <td>
                    {deletingInterviewId === id ? (
                      <td>
                        <span>Are you sure ?</span>
                        <div className="stdSplitActions stdSplitActions-confirm">
                          <button type="button" onClick={() => setDeletingInterviewId(null)}><i className="ptl-close2" /> no</button>
                          <button type="button" onClick={() => doDeleteInterview(id)}><i className="ptl-valid" /> yes</button>
                        </div>
                      </td>
                    ) : (
                      <td className="stdSplitActions stdSplitActions-small">
                        <button type="button" onClick={() => setEditingInterview(id)}>edit</button>
                        <button type="button" onClick={() => setDeletingInterviewId(id)}>delete</button>
                      </td>
                    )}

                  </td>
                </tr>
              ))}

            </tbody>
          </table>
        ) : (
          <div className="center">
            <div className="mid midMargin-b">No interviews</div>
            <ButtonAjouter onClick={() => setAdding(true)}>Add an interview/conclave</ButtonAjouter>
          </div>
        )}
        </>
      ) }

    </StaffContenu>
  );
};

export default Interviews;
