import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Select from './Form/Select/Select';

const Pagination = ({
  actPage, nbPages, changePage, total, changeNbPage, nbPerPage,
}) => {
  const arPages = (Array.from({ length: nbPages }));

  return (
    <div className="stdPagination">
      <div className="stdPagination-container">
        <button type="button" disabled={actPage === 0} onClick={() => changePage(0)}><i className="ptl-carret" /><i className="ptl-carret" /></button>
        <button type="button" disabled={actPage === 0} onClick={() => changePage(actPage - 1)}><i className="ptl-carret" /></button>

        { arPages.length < 10 ? <>{arPages.map((el, index) => <button type="button" key={index} className={clsx(actPage === index && 'act')} onClick={() => changePage(index)}>{index + 1}</button>)}</> : (
          <>
            { actPage > 3 - 1 ? <button type="button" key={actPage - 3} onClick={() => changePage(actPage - 3)}>{actPage + 1 - 3}</button> : <div />}
            { actPage > 2 - 1 ? <button type="button" key={actPage - 2} onClick={() => changePage(actPage - 2)}>{actPage + 1 - 2}</button> : <div />}
            { actPage > 1 - 1 ? <button type="button" key={actPage - 1} onClick={() => changePage(actPage - 1)}>{actPage + 1 - 1}</button> : <div />}
            <button type="button" key={actPage} className="act" onClick={() => changePage(actPage)}>{actPage + 1}</button>
            { actPage < arPages.length - 1 ? <button type="button" key={actPage + 1} onClick={() => changePage(actPage + 1)}>{actPage + 1 + 1}</button> : <div />}
            { actPage < arPages.length - 2 ? <button type="button" key={actPage + 2} onClick={() => changePage(actPage + 2)}>{actPage + 1 + 2}</button> : <div />}
            { actPage < arPages.length - 3 ? <button type="button" key={actPage + 3} onClick={() => changePage(actPage + 3)}>{actPage + 1 + 3}</button> : <div />}
          </>
        )}

        <button type="button" disabled={actPage === nbPages - 1} onClick={() => changePage(actPage + 1)}><i className="ptl-carret" /></button>
        <button type="button" disabled={actPage === nbPages - 1} onClick={() => changePage(arPages.length - 1)}><i className="ptl-carret" /><i className="ptl-carret" /></button>
      </div>
      <div className="stdPagination-infos">
        {total} results ({arPages.length} pages)
      </div>

      <Select
        value={nbPerPage}
        required
        options={[
          { value: 25, label: '25 per page' },
          { value: 50, label: '50 per page' },
          { value: 250, label: '250 per page' },
          { value: 500, label: '500 per page' },
        ]}
        onChange={changeNbPage}
      />
    </div>
  );
};

Pagination.propTypes = {
  actPage: PropTypes.number,
  nbPerPage: PropTypes.number,
  total: PropTypes.number.isRequired,
  nbPages: PropTypes.number.isRequired,
  changePage: PropTypes.func.isRequired,
  changeNbPage: PropTypes.func,
};

Pagination.defaultProps = {
  actPage: 0,
  nbPerPage: 50,
  changeNbPage: null,
};

export default Pagination;
