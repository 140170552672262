import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import PublicChat from '../StaffSpace/PublicChat';
import Button from '../../components/UI/Button';
import Video from '../../components/UI/Video';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import MenuLang, { LANG_EN } from '../../components/UI/MenuLang';
import SmallProgram from '../Program/SmallProgram';
import usePubsub from '../../Lib/usePubsub';
import startPlayer, { stopPlayer, switchLanguage } from '../../Lib/misc/startNanoPlayer';
import isOs from '../../Lib/misc/isOs';
import LiveYoutube from "./LiveYoutube";

const Live = () => {
  const [lang, setLang] = useState(LANG_EN);
  const container = useRef();
  const [isFullScreenState, setIsFullScreenState] = useState(false);

  const exitHandler = () => {
    if (isFullScreenState && (document.fullScreen || document.webkitIsFullScreen || document.mozFullScreen || document.msFullscreenElement !== null)) {
      setIsFullScreenState(false);
    }
  };

  const isFullScreen = () => document.fullScreen
    || document.webkitIsFullScreen
    || document.mozfullScreen
    || document.msFullscreenElement;

  const goFullScreen = () => {
    if (isFullScreen()) {
      setIsFullScreenState(false);
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.mozExitFullScreen) {
        document.mozExitFullScreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
      // if we are in non-fullscreen mode, open it
    } else if (container.current) {
      setIsFullScreenState(true);
      if (container.current.requestFullscreen) {
        container.current.requestFullscreen();
      } else if (container.current.webkitRequestFullscreen) {
        window.document.documentElement.webkitRequestFullscreen();
        // container.current.webkitRequestFullscreen();
      } else if (container.current.mozRequestFullScreen) {
        container.current.mozRequestFullScreen();
      } else if (container.current.msRequestFullscreen) {
        container.current.msRequestFullscreen();
      }
    }
  };

  useEffect(() => {
    document.addEventListener('fullscreenchange', exitHandler, false);
    document.addEventListener('mozfullscreenchange', exitHandler, false);
    document.addEventListener('MSFullscreenChange', exitHandler, false);
    document.addEventListener('webkitfullscreenchange', exitHandler, false);
    return () => {
      document.removeEventListener('fullscreenchange', exitHandler, false);
      document.removeEventListener('mozfullscreenchange', exitHandler, false);
      document.removeEventListener('MSFullscreenChange', exitHandler, false);
      document.removeEventListener('webkitfullscreenchange', exitHandler, false);
    };
  });

  return (
    <>
      <div className={clsx('component-live-iframe', isFullScreenState && 'component-live-iframe-fullScreen')} ref={container}>
        {/* <Button small onClick={goFullScreen} variant="transparent">{ isFullScreen() ? <>Exit fullscreen</> : <>Fullscreen</>}</Button> */}
        {/* <iframe src="https://video.ibm.com/embed/23995640" frameBorder="no" width="100%" height="100%" /> */}
        <iframe src="https://vimeo.com/event/2393284/embed/e5436e0a2e" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen />

        {/* <MenuLang val={lang} setLang={setLang} textVersion /> */}
        {/* { lang === 'EN' ? ( */}
        {/*  <iframe src="https://vimeo.com/event/470733/embed" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen /> */}
        {/* ) : ( */}
        {/*  <iframe src="https://vimeo.com/event/470738/embed" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen /> */}
        {/* )} */}

      </div>
    </>
  );
};

const LiveNanoPlayer = ({ introVideoState, channel }) => {
  const container = useRef();

  useEffect(() => {
    if (introVideoState === 'finished' || (isOs() && introVideoState === 'playing')) {
      // console.log('start player');
      startPlayer('playerDiv', 0);
    }

    return () => {
      if (introVideoState === 'finished' || (isOs() && introVideoState === 'playing')) {
        // console.log('stopPlayer');
        stopPlayer();
      }
    };
  }, [introVideoState]);

  useEffect(() => {
    if (introVideoState === 'finished' || (isOs() && introVideoState === 'playing')) {
      stopPlayer();
      if (channel === 'plenary') {
        startPlayer('playerDiv', 0);
      } else {
        startPlayer('playerDiv', 4);
      }
    }
  }, [channel]);

  return (
    <div className={clsx('component-live-iframe')}>
      <div id="playerDiv" ref={container} />
       <div id="languageButtons">
        { channel === 'plenary' ? (
          <>
            <button id="btnAR" onClick={() => switchLanguage(0)}>AR</button>
            <button id="btnEN" onClick={() => switchLanguage(1)}>EN</button>
          </>
        ) : (
          <>
            <button id="btnAR" onClick={() => switchLanguage(0)}>AR</button>
            <button id="btnEN" onClick={() => switchLanguage(1)}>EN</button>
          </>
        )}
       </div>
    </div>
  );
};

const LiveWithChat = ({ introVideoState }) => {
  const config = useSelector((state) => state.stateDataReducer.config);
  const isFinished = parseInt(useSelector((state) => state.stateDataReducer.config.isFinished)) === 1;


  usePubsub('refresh', () => {
    document.location.reload();
  });

  const goToReplay = () => {
    window.flash({ action: 'replay' });
  };

  if (isFinished) {
    return (
      <div className="component-live-finished">
        { config.endVideoUrl && <Video url={config.endVideoUrl} /> }
        {/* <div style={{ */}
        {/*  display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '1rem', */}
        {/* }} */}
        {/* > */}
        {/*  <Button onClick={goToReplay}>Access to the replay</Button> */}
        {/* </div> */}
      </div>
    );
  }

  return (
    <>
      <div className="component-live">
        <ErrorBoundary><LiveYoutube /> </ErrorBoundary>
         {/*<ErrorBoundary><LiveNanoPlayer introVideoState={introVideoState} channel={'plenary'} /> </ErrorBoundary>*/}
        {/*<ErrorBoundary><PublicChat tabbed /> </ErrorBoundary>*/}
      </div>
    </>
  );
};

export { LiveWithChat };
export default Live;
