import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import logo from '../../../img/FII/logo.svg';
import logo1 from '../../../img/FII/LoungeLogos/logo1.svg';
import logo2 from '../../../img/FII/LoungeLogos/logo2.svg';
import logo3 from '../../../img/FII/LoungeLogos/logo3.svg';
import logo4 from '../../../img/FII/LoungeLogos/logo4.svg';
import logo5 from '../../../img/FII/LoungeLogos/logo5.svg';
import logo6 from '../../../img/FII/LoungeLogos/logo6.svg';
import logo7 from '../../../img/FII/LoungeLogos/logo7.svg';
import Carousel from '../../components/UI/Carousel';
import book2017pdf from '../../../img/FII/Books/2017_coffeetable.pdf';
import book2018pdf from '../../../img/FII/Books/2018_coffeetable.pdf';
import book2019pdf from '../../../img/FII/Books/2019_coffeetable.pdf';
import book2020pdf from '../../../img/FII/Books/2020_coffeetable.pdf';
import book2017 from '../../../img/FII/Books/2017.jpg';
import book2018 from '../../../img/FII/Books/2018.jpg';
import book2019 from '../../../img/FII/Books/2019.jpg';
import book2020 from '../../../img/FII/Books/2020.jpg';
import ButtonClose from '../../components/UI/ButtonClose';
import useBackButton from '../../Lib/useBackButton';

const FiiInstituteLoungeLogos = ({ onClose }) => {
  useBackButton(onClose);

  return (
    <div className={clsx('component-library component-library-withCarousel', 'component-libraryCoffeeTab', 'component-fiiInstituteLoungeLogos')}>
      <ButtonClose backgrounded onClick={onClose} />
      <div>
        <div><img src={logo} /></div>

        <h2>FII strategic partners community</h2>
        <div className="component-fiiInstituteLoungeLogos-container">
          <img src={logo1} />
          <img src={logo2} />
          <img src={logo3} />
          <img src={logo4} />
          <img src={logo5} />
          <img src={logo6} />
          <img src={logo7} />
        </div>
      </div>
    </div>
  );
};
const LibraryCoffeeTab = ({ onClose }) => {
  useBackButton(onClose);

  return (
    <div className={clsx('component-library component-library-withCarousel component-libraryCoffeeTab')}>
      <ButtonClose backgrounded onClick={onClose} />
      <div>
        <div><img src={logo} /></div>
        <Carousel count={4}>
          <a target="_blank" rel="noopener" href={book2017pdf} className="component-libraryCoffeeTab-el">
            <div><img src={book2017} /></div>
            <div>2017</div>
          </a>
          <a target="_blank" rel="noopener" href={book2018pdf} className="component-libraryCoffeeTab-el">
            <div><img src={book2018} /></div>
            <div>2018</div>
          </a>
          <a target="_blank" rel="noopener" href={book2019pdf} className="component-libraryCoffeeTab-el">
            <div><img src={book2019} /></div>
            <div>2019</div>
          </a>
          <a target="_blank" rel="noopener" href={book2020pdf} className="component-libraryCoffeeTab-el">
            <div><img src={book2020} /></div>
            <div className="bold mid">2020</div>
          </a>
        </Carousel>
      </div>
    </div>
  );
};

LibraryCoffeeTab.propTypes = {
  onClose: PropTypes.func.isRequired,
};

LibraryCoffeeTab.defaultProps = {};

export { FiiInstituteLoungeLogos };
export default LibraryCoffeeTab;
