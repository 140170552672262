import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import ReCAPTCHA from 'react-google-recaptcha';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import useRestClient from '../../Lib/useRestClient';
import EvenementState from '../../Lib/EvenementState';
import topRight from '../../../img/FII/topRight.png';
import botLeft from '../../../img/FII/botLeft.png';
import logo from '../../../img/FII2/Login/logo.svg';
import logoLogin from '../../../img/FII2/Login/logoLogin.svg';
import backLogin from '../../../img/FII2/Login/backLogin.jpg';
import Fieldset from '../../components/UI/Form/Fieldset';
import Button from '../../components/UI/Button';
import Chargement from '../../components/UI/Chargement';
import LoginCompteur from './LoginCompteur';
import Footer from '../MainSpace/Footer';

const Login = ({ match }) => {
  const recaptchaRef = useRef();
  const [error, setError] = useState(null);

  const [config] = useRestClient('/config');
  // const shouldDisplayLogin = (config && parseInt(config.isFinished) !== EvenementState.UNSTARTED) || (match && match.params && match.params.code === 'testFII!!!');
  const shouldDisplayLogin = (!config || parseInt(config.isFinished) !== EvenementState.UNSTARTED) || (match && match.params && match.params.code === 'testFII!!!');
  // const shouldDisplayLogin = false;

  const tryConnect = async (password, captcha, setSubmitting = null) => {
    try {
      await axios.post('login_check', {
        username: password,
        password,
        captcha,
      });
      window.location.href = '/chargement';
    } catch (err) {
      if (setSubmitting) {
        setSubmitting(false);
      }
      if (err.response.data.code === 444) {
        setError('Your ip is not whitelisted');
      } else {
        setError('Please verify your password');
      }

      if (recaptchaRef.current) {
        recaptchaRef.current.reset();
      }
    }
  };

  useEffect(() => {
    if (match && match.params.lien) {
      tryConnect(match.params.lien, 'ed59bfc238f82a8e3f75464e557c2bad43f6a431af2e2d634a07d2e7622f0d42');
    }
  }, []);

  const onChangeCapcha = (val) => {
  };

  if (config === null) {
    return <Chargement />;
  }

  // shouldDisplayLogin = false;

  return (
    <div className="component-connexion">
      {/* <img src={topRight} className="component-connexion-topRight" /> */}
      {/* <img src={botLeft} className="component-connexion-botLeft" /> */}

      <img src={logo} className="component-connexion-logo" />
      <img src={logoLogin} className="component-connexion-logoLogin" />
      <div>
        <h1>ON THE EDGE OF A NEW FRONTIER</h1>
        <h2>February 22 - 23, 2024</h2>
        <h3>FAENA HOTEL & FORUM, MIAMI BEACH</h3>
        <hr className="stdHr"/>

        <Formik
          initialValues={{
            password: '',
          }}
          onSubmit={async (values, { setSubmitting }) => {
            try {
              const captchaVal = await recaptchaRef.current.executeAsync();
              setError(null);
              await tryConnect(values.password, captchaVal);
              setSubmitting(false);
            } catch (err) {
              setSubmitting(false);
              // console.log('captcha err', err);
            }
          }}

          validationSchema={Yup.object().shape({
            password: Yup.string().required('Please provide your password'),
          })}
        >
          {({ handleSubmit, isSubmitting }) => (
            <Form>

              {!shouldDisplayLogin ? (
                <>
<br/>
                  <h4>Please come back on February 22nd and 23rd at 9 a.m. in Miami (GMT +5)</h4><br/>
                  <LoginCompteur date={DateTime.fromFormat('22/02/2024 09:00', 'dd/LL/yyyy HH:mm', {
                    zone: 'UTC-5',
                  })}
                  />
                  <h4 className="midMargin-y medium">Before the show</h4>

                  <h4>
                    If you did not register yet, please click <a href="https://prioritysummit.fii-institute.org/virtual" target="_blank" rel="noopened">here.</a>
                  </h4>
                </>
              ) : (
                <>
                  <div className="component-connexion-centre midMargin-t">
                    <h4>Please type below the Future Investment Initiative’s virtual platform <br />access code you received by email.</h4>

                    <div className="stdContainer" style={{ maxWidth: '36rem' }}>
                      <Fieldset type="password" placeholder="access code" name="password" onIconClic={() => handleSubmit()} />
                      <div className="component-connexion-captcha">
                        <ReCAPTCHA
                          ref={recaptchaRef}
                          size="invisible"
                          sitekey="6Ldcq9EZAAAAAI7TW8UyizDAMUnD1L230q4S5OBz"
                          onChange={onChangeCapcha}
                        />
                      </div>
                      <Button type="submit" variant="big">Enter</Button>
                       <a href="https://prioritysummit.fii-institute.org/virtual/forget-password" target="_blank" className="component-connexion-password">Forgot your access code?</a>

                      {isSubmitting && <Chargement withoutText />}
                      {error && <div className="error smallMargin-t center">{error}</div>}
                    </div>
                  </div>


                  <h4 className="midMargin-t">
                    If you did not register yet, please click <a href="https://prioritysummit.fii-institute.org/virtual" target="_blank" rel="noopened">here.</a>
                  </h4>
                </>
              )}

            </Form>
          )}
        </Formik>

        <Footer />

        {/* <div className="component-connexion-logoBot bigMargin-t"> */}
        {/*  <img src={logoBot} style={{ width: '22rem' }} /> */}
        {/* </div> */}
      </div>
    </div>
  );
};

Login.propTypes = {
  match: PropTypes.object,
};

Login.defaultProps = {
  match: null,
};
export default Login;
