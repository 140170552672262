import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import axios from 'axios';
import { DateTime } from 'luxon';
import StaffContenu from '../StaffContenu';
import useRestClient from '../../../Lib/useRestClient';
import Chargement from '../../../components/UI/Chargement';
import Select from '../../../components/UI/Form/Select/Select';
import useInterface from '../../../Lib/useInterface';
import Popup from '../../../components/UI/Popup/Popup';
import ButtonAjouter from '../../../components/UI/ButtonAjouter';
import AddQuizPopup from './AddQuizPopup';
import EditQuiz from './EditQuiz';

const QuizForPartner = ({ partnerId }) => {
  const { sendRestErrorMessage, sendMessage } = useInterface();
  const [lastAction, setLastAction] = useState(DateTime.local());
  const [partner] = useRestClient(`partners/${partnerId}`, null, [lastAction]);
  const [isAddingQuiz, setIsAddingQuiz] = useState(false);
  const [nbSelectedPage, setNbSelectedPage] = useState(0);

  useEffect(() => {
  }, [partnerId, nbSelectedPage]);
  if (!partner) {
    return <Chargement />;
  }

  const orderedQuizzes = partner.quizzes;
  // const orderedQuizzes = partner.quizzes.sort((a, b) => a.date.valueOf() - b.date.valueOf());

  const deleteQuiz = async () => {
    try {
      await axios.delete(`/staff/quiz/${orderedQuizzes[nbSelectedPage].id}`);
      sendMessage('The quiz has been deleted');
      setLastAction(DateTime.local());
      setNbSelectedPage(0);
    } catch (err) {
      sendRestErrorMessage(err);
    }
  };

  const addAQuiz = async (newQuiz) => {
    try {
      await axios.post(`/staff/parnters/${partnerId}/quiz`, newQuiz);
      sendMessage('The quiz has been saved');
      setLastAction(DateTime.local());
    } catch (err) {
      sendRestErrorMessage(err);
    }
  };

  return (
    <div className="stdTabbedMenu">
      {isAddingQuiz && (
        <Popup onClosePopup={() => setIsAddingQuiz(false)}>
          <AddQuizPopup onFinish={(newQuiz) => {
            addAQuiz(newQuiz);
            setIsAddingQuiz(false);
          }}
          />
        </Popup>
      )}
      <div className="stdTabbedMenu-menu">
        {orderedQuizzes.map(({ id, date }, key) => (
          <button
            className={clsx(key === nbSelectedPage && 'act')}
            onClick={() => {
              setNbSelectedPage(key);
            }}
            key={id}
          >Workshop : {date.toFormat('LL/dd/yyyy')}
          </button>
        ))}
        {orderedQuizzes.length < 3 && <button className="stdTabbedMenu-menu-rightBtn" type="button" onClick={() => setIsAddingQuiz(true)}>Add a quiz</button>}
      </div>
      <div className="stdTabbedMenu-content">

        {orderedQuizzes.length ? (
          <EditQuiz
            onDelete={deleteQuiz}
            quiz={orderedQuizzes[nbSelectedPage]}
            onSave={() => setLastAction(DateTime.local())}
          />
        ) : (
          <div>
            <div className="center">
              <div className="mid midMargin-b">No quizz created</div>
              <ButtonAjouter onClick={() => setIsAddingQuiz(true)}>Add a new quiz</ButtonAjouter>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

QuizForPartner.propTypes = {
  partnerId: PropTypes.number.isRequired,

};

const Quiz = () => {
  const [selectedPartner, setSelectedPartner] = useState(null);
  const [partners] = useRestClient('partners');
  if (!partners) {
    return <Chargement />;
  }

  return (
    <StaffContenu
      titre="Quiz"
      className="component-staffQuiz"
      rightContent={(
        <div style={{ display: 'flex', alignItems: 'center' }}>
          Select a partner : &nbsp;
          <Select
            value={selectedPartner}
            options={partners.map((partner) => ({
              label: partner.name,
              value: partner,
            }))}
            onChange={setSelectedPartner}
          />
        </div>
      )}
    >
      {selectedPartner && <QuizForPartner partnerId={selectedPartner.id} />}
    </StaffContenu>
  );
};

export default Quiz;
