import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import clsx from 'clsx';
import notification from '../../../img/notification.svg';
import useGetMessage from '../../Lib/useGetMessage';
import * as StateAction from '../../Redux/actions/State';

const Notification = () => {
  const [bIsOpened, setBIsOpened] = useState(false);
  const { iframeToken, hasNotification } = useSelector((state) => state.stateDataReducer.loggedUtilisateur);
  const dispatch = useDispatch();

  const url = `${process.env.DATARIUM_CHAT_URL}/my-notifications?mytoken=${iframeToken}`;

  useGetMessage((async (event) => {
    if (event.origin !== process.env.DATARIUM_CHAT_URL) {
      return;
    }

    if (event.data === 'has_notif' || event.data === 'no_notif') {
      try {
        const resp = await axios.get(`/utilisateurs/setNotif?type=${event.data}`);
        dispatch({ type: StateAction.LOGIN, state: resp.data });
      } catch (error) {
      }
    }
  }));

  useEffect(() => {
    const setNoNotif = async () => {
      if (bIsOpened && hasNotification) {
        const resp = await axios.get('/utilisateurs/setNotif?type=no_notif');
        dispatch({ type: StateAction.LOGIN, state: resp.data });
      }
    };
    setNoNotif();
  }, [bIsOpened]);

  return (
    <div className="stdNotification">
      <button type="button" onClick={() => setBIsOpened(!bIsOpened)}>
        <img src={notification} alt="" />
        {hasNotification && <div>!</div>}

      </button>
      <div className={clsx('stdNotification-iframe', bIsOpened && 'act')}>
        <iframe src={url} frameBorder="0" enablejsapi="1" />

        {/* <ul className="notification-list list-group" style={{ backgroundColor: 'rgb(24, 28, 41)' }}> */}
        {/*  <li className="notification">Barcass messis in burdigala!</li> */}
        {/* </ul> */}
      </div>

    </div>
  );
};

export default Notification;
