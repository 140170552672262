import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import axios from 'axios';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import useInterface from '../../../Lib/useInterface';
import SplitDiv from '../../../components/UI/Div/SplitDiv';
import Fieldset from '../../../components/UI/Form/Fieldset';
import Popup from '../../../components/UI/Popup/Popup';
import EditQuizQuestion from './EditQuizQuestion';
import ButtonAjouter from '../../../components/UI/ButtonAjouter';
import swapArrayElements from '../../../Lib/misc/swapArrayElements';
import truncate from '../../../Lib/misc/truncate';
import Button from '../../../components/UI/Button';
import InputDate from '../../../components/UI/Form/InputDate';

const DragHandle = SortableHandle(() => <div className="stdHandle" />);

const SortableItem = SortableElement(({
  question, setEditingQuestion, deleteQuestion,
}) => (
  <tr>
    <td><DragHandle /></td>
    <td><button onClick={setEditingQuestion} type="button">{truncate(question.q, 40)}</button></td>
    <td>{question.r.length}</td>
    <td>{question.r.reduce((carry, { p }) => (p > carry ? p : carry), 0)}</td>
    <td className="stdSplitActions">
      <button type="button" onClick={setEditingQuestion}>edit</button>
      <button type="button" onClick={deleteQuestion}>delete</button>
    </td>
  </tr>
));

const SortableList = SortableContainer(({
  values, setFieldValue, setEditingQuestion,
}) => (
  <tbody>
    {values.questions.map((question, key) => (
      <SortableItem
        key={key + question.q}
        values={values}
        question={question}
        index={key}
        indexElement={key}
        setFieldValue={setFieldValue}
        setEditingQuestion={() => setEditingQuestion(key)}
        deleteQuestion={() => setFieldValue('questions', values.questions.filter((q, k) => k !== key))}
      />
    ))}
  </tbody>
));

const EditQuiz = ({ quiz, onDelete, onSave }) => {
  const [editingQuestion, setEditingQuestion] = useState(null);
  const { sendRestErrorMessage, sendMessage } = useInterface();

  return (
    <Formik
      enableReinitialize
      initialValues={quiz}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          await axios.patch(`/staff/quiz/${quiz.id}`, values);
          sendMessage('The quiz has been saved');
          onSave();
          setEditingQuestion(null);
        } catch (err) {
          sendRestErrorMessage(err);
        }

        setSubmitting(false);
      }}

      validationSchema={Yup.object().shape({
        date: Yup.string().required('You must provide this field').typeError('You must provide this field').matches(/^\d{2}\/\d{2}\/\d{4}$/, 'The format should be mm/dd/yyyy'),
      })}
    >

      {({ values, setFieldValue, isSubmitting }) => (
        <Form className="stdForm form-100 leftAlign">
          <SplitDiv>
            <Fieldset name="date" input={(otherProps) => <InputDate {...otherProps} />}>Date</Fieldset>
            <Fieldset name="video" placeholder="https://www.youtube.com/watch?v=XXXXXXXXXXX">Youtube video</Fieldset>
          </SplitDiv>

          {(editingQuestion !== null) && (
          <Popup onClosePopup={() => setEditingQuestion(null)} variant="big">
            <EditQuizQuestion
              question={values.questions[editingQuestion]}
              onChangeQuestion={async (newQuestion) => {
                setFieldValue('questions', [...values.questions.slice(0, editingQuestion), newQuestion, ...values.questions.slice(editingQuestion + 1)]);
                setEditingQuestion(null);
              }}
            />
          </Popup>
          )}

          <table className="stdTable table-100">
            <thead>
              <tr>
                <th>Sort</th>
                <th>Question</th>
                <th>Answers count</th>
                <th>Max points</th>
                <th className="rightAlign"><ButtonAjouter
                  onClick={() => setFieldValue('questions', [...values.questions, {
                    q: 'New question',
                    r: [
                      { t: 'Answer 1', p: 1 },
                      { t: 'Answer 2', p: 0 },
                      { t: 'Answer 3', p: 0 },
                      { t: 'Answer 4', p: 0 },
                    ],
                  }])}
                  disabled={values.questions.length > 7}
                >Add question
                                           </ButtonAjouter>
                </th>
              </tr>
            </thead>

            <SortableList
              values={values}
              setFieldValue={setFieldValue}
              setEditingQuestion={setEditingQuestion}
              helperClass="stdTable-draggingHelper"
              onSortEnd={({ oldIndex, newIndex }) => {
                setFieldValue('questions', swapArrayElements(values.questions, oldIndex, newIndex));
              }}
              useDragHandle
            />

          </table>
          <div className="rightAlign bigMargin-t stdForm-submitLine">
            <Button onClick={onDelete} variant="transparent">Delete the quiz</Button>
            <Button type="submit" disabled={isSubmitting}>Save</Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

EditQuiz.propTypes = {
  quiz: PropTypes.shape({
    id: PropTypes.number.isRequired,
    video: PropTypes.string,
  }).isRequired,
  onSave: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,

};

export default EditQuiz;
