import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import FieldsetTextarea from '../../../components/UI/Form/FieldsetTextarea';
import Button from '../../../components/UI/Button';
import ClickEditText from '../../../components/UI/ClickEditText';
import ButtonAjouter from '../../../components/UI/ButtonAjouter';
import swapArrayElements from '../../../Lib/misc/swapArrayElements';
import Fieldset from '../../../components/UI/Form/Fieldset';
import SplitDiv from '../../../components/UI/Div/SplitDiv';
import Input from '../../../components/UI/Form/Input';

const DragHandle = SortableHandle(() => <div className="stdHandle" />);

const SortableItem = SortableElement(({
  onChange, onDelete, answer,
}) => {
  const [curAnswer, setCurAnswer] = useState(answer);

  useEffect(() => {
    onChange(curAnswer);
  }, [curAnswer]);
  return (
    <tr>
      <td><DragHandle /></td>
      <td><ClickEditText
        onChange={(newVal) => {
          setCurAnswer({ ...curAnswer, t: newVal });
        }}
        value={answer.t}
      />
      </td>
      <td>
        Points : <Input
          type="number"
          min="0"
          style={{ maxWidth: '5.5rem' }}
          value={answer.p}
          onChange={(newVal) => {
            setCurAnswer({ ...curAnswer, p: newVal });
          }}
        />
      </td>
      <td>
        <button type="button" onClick={onDelete}>delete</button>
      </td>
    </tr>
  );
});

const SortableList = SortableContainer(({
  answers, onChangeAnswers,
}) => (
  <tbody>
    {answers.map((answer, key) => (
      <SortableItem
        index={key}
        key={key + answer}
        answer={answer}
        onDelete={() => onChangeAnswers(answers.filter((a, key2) => key !== key2))}
        onChange={(newVal) => {
          onChangeAnswers([...answers.slice(0, key), newVal, ...answers.slice(key + 1)]);
        }}
      />
    ))}
  </tbody>
));

const InputAnswers = ({
  onChangeAnswers, answers,
}) => (
  <>
    <ButtonAjouter onClick={() => onChangeAnswers([...answers, 'New answer'])} className="floatRight">Add an answer</ButtonAjouter>
    <h2 className="stdSubTitle bigMargin-t midMargin-b">Answers <span className="smallStd">Click right to select the good answer</span></h2>
    <table className="stdTable table-100">
      <SortableList
        answers={answers}
        useDragHandle
        onChangeAnswers={onChangeAnswers}
        onSortEnd={({ oldIndex, newIndex }) => {
          onChangeAnswers(swapArrayElements(answers, oldIndex, newIndex));
        }}
        helperClass="stdTable-draggingHelper"
      />
    </table>
  </>
);

InputAnswers.propTypes = {
  onChangeAnswers: PropTypes.func.isRequired,
  answers: PropTypes.array.isRequired,

};

const EditQuizQuestion = ({ question, onChangeQuestion }) => (
  <><h2 className="stdSubTitle midMargin-b">Editing question</h2>
    <Formik
      initialValues={question}
      onSubmit={async (values, { setSubmitting }) => {
        console.log(values);
        onChangeQuestion(values);
        setSubmitting(false);
      }}

      validationSchema={Yup.object().shape({
        q: Yup.string().required('You must provide this field'),
        r: Yup.array().min(2).required('You must provide this field'),
      })}
    >

      {({
        values, setFieldValue, isSubmitting, handleSubmit,
      }) => (
        <Form className="stdForm form-100 leftAlign">

          <h2 className="stdSubTitle midMargin-b">Question</h2>
          <SplitDiv>
            <Fieldset placeholder="Points" name="p" type="number" min="0">Question points</Fieldset>
            <div />
          </SplitDiv>
          <FieldsetTextarea placeholder="Question" name="q" />

          <InputAnswers
            answers={values.r}
            onChangeAnswers={(answers) => setFieldValue('r', answers)}
          />

          <div className="rightAlign midMargin-t stdForm-submitLine">
            <Button type="button" disabled={isSubmitting} onClick={handleSubmit}>Save changes</Button>
          </div>
        </Form>
      )}
    </Formik>
  </>
);

EditQuizQuestion.propTypes = {
  question: PropTypes.shape({
    r: PropTypes.array,
  }).isRequired,
  onChangeQuestion: PropTypes.func.isRequired,

};

export default EditQuizQuestion;
