import { useDispatch, useSelector } from 'react-redux';
import { handleRestError } from './misc/handleRestError';
import * as InterfaceAction from '../Redux/actions/Interface';

const useInterface = () => {
  const dispatch = useDispatch();
  const changePage = (page) => {
    dispatch({ type: InterfaceAction.SET_PAGE, page });
  };
  const sendMessage = (titre, contenu, variant = null) => {
    window.flash({ titre, contenu, variant });
  };
  const sendErrorMessage = (contenu, titre = 'Error') => {
    window.flash({ titre, contenu, error: true });
  };

  const sendRestErrorMessage = (error) => {
    window.flash({ titre: 'Error', contenu: handleRestError(error), error: true });
  };

  return {
    sendMessage, sendErrorMessage, sendRestErrorMessage, changePage,
  };
};

export default useInterface;
