import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import DateTime from 'luxon/src/datetime';
import isOs from '../../Lib/misc/isOs';
import startPlayer, { stopPlayer } from '../../Lib/misc/startNanoPlayer';

const channels = {
  day1: {
    en: 'https://www.youtube.com/embed/F4CKgTwXSG4?autoplay=1',
    ar: 'https://www.youtube.com/embed/Wvkdw2rJFxs?autoplay=1',
  },
  day2: {
    en: 'https://www.youtube.com/embed/CNO8N1hHIvE?autoplay=1',
    ar: 'https://www.youtube.com/embed/zJikzDbZqKI?autoplay=1',
  },
};


const LiveYoutube = ({ introVideoState }) => {
  const today = DateTime.local().setZone('America/New_York').toFormat('MMdd');

  const isDay2 = (today === '0223');
  // const isDay2 = true;


  const [lang, setLang] = useState('en');
  //
  // useEffect(() => {
  //   if (introVideoState === 'finished' || (isOs() && introVideoState === 'playing')) {
  //     // console.log('start player');
  //     startPlayer('playerDiv', 0);
  //   }
  //
  //   return () => {
  //     if (introVideoState === 'finished' || (isOs() && introVideoState === 'playing')) {
  //       // console.log('stopPlayer');
  //       stopPlayer();
  //     }
  //   };
  // }, [introVideoState]);

  
  return (
    <div className={clsx('component-live-iframe')}>
      <iframe width="560" height="315" src={!isDay2 ? channels.day1[lang] : channels.day2[lang]} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" autoPlay allowFullScreen />
      <div id="languageButtons">
        <button className={clsx(lang === 'en' && 'act')} onClick={() => setLang('en')}>EN</button>
        <button className={clsx(lang === 'ar' && 'act')} onClick={() => setLang('ar')}>AR</button>
      </div>
    </div>
  );
};

export default LiveYoutube;
