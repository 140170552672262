const NotificationMessageType = {
  SOUND: 'SOUND',
  VIEW: 'VIEW',
  OTHER: 'OTHER',
  ADMIN: 'ADMIN',
};

const arNotificationMessageTypes = [
  { value: NotificationMessageType.SOUND, label: 'Sound' },
  { value: NotificationMessageType.VIEW, label: 'View' },
  { value: NotificationMessageType.OTHER, label: 'Other' },
  { value: NotificationMessageType.ADMIN, label: 'Admin' },
];

const getLabelForNotificationMessageType = (type) => (arNotificationMessageTypes.find(({ value }) => type === value) ? arNotificationMessageTypes.find(({ value }) => type === value).label : null);
export { arNotificationMessageTypes, getLabelForNotificationMessageType };
export default NotificationMessageType;
