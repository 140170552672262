import React, { useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { DateTime } from 'luxon';
import useRestClient from '../../../Lib/useRestClient';
import Chargement from '../../../components/UI/Chargement';
import Fieldset from '../../../components/UI/Form/Fieldset';
import Button from '../../../components/UI/Button';
import useInterface from '../../../Lib/useInterface';
import SelectUtilisateur from '../../../components/UI/Form/Select/SelectUtilisateur';
import Category from '../../../Lib/Category';
import SplitDiv from '../../../components/UI/Div/SplitDiv';
import FieldsetTextarea from '../../../components/UI/Form/FieldsetTextarea';
import InputHour from '../../../components/UI/Form/InputHour';
import WysiwygTextarea from '../../../components/UI/Form/Wysiwyg/WysiwygTextarea';
import FieldsetFile from '../../../components/UI/Form/FieldsetFile';
import Switch from '../../../components/UI/Form/Switch';

const EditProgramme = ({
  evenementId, nbDay, programmeId, onCancel, onSuccess,
}) => {
  const [lastAction, setLastAction] = useState(DateTime.local());
  const { sendRestErrorMessage, sendMessage } = useInterface();
  const [programme] = programmeId ? useRestClient(`programmes/${programmeId}`, null, [lastAction]) : [{
    name: '',
    heure: '',
    duree: '',
    description: '',
    programmeSpeakers: [],
    programmeModerators: [],
    jour: nbDay - 1,
  }];
  if (!programme) {
    return <Chargement />;
  }

  const createZoom = async () => {
    try {
      await axios.get(`/staff/programmes/create-zoom/${programmeId}`);
      setLastAction(DateTime.local());
      sendMessage('The zoom room has been created');
    } catch (error) {
      sendRestErrorMessage(error);
    }
    return false;
  };

  return (
    <Formik
      initialValues={programme}
      enableReinitialize
      onSubmit={async (values, { setSubmitting }) => {
        try {
          if (programmeId) {
            await axios.post(`/staff/programmes/${programmeId}`, values);
            sendMessage('The programme has been modified');
          } else {
            await axios.post(`/staff/evenement/${evenementId}/programmes`, values);
            sendMessage('The programme has been created');
          }
          onSuccess();
        } catch (err) {
          sendRestErrorMessage(err);
        }
        setSubmitting(false);
      }}

      validationSchema={Yup.object().shape({
        name: Yup.string().required('You must provide this field'),
        heure: Yup.string().matches(/^\d{2}:\d{2}$/, 'The format should be HH:MM').required('You must provide this field'),
        duree: Yup.number()
          .moreThan(0, 'The duration should be at least 0 minutes')
          .required('You must provide this field'),
      })}
    >
      {({ values, setFieldValue, isSubmitting }) => (
        <Form className="stdForm form-100">

          <SplitDiv>
            <Fieldset placeholder="Name" name="name" autoFocus>Name</Fieldset>
            <Fieldset name="bHideInProgram" input={(otherProps) => <Switch {...otherProps} />}>Hide in program</Fieldset>
          </SplitDiv>
          <Fieldset placeholder="HH:mm" name="heure" input={(otherProps) => <InputHour {...otherProps} />}>Hour</Fieldset>
          <Fieldset placeholder="Duration" name="duree" type="number" min={0}>Duration (mins)</Fieldset>

          <Fieldset name="description" input={(otherProps) => <WysiwygTextarea {...otherProps} />}>Description</Fieldset>

          <Fieldset
            placeholder="Add speakers"
            name="programmeSpeakers"
            input={(otherProps) => (
              <SelectUtilisateur
                byRole={[Category.SPEAKER]}
                value={values.programmeSpeakers ? values.programmeSpeakers.map(({ speaker }) => speaker) : []}
                onChange={(newMap) => {
                  const ret = newMap.map((speaker) => ({ speaker }));
                  setFieldValue('programmeSpeakers', ret);
                }}
              />
            )}
          >Speakers
          </Fieldset>
          <Fieldset
            placeholder="Add moderators"
            name="programmeModerators"
            input={(otherProps) => (
              <SelectUtilisateur
                byRole={[Category.SPEAKER]}
                value={values.programmeModerators ? values.programmeModerators.map(({ moderator }) => moderator) : []}
                onChange={(newMap) => {
                  const ret = newMap.map((moderator) => ({ moderator }));
                  setFieldValue('programmeModerators', ret);
                }}
              />
            )}
          >Moderators
          </Fieldset>

          { programmeId && programme.zoomReunionId && (
          <SplitDiv>
            <Fieldset disabled name="zoomReunionId" autoFocus>Zoom reunion id (readonly)</Fieldset>
            <Fieldset disabled name="zoomPassword" autoFocus>Zoom password (readonly)</Fieldset>
          </SplitDiv>
          )}

          <div className="rightAlign stdForm-submitLine">
            { programmeId && !programme.zoomReunionId && <Button onClick={createZoom} variant="transparent">Create zoom room</Button> }
            <Button onClick={onCancel} variant="transparent">Back to programmes list</Button>
            <Button type="submit" disabled={isSubmitting}>Save and quit</Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

EditProgramme.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  evenementId: PropTypes.number.isRequired,
  programmeId: PropTypes.number,
  nbDay: PropTypes.number.isRequired,
};
EditProgramme.defaultProps = {
  programmeId: 0,
};

export default EditProgramme;
