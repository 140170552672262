import React from 'react';
import { useSelector } from 'react-redux';

const Chat = () => {


  return (
    <div className="stdDelegateChat">
      <div className="stdDelegateChat-delegateList">
        <h2>View Your Conversations</h2>

        <button type="button" className="stdDelegateChat-delegateList-element">
          <div>Delegate name</div>
          <div>Ongoing chat with person...</div>
          <div>5 min ago</div>
        </button>

        <button type="button" className="stdDelegateChat-delegateList-element stdDelegateChat-delegateList-element-act">
          <div>Delegate name</div>
          <div>Ongoing chat with person...</div>
          <div>10:30</div>
        </button>

        <button type="button" className="stdDelegateChat-delegateList-element">
          <div>Delegate name</div>
          <div>Ongoing chat with person...</div>
          <div>9:30</div>
        </button>
      </div>

      <div className="stdDelegateChat-chat stdTab">
        <div className="stdTab-titre">Public chat</div>
        <div className="stdTab-content">
          <div className="stdDelegateChat-chat-message-container">
            <div className="stdDelegateChat-chat-message">
              Hello! <br />
              Delegate message appears here..
              <div className="stdDelegateChat-chat-message-time">9:30 am</div>
              <svg viewBox="0 0 15.871 17.539">
                <path d="M15.662,17.446C9.942,18.069,4.795,15.467,0,12.609C7.046,11.428,14.465,6.215,15.871,0L15.662,17.446z" />
              </svg>
            </div>

            <div className="stdDelegateChat-chat-message stdDelegateChat-chat-message-answer">
              Hello! <br />
              Delegate message appears here..
              <div className="stdDelegateChat-chat-message-time">9:30 am</div>
              <svg viewBox="0 0 15.871 17.539">
                <path d="M15.662,17.446C9.942,18.069,4.795,15.467,0,12.609C7.046,11.428,14.465,6.215,15.871,0L15.662,17.446z" />
              </svg>
            </div>

            <div className="stdDelegateChat-chat-message">
              Hello! <br />
              Delegate message appears here..
              <div className="stdDelegateChat-chat-message-time">9:30 am</div>
              <svg viewBox="0 0 15.871 17.539">
                <path d="M15.662,17.446C9.942,18.069,4.795,15.467,0,12.609C7.046,11.428,14.465,6.215,15.871,0L15.662,17.446z" />
              </svg>
            </div>

            <div className="stdDelegateChat-chat-message stdDelegateChat-chat-message-answer">
              Hello! <br />
              Delegate message appears hergefaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaae..
              <div className="stdDelegateChat-chat-message-time">9:30 am</div>
              <svg viewBox="0 0 15.871 17.539">
                <path d="M15.662,17.446C9.942,18.069,4.795,15.467,0,12.609C7.046,11.428,14.465,6.215,15.871,0L15.662,17.446z" />
              </svg>
            </div>
          </div>

          <form className="stdChatInput">
            <input placeholder="Type your message here..." />
            <i className="ptl-fleche" />
          </form>
        </div>
      </div>
    </div>
  );
};

Chat.propTypes = {};

export default Chat;
