import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Video from './Video';
import Popup from './Popup/Popup';
import { getVideoThumbUrl } from '../../Lib/misc/youtubeMisc';

const VideoPopup = ({
  url, thumb, small, titre, ...props
}) => {
  const [isOpened, setIsOpened] = useState(false);

  return (
    <>
      {isOpened && (
        <Popup onClosePopup={() => setIsOpened(false)} variant="noPadding">
          <Video url={url} autoPlay {...props} />
        </Popup>
      )}

      <button type="button" className={clsx('stdVideoPopup', small && 'stdVideoPopup-small')} onClick={() => setIsOpened(true)}>
        <div><div><i className="ptl-play" /></div></div>
        <img src={thumb || getVideoThumbUrl(url)} />
        { titre && <div>{titre}</div>}
      </button>
    </>
  );
};

VideoPopup.propTypes = {
  url: PropTypes.string.isRequired,
  thumb: PropTypes.string,
  titre: PropTypes.string,
  small: PropTypes.bool,
};

VideoPopup.defaultProps = {
  thumb: null,
  titre: null,
  small: false,
};

export default VideoPopup;
