import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Formik } from 'formik';
import axios from 'axios';
import * as Yup from 'yup';
import { NavLink } from 'react-router-dom';
import * as StateAction from '../../Redux/actions/State';
import StaffTab from './StaffTab';
import useInterface from '../../Lib/useInterface';
import Fieldset from '../../components/UI/Form/Fieldset';
import Button from '../../components/UI/Button';
import NavButton from '../../components/UI/NavButton';
import SplitDiv from '../../components/UI/Div/SplitDiv';

const EWC = () => {
  const { sendRestErrorMessage, sendMessage } = useInterface();


  const config = useSelector((state) => state.stateDataReducer.config);
  const dispatch = useDispatch();

  return (
    <StaffTab titre="EWC">
      <br />
      <br />
      <Formik
        initialValues={{
          ewcLink: config.ewcLink,
          ewcVideo: config.ewcVideo,
        }}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            const result = await axios.put('/staff/config', values);
            dispatch({ type: StateAction.SET_CONFIG, config: result.data });
            sendMessage('The data has been saved');
          } catch (err) {
            sendRestErrorMessage(err);
          }
          setSubmitting(false);
        }}

        validationSchema={Yup.object().shape({
          ewcLink: Yup.string().required('You must provide this field').url('You must provide a url'),
          ewcVideo: Yup.string().required('You must provide this field').url('You must provide a url'),
        })}
      >
        {({ values, isSubmitting }) => (
          <Form className="stdForm form-100">
            <SplitDiv>
              <Fieldset placeholder="https://www.domain.com" name="ewcLink">EWC link</Fieldset>
              <div />
            </SplitDiv>

            <div>Note : to edit EWC videos, go to <NavLink to="/learning-space/mgf-library" className="underline">Learning space video manager</NavLink></div>

            <div className="rightAlign stdForm-submitLine">
              <NavButton to="/" variant="transparent">Back to home</NavButton>
              <Button type="submit" disabled={isSubmitting}>Save changes</Button>
            </div>
          </Form>
        )}
      </Formik>
    </StaffTab>
  );
};

export default EWC;
