import React, { useState } from 'react';
import { DateTime } from 'luxon';
import axios from 'axios';
import useRestClient from '../../Lib/useRestClient';
import Chargement from '../../components/UI/Chargement';
import StaffContenu from './StaffContenu';
import useInterface from '../../Lib/useInterface';
import EditNotificationMessage from './EditNotificationMessage';
import Button from '../../components/UI/Button';
import ButtonAjouter from '../../components/UI/ButtonAjouter';

const Notifications = () => {
  const { sendRestErrorMessage, sendMessage } = useInterface();
  const [lastAction, setLastAction] = useState(DateTime.local());
  const [editingNotificationMessageId, setEditingNotificationMessageId] = useState(null);
  const [addNotificationMessageId, setAddNotificationMessageId] = useState(false);
  const [deletingNotificationMessageId, setDeletingNotificationMessageId] = useState(null);
  const [notificationMessages] = useRestClient('notification-messages', null, [lastAction]);

  const doDeleteNotificationMessages = async (notificationMessageId) => {
    try {
      await axios.delete(`/staff/notification-messages/${notificationMessageId}`);
      setDeletingNotificationMessageId(null);
      setLastAction(DateTime.local());
      sendMessage('The message has been deleted');
    } catch (error) {
      sendRestErrorMessage(error);
    }
    return false;
  };

  if (!notificationMessages) {
    return <Chargement />;
  }

  return (
    <StaffContenu titre={!editingNotificationMessageId ? 'Notification messages' : 'Edit message'} rightContent={!editingNotificationMessageId && <ButtonAjouter onClick={() => setAddNotificationMessageId(true)}>Add more</ButtonAjouter>}>

      {((() => {
        if (editingNotificationMessageId || addNotificationMessageId) {
          return (
            <EditNotificationMessage
              notificationMessageId={editingNotificationMessageId}
              onCancel={() => {
                setEditingNotificationMessageId(null);
                setAddNotificationMessageId(false);
              }}
              onSuccess={() => {
                setEditingNotificationMessageId(null);
                setAddNotificationMessageId(false);
                setLastAction(DateTime.local());
              }}
            />
          );
        }

        if (!notificationMessages.length) {
          return (
            <div className="mid center hugePadding-t">
              <div className="midMargin-b">No notification messages so far</div>
              <ButtonAjouter>Add a notification message</ButtonAjouter>
            </div>
          );
        }

        return (
          <table className="stdTable table-100">
            <thead>
              <tr>
                <th>Contenu</th>
                <th>Type</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {notificationMessages.map((notificationMessage) => (
                <tr key={notificationMessage.id}>
                  <td>
                    <button type="button" onClick={() => setEditingNotificationMessageId(notificationMessage.id)}>{notificationMessage.type}</button>
                  </td>
                  <td>
                    <button type="button" onClick={() => setEditingNotificationMessageId(notificationMessage.id)}>{notificationMessage.content}</button>
                  </td>

                  {deletingNotificationMessageId === notificationMessage.id ? (
                    <td>
                      <span>Are you sure ?</span>
                      <div className="stdSplitActions stdSplitActions-confirm">
                        <button onClick={() => setDeletingNotificationMessageId(null)}><i className="ptl-close2" /> no</button>
                        <button onClick={() => doDeleteNotificationMessages(notificationMessage.id)}><i className="ptl-valid" /> yes</button>
                      </div>
                    </td>
                  ) : (
                    <td className="stdSplitActions">
                      <button type="button" onClick={() => setEditingNotificationMessageId(notificationMessage.id)}>edit</button>
                      <button type="button" onClick={() => setDeletingNotificationMessageId(notificationMessage.id)}>delete</button>
                    </td>
                  )}

                  <td className="stdSplitActions" />

                </tr>
              ))}
            </tbody>
          </table>
        );
      }))()}

    </StaffContenu>
  );
};

export default Notifications;
