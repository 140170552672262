import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import ButtonClose from '../../components/UI/ButtonClose';
import useBackButton from '../../Lib/useBackButton';

const LobbyLounge = ({ onClose }) => {
  useBackButton(onClose);

  return (
    <div className={clsx('component-lobbyLounge')}>

      <div>
        <ButtonClose backgrounded onClick={onClose} />
      </div>
    </div>
  );
};

LobbyLounge.propTypes = {
  onClose: PropTypes.func.isRequired,
};

LobbyLounge.defaultProps = {};

export default LobbyLounge;
