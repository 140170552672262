import React, { useState } from 'react';
import PropTypes from 'prop-types';
import StaffContenu from '../StaffContenu';
import useRestClient from '../../../Lib/useRestClient';
import Chargement from '../../../components/UI/Chargement';
import Select from '../../../components/UI/Form/Select/Select';
import GreenRoomNotifications from './GreenRoomNotifications';

const GreenRoomForEvenement = ({ programmeId }) => {
  const [programme] = useRestClient(`programmes/${programmeId}`);
  if (!programme) {
    return <Chargement />;
  }

  return (
    <div>
      <table className="stdTable table-100 midMargin-t">
        <tbody>
          <tr>
            <th>Start</th>
            <td>{programme.realDateDebut && programme.realDateDebut.toFormat('LL/dd/yyyy') }</td>
          </tr>
          <tr>
            <th>End</th>
            <td>{programme.realDateFin && programme.realDateFin.toFormat('LL/dd/yyyy') }</td>
          </tr>
          <tr>
            <th>Speakers</th>
            <td>{programme.programmeSpeakers.length ? programme.programmeSpeakers.map(({ speaker }) => `${speaker.firstname} ${speaker.lastname}`).join(', ') : 'No speakers' }</td>
            {/* <td className="rightAlign"><Button small>Pick speaker</Button></td> */}
          </tr>
          <tr>
            <th>Moderators</th>
            <td>{programme.programmeSpeakers.programmeModerators ? programme.programmeModerators.map(({ moderator }) => `${moderator.firstname} ${moderator.lastname}`).join(', ') : 'No moderators' }</td>
            {/* <td className="rightAlign"><Button small>Pick moderator</Button></td> */}
          </tr>
        </tbody>
      </table>

      <GreenRoomNotifications programmeId={programmeId} speakers={programme.programmeSpeakers.map(({ speaker }) => speaker)} />
    </div>
  );
};

GreenRoomForEvenement.propTypes = {
  programmeId: PropTypes.number.isRequired,
};

const GreenRoom = () => {
  const [selectedProgramme, setSelectedProgramme] = useState(null);
  const [evenement] = useRestClient('evenements/1?filterProgrammeAfterNow=1');
  if (!evenement) {
    return <Chargement />;
  }
  const { programmes } = evenement;

  const sortedProgrammes = programmes.sort((a, b) => a.realDateDebut.valueOf() - b.realDateDebut.valueOf());
  return (
    <StaffContenu
      titre="Green room"
      className="component-staffGreenRoom"
      rightContent={(
        <div style={{ display: 'flex', alignItems: 'center' }}>
          Select a program : &nbsp;
          <Select
            value={selectedProgramme}
            options={sortedProgrammes.map((programme) => ({
              label: <span className="nowrap">{`${programme.name} ${programme.realDateDebut.toFormat('DD - HH:mm')} to ${programme.realDateFin.toFormat('HH:mm')}`}</span>,
              value: programme,
            }))}
            onChange={setSelectedProgramme}
          />
        </div>
    )}
    >

      { selectedProgramme ? <GreenRoomForEvenement programmeId={selectedProgramme.id} /> : <div className="center mid hugePadding-y">Please select a program</div>}

    </StaffContenu>
  );
};

export default GreenRoom;
