import PropTypes from 'prop-types';
import React from 'react';
import clsx from 'clsx';

const Switch = ({
  onChange, value, ...props
}) => (
  <button type="button" {...props} className={clsx('stdSwitch', value && 'act')} onClick={() => onChange(!value)}>
    <div />
  </button>
);

Switch.propTypes = {
  value: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

Switch.defaultProps = {
  value: false,
};
export default Switch;
