import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import Chargement from '../../components/UI/Chargement';
import useRestClient from '../../Lib/useRestClient';
import Button from '../../components/UI/Button';
import useBackButton from '../../Lib/useBackButton';

const Interview = ({ idInterview, onBack, doJoinExternal }) => {
  const loggedUtilisateur = useSelector((state) => state.stateDataReducer.loggedUtilisateur);
  const iframeRef = useRef(null);
  useBackButton((e) => {
    onBack();
    e.stopPropagation();
    return false;
  });
  const [interview] = useRestClient(`interviews/${idInterview}`);

  window.onmessage = (e) => {
    if (e.data.action && e.data.action === 'leave') {
      onBack();
    }
  };

  if (!interview) {
    return <Chargement />;
  }

  return (
    <div className={clsx('component-interview')}>
      <div className="center">
        <Button variant="secondary rounded" onClick={() => doJoinExternal(idInterview)}>Join from the app</Button>
      </div>

      <div className="stdContainer">
        <iframe src={`visio.html?pseudo=${loggedUtilisateur.firstname}&meetingPassword=${interview.zoomPassword}&meetingId=${interview.zoomReunionId}&zoomUserId=${interview.zoomUser.zoomId}&zoomUserEmail=${interview.zoomUser.email}`} frameBorder="0" title="visio" ref={iframeRef} />
      </div>
    </div>
  );
};

Interview.propTypes = {
  idInterview: PropTypes.number.isRequired,
  onBack: PropTypes.func.isRequired,
  doJoinExternal: PropTypes.func.isRequired,
};

Interview.defaultProps = {};

export default Interview;
