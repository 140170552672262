import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import axios from 'axios';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
import StaffContenu from '../StaffContenu';
import useInterface from '../../../Lib/useInterface';
import Button from '../../../components/UI/Button';
import FieldsetFile from '../../../components/UI/Form/FieldsetFile';
import Fieldset from '../../../components/UI/Form/Fieldset';

const Roadmap = ({}) => {
  const [uploadingData, setUploadingData] = useState({
    roadmapPdf: null,
  });

  const { sendRestErrorMessage, sendMessage } = useInterface();

  const { roadmapPdf, roadmapPdfDescription, roadmapSubtitle, roadmapPdfTitle } = useSelector((state) => state.stateDataReducer.config);

  const doDelete = async () => {
    await axios.delete('/config/file/roadmapPdf');
    sendMessage('The page has been saved');
    window.location.reload();
  };

  return (
    <StaffContenu titre="Roadmap">

      <Formik
        initialValues={{
          roadmapPdf: null,
          roadmapPdfDescription,
          roadmapSubtitle,
          roadmapPdfTitle,
        }}
        onSubmit={async (values, { setSubmitting }) => {
          const formData = new FormData();
          for (const key in values) {
            formData.set(key, values[key]);
          }
          for (const key in uploadingData) {
            if (uploadingData[key]) {
              formData.set(key, uploadingData[key]);
            }
          }

          try {
            formData.append('_method', 'PUT');
            await axios.post('/staff/config', formData);
            sendMessage('The page has been saved');
            window.location.reload();
          } catch (err) {
            sendRestErrorMessage(err);
          }
          setSubmitting(false);
        }}

        validationSchema={Yup.object().shape({
          // roadmapPdf: Yup.string().required('You must provide this field'),
        })}
      >
        {({ isSubmitting }) => (
          <Form className="stdForm form-100">
            <Fieldset name="roadmapSubtitle">Subtitle</Fieldset>
            <Fieldset name="roadmapPdfTitle">Pdf title</Fieldset>
            <Fieldset name="roadmapPdfDescription">Pdf description</Fieldset>
            <FieldsetFile name="roadmapPdf" value={roadmapPdf} doDelete={roadmapPdf && doDelete} onUpload={(roadmapPdf) => setUploadingData({ ...uploadingData, roadmapPdf })} extensions={['pdf']}>Pdf file</FieldsetFile>

            <div className="rightAlign stdForm-submitLine">
              <Button type="submit" disabled={isSubmitting}>Save and quit</Button>
            </div>
          </Form>
        )}
      </Formik>
    </StaffContenu>
  );
};

export default Roadmap;
