const SondageStatut = {
  ACTIVE: 'ACTIVE',
  STOPPED: 'STOPPED',
  ARCHIVED: 'ARCHIVED',
};

const arSondageStatut = [
  { value: SondageStatut.ACTIVE, label: 'Active' },
  { value: SondageStatut.STOPPED, label: 'Stopped' },
  { value: SondageStatut.ARCHIVED, label: 'Archived' },
];
const getLabelForSondageStatut = (statut) => (arSondageStatut.find(({ value }) => value === statut) ? arSondageStatut.find(({ value }) => value === statut).label : '?');
export { arSondageStatut, getLabelForSondageStatut };

export default SondageStatut;
