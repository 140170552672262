import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import DateTime from 'luxon/src/datetime';
import { useSelector } from 'react-redux';
import { range } from 'lodash';
import ButtonClose from '../../components/UI/ButtonClose';
import useRestClient from '../../Lib/useRestClient';
import Chargement from '../../components/UI/Chargement';
import ProgramSpeakers from './ProgramSpeakers';
import ProgramProgramme from './ProgramProgramme';
import ProgramSpeaker from './ProgramSpeaker';
import BookmarkIcon from '../../components/UI/BookmarkIcon';
import salutation from '../../Lib/misc/salutation';
import Popup from '../../components/UI/Popup/Popup';
import isMobile from '../../Lib/misc/isMobile';

const VIEW_SPEAKER = 'speakers';

const Program = ({ onClose, withBackground }) => {
  const loggedUtilisateur = useSelector((state) => state.stateDataReducer.loggedUtilisateur);
  const [selectedProgrammeId, setSelectedProgrammeId] = useState(null);
  const [selectedUtilisateurId, setSelectedUtilisateurId] = useState(null);
  const [curDay, setCurDay] = useState(0);
  const [lastAction, setLastAction] = useState(0);

  const [evenement] = useRestClient('evenements/1', null, [lastAction]);
  if (!evenement) {
    return <Chargement />;
  }

  const programmesThisDay = evenement.programmes.filter(({ jour }) => jour === curDay);

  const printLabelDay = (nDay) =>
  // if (nDay < 2) {
    <>Day&nbsp;{nDay + 1}</>
    // }
    // return <>Workshops</>;

  ;

  if (selectedProgrammeId && isMobile()) {
    return <ProgramProgramme programmeId={selectedProgrammeId} onBack={() => setSelectedProgrammeId(null)} forMobile />;
  }

  return (
    <div className={clsx('component-program', withBackground && 'component-program-withBackground')}>
      <div className="center">
        <h1>Program</h1>
      </div>
      <hr className="stdHr stdHr-gradiented" />

      <ButtonClose onClick={onClose} backgrounded />

      <nav className="component-program-top">
        {range(evenement.duree).map((nDay) => (
          <button
            key={nDay}
            type="button"
            className={clsx(curDay === nDay && 'act')}
            onClick={() => {
              setSelectedProgrammeId(null);
              setCurDay(nDay);
            }}
          >
            {/* <div>{printLabelDay(nDay)}</div> */}
            <div>{evenement.date.plus({ days: nDay }).toFormat('dd/MM')}</div>
            {/*<div>{nDay < 2 ? evenement.date.plus({ days: nDay }).toFormat('dd/MM') : 'Learning space'}</div>*/}
            <div className={clsx('component-program-top-borderAct', curDay === nDay && 'act')} />
          </button>
        ))}

        <button
          type="button"
          className={clsx(curDay === VIEW_SPEAKER && 'act')}
          onClick={() => {
            setSelectedProgrammeId(null);
            setCurDay(VIEW_SPEAKER);
          }}
        >
          <div>Speakers</div>
          <div className={clsx('component-program-top-borderAct', curDay === VIEW_SPEAKER && 'act')} />
        </button>
      </nav>

      {((() => {
        if (curDay === VIEW_SPEAKER) {
          return <ProgramSpeakers />;
        }
        return (
          <>
            { selectedUtilisateurId && <Popup onClosePopup={() => setSelectedUtilisateurId(null)}><ProgramSpeaker utilisateurId={selectedUtilisateurId} onBack={() => setSelectedUtilisateurId(null)} /></Popup>}
            { selectedProgrammeId && <Popup onClosePopup={() => setSelectedProgrammeId(null)}><ProgramProgramme programmeId={selectedProgrammeId} onBack={() => setSelectedProgrammeId(null)} /></Popup>}

            <table className="component-program-table">
              <tbody>
                {programmesThisDay.filter(({ bHideInProgram }) => !bHideInProgram).map(({
                  id, name, jour, heure, duree, programmeSpeakers, programmeModerators, currentUserHasBookmarked,
                }) => {
                  const hour = DateTime.fromFormat(heure, 'HH:mm');
                  const start = evenement.date.plus({ days: jour }).set({ hour: hour.toFormat('HH'), minute: hour.toFormat('mm') });
                  const end = start.plus({ minutes: duree });

                  // if (curDay > 1) {
                  //   return (
                  //     <React.Fragment key={id}>
                  //       <tr className="component-program-table-bigline">
                  //         <td>
                  //           <div>{name}</div>
                  //         </td>
                  //       </tr>
                  //     </React.Fragment>
                  //   );
                  // }

                  return (
                    <React.Fragment key={id}>
                      <tr className={clsx((DateTime.local() > start && DateTime.local() < end) && 'active')}>
                        <td>
                          <div>{start.toFormat('HH:mm')}-{end.toFormat('HH:mm')}</div>
                          <div>{duree}&nbsp;mins</div>
                        </td>
                        <td>
                          <div>
                            <button onClick={() => setSelectedProgrammeId(id)} type="button">{name}</button>
                          </div>
                          {!!programmeSpeakers.length && (
                          <div>Speaker{programmeSpeakers.length > 1 && 's'}: {programmeSpeakers.map(({ speaker }, key) => <button onClick={() => setSelectedUtilisateurId(speaker.id)} type="button" key={key}>{`${salutation(speaker.salutation)} ${speaker.firstname} ${speaker.lastname}`}</button>).reduce((accu, elem) => (accu === null ? [elem] : [...accu, ', ', elem]), null)}
                          </div>
                          )}
                          <div className="showMobile">{!!programmeModerators.length && <div>Moderator{programmeModerators.length > 1 && 's'}: {programmeModerators.map(({ moderator }, key) => <button onClick={() => setSelectedUtilisateurId(moderator.id)} type="button" key={key}>{`${salutation(moderator.salutation)} ${moderator.firstname} ${moderator.lastname}`}</button>).reduce((accu, elem) => (accu === null ? [elem] : [...accu, ', ', elem]), null)}</div>}</div>
                        </td>
                        <td className="hideMobile">
                          {!!programmeModerators.length && <div>Moderator{programmeModerators.length > 1 && 's'}: {programmeModerators.map(({ moderator }, key) => <button onClick={() => setSelectedUtilisateurId(moderator.id)} type="button" key={key}>{`${salutation(moderator.salutation)} ${moderator.firstname} ${moderator.lastname}`}</button>).reduce((accu, elem) => (accu === null ? [elem] : [...accu, ', ', elem]), null)}</div>}
                        </td>
                        <td>
                          <BookmarkIcon
                            programme={{ id }}
                            hasBookmarked={loggedUtilisateur.bookmarkedProgrammesId && loggedUtilisateur.bookmarkedProgrammesId.includes(id)}
                            onChange={() => {
                              setLastAction(DateTime.local());
                            }}
                          />
                        </td>
                      </tr>
                      <tr className="component-program-table-spacer">
                        <td colSpan={4} />
                      </tr>
                    </React.Fragment>
                  );
                })}

              </tbody>
            </table>
          </>
        );
      }))()}

    </div>
  );
};

Program.propTypes = {
  onClose: PropTypes.func.isRequired,
  withBackground: PropTypes.bool,
};

Program.defaultProps = {
  withBackground: false,
};
export default Program;
