import React, { useEffect, useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';
import {
  Redirect, Route, Switch, useHistory, useLocation,
} from 'react-router';
import clsx from 'clsx';
import { DateTime } from 'luxon';
import PublicChat from './PublicChat';
import LearningSpace from './LearningSpace/LearningSpace';
import EWC from './EWC';
import InfoDesk from './InfoDesk';
import Programs from './Programs';
import Speakers from './Speakers';
import Notifications from './Notifications';
import GreenRoom from './GreenRoom/GreenRoom';
import useRestClient from '../../Lib/useRestClient';
import NavButton from '../../components/UI/NavButton';
import usePubsub from '../../Lib/usePubsub';
import Users from './GreenRoom/Users';
import ondes from '../../../img/T20/ondes.svg';
import Settings from './Settings';
import Polls from './Polls';
import StaffEvenementDiv from './StaffEvenementDiv';
import Interview from "../Interview/Interview";
import Interviews from "./Interviews/Interviews";

const StaffSpaceMenu = () => (
  <div className="stdStaffSpace-menu">
    {/* <NavLink style={{ backgroundImage: `url(${ondes})` }} to="/learning-space">LEARNING <br />SPACE</NavLink> */}
    {/* <NavLink style={{ backgroundImage: `url(${ondes})` }} to="/ewc">EWC</NavLink> */}
    <NavLink style={{ backgroundImage: `url(${ondes})` }} to="/info-desk">INFO DESK</NavLink>
    <NavLink style={{ backgroundImage: `url(${ondes})` }} to="/program">PROGRAM</NavLink>
    <NavLink style={{ backgroundImage: `url(${ondes})` }} to="/speakers">SPEAKERS/ <br /> MODERATORS</NavLink>
    <NavLink style={{ backgroundImage: `url(${ondes})` }} to="/polls">POLLS MANAGEMENT</NavLink>
    {/*<NavLink style={{ backgroundImage: `url(${ondes})` }} to="/notifications">NOTIFICATIONS</NavLink>*/}
    <NavLink style={{ backgroundImage: `url(${ondes})` }} to="/interviews">Interviews /<br />Conclaves</NavLink>
    {/*<NavLink style={{ backgroundImage: `url(${ondes})` }} to="/green-room">GREEN ROOM <br />MANAGEMENT</NavLink>*/}
    <NavLink style={{ backgroundImage: `url(${ondes})` }} to="/users">Users</NavLink>
    <NavLink style={{ backgroundImage: `url(${ondes})` }} to="/settings">SETTINGS</NavLink>
  </div>
);

const StaffSpaceBackButton = () => {
  const location = useLocation();
  const history = useHistory();

  return (
    <button
      className={clsx('stdStaffSpace-backMenu', location.pathname !== '/' && 'act')}
      type="button"
      onClick={() => {
        history.push('/');
      }}
    ><i className="ptl-play" /> Back to home
    </button>
  );
};

const StaffSpaceEmergency = () => {
  const [lastAction, setLastAction] = useState(DateTime.local());
  const [notifications] = useRestClient('/staff/notifications', null, [lastAction]);
  const dummyEnd = useRef();
  const nbMessages = 10;

  usePubsub('programme/notifications', () => {
    setLastAction(DateTime.local());
  });

  useEffect(() => {
    if (dummyEnd.current) {
      dummyEnd.current.parentNode.scrollTop = dummyEnd.current.offsetTop;
    }
  }, [notifications]);

  if (!notifications) {
    return <div className="stdStaffSpace-emergencyDashboard-contenu" />;
  }
  return (
    <div className="stdStaffSpace-emergencyDashboard stdTab">
      <div className="stdTab-titre">
        <span>Emergency Dashboard</span>
      </div>

      <div className="stdTab-content">
        <div className="stdStaffSpace-emergencyDashboard-contenu">
          {notifications.slice(notifications.length - nbMessages).map(({
            id, message, author, category, created,
          }) => (
            <div key={id} className={clsx('stdStaffSpace-emergencyDashboard-message')}>
              <div>
                <div>{`${author.firstname} ${author.lastname}`}</div>
                {created && <div>{created.toFormat('HH:mm')}</div>}
              </div>
              <div>{message}</div>
            </div>
          ))}
          <div style={{ float: 'left', clear: 'both' }} ref={dummyEnd} />
        </div>
        <NavButton variant="danger" small to="/green-room">Go to emergency dashboard</NavButton>
      </div>
    </div>
  );
};

const StaffSpace = () => {
  const location = useLocation();

  return (
    <div className="stdStaffSpace">
      <div className="stdContainer">

        <div className="stdStaffSpace-top">
          <StaffSpaceBackButton />
          <StaffEvenementDiv />
        </div>

        <div className={clsx('stdStaffSpace-content', location.pathname === '/green-room' && 'stdStaffSpace-chatMaxed')}>
          <Switch>

            {/*<Route path="/learning-space" render={() => <LearningSpace />} />*/}
            {/*<Route exact path="/ewc" render={() => <EWC />} />*/}
            <Route exact path="/info-desk" render={() => <InfoDesk />} />
            <Route path="/program" render={() => <Programs />} />
            <Route path="/polls" render={() => <Polls />} />
            <Route exact path="/speakers" render={() => <Speakers />} />
            {/*<Route exact path="/notifications" render={() => <Notifications />} />*/}
            <Route exact path="/settings" render={() => <Settings />} />
            <Route exact path="/interviews" render={() => <Interviews />} />
            {/*<Route exact path="/green-room" render={() => <GreenRoom />} />*/}
            <Route exact path="/users" render={() => <Users />} />
            <Route exact path="/" render={() => <StaffSpaceMenu />} />
            <Redirect from="*" to="/" />
          </Switch>
          <PublicChat className="stdStaffSpace-chatTab" />

          {/*{location.pathname !== '/green-room' && <StaffSpaceEmergency />}*/}

        </div>
      </div>
    </div>
  );
};
export default StaffSpace;
