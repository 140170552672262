import PropTypes from 'prop-types';
import React from 'react';
import Input from './Input';

const InputHour = ({
  onChange, ...props
}) => (
  <Input
    {...props}
    onChange={(newVal) => {
      let newValueFormatted = newVal;
      if (newValueFormatted.match(/^\d{2}$/)) {
        newValueFormatted += ':';
      } else if (newValueFormatted.match(/^\d{2}:\d{2}.+$/)) {
        newValueFormatted = newValueFormatted.substring(0, 5);
      }
      return onChange(newValueFormatted);
    }}
  />
);

InputHour.propTypes = {
  onChange: PropTypes.func.isRequired,
};

InputHour.defaultProps = {};
export default InputHour;
