import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import useInterface from '../../Lib/useInterface';
import Button from './Button';
import * as StateAction from '../../Redux/actions/State';

const BookmarkIcon = ({
  programme, hasBookmarked, onChange, withText, ...props
}) => {
  const { sendRestErrorMessage, sendMessage } = useInterface();
  const dispatch = useDispatch();

  const bookmark = async (programmeId) => {
    try {
      const resp = await axios.post(`/programmes/${programmeId}/bookmark`);
      dispatch({ type: StateAction.SET_UTILISATEUR, loggedUtilisateur: resp.data });
      if (hasBookmarked) {
        sendMessage('The session has been unbookmarked');
      } else {
        sendMessage('The session has been bookmarked');
      }
      onChange();
    } catch (error) {
      sendRestErrorMessage(error);
    }
    return false;
  };

  if (withText) {
    return <Button variant="transparent" onClick={() => bookmark(programme.id)} {...props} iconAfter={hasBookmarked ? 'flag' : 'flag-o'}>{hasBookmarked ? <>Un-bookmark this session</> : <>Bookmark this session</>} </Button>;
  }
  return <button onClick={() => bookmark(programme.id)} {...props}>{hasBookmarked ? <i className="ptl-flag main" /> : <i className="ptl-flag-o main" />}</button>;
};

BookmarkIcon.propTypes = {
  withText: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  hasBookmarked: PropTypes.bool.isRequired,
  programme: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
};
BookmarkIcon.defaultProps = {
  withText: false,
};

export default BookmarkIcon;
