import React, { useState } from 'react';
import clsx from 'clsx';
import ProgramSpeaker from './ProgramSpeaker';
import SpeakerDesc from '../../components/UI/SpeakerDesc';
import useRestClient from '../../Lib/useRestClient';
import Chargement from '../../components/UI/Chargement';
import Popup from '../../components/UI/Popup/Popup';

const ProgramSpeakers = ({ }) => {
  const [utilisateurs] = useRestClient('speakers');
  const [selectedUtilisateurId, setSelectedUtilisateurId] = useState(null);

  if (!utilisateurs) {
    return <Chargement height50 />;
  }

  return (
    <div className={clsx('component-programSpeakers')}>
      { selectedUtilisateurId && <Popup onClosePopup={() => setSelectedUtilisateurId(null)}><ProgramSpeaker utilisateurId={selectedUtilisateurId} onBack={() => setSelectedUtilisateurId(null)} forSpeakers /></Popup>}
      {/* <Carousel> */}
      {utilisateurs.filter(({ bCacherSpeaker }) => !bCacherSpeaker).map((utilisateur) => (
        <div onClick={() => setSelectedUtilisateurId(utilisateur.id)} key={utilisateur.id} className="component-programSpeakers-speakerEl">
          <SpeakerDesc utilisateur={utilisateur} />
        </div>
      ))}
      {/* </Carousel> */}
    </div>
  );
};

export default ProgramSpeakers;
