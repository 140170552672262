import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const StaffContenu = ({
  titre, rightContent, className, children,
}) => (
  <div className={clsx('stdStaffContenu', className)}>
    <div className="stdStaffContenu-titre">
      <h1>{titre}</h1>
      { rightContent && <div className="stdStaffContenu-droite">{rightContent}</div> }
    </div>
    <div className="stdStaffContenu-content">{children}</div>
  </div>
);

StaffContenu.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  rightContent: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  titre: PropTypes.string.isRequired,
  className: PropTypes.string,
};
StaffContenu.defaultProps = {
  children: null,
  rightContent: null,
  className: null,
};

export default StaffContenu;
