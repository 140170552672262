import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import { Form, Formik } from 'formik';
import axios from 'axios';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import StaffContenu from '../StaffContenu';
import useRestClient from '../../../Lib/useRestClient';
import Chargement from '../../../components/UI/Chargement';
import useInterface from '../../../Lib/useInterface';
import Button from '../../../components/UI/Button';
import Pagination from '../../../components/UI/Pagination';
import InputWithIcon from '../../../components/UI/Form/InputWithIcon';
import Select from '../../../components/UI/Form/Select/Select';
import Category, { arCategories } from '../../../Lib/Category';
import SplitDiv from '../../../components/UI/Div/SplitDiv';

const EditUser = ({ editingUtilisateurId, onSuccess, onCancel }) => {
  const { sendRestErrorMessage, sendMessage } = useInterface();

  const [utilisateur] = useRestClient(`staff/utilisateurs/${editingUtilisateurId}`);

  if (!utilisateur) {
    return <Chargement />;
  }

  return (
    <Formik
      initialValues={utilisateur}
      enableReinitialize
      onSubmit={async (values, { setSubmitting }) => {
        try {
          await axios.patch(`/staff/utilisateurs/${editingUtilisateurId}`, values);
          sendMessage('The user has been modified');
          onSuccess();
        } catch (err) {
          sendRestErrorMessage(err);
        }
        setSubmitting(false);
      }}

    >
      {({ values, setFieldValue, isSubmitting }) => (
        <Form className="stdForm form-100">

          <div className="rightAlign stdForm-submitLine">
            <Button onClick={onCancel} variant="transparent">Back to users list</Button>
            <Button type="submit" disabled={isSubmitting}>Save and quit</Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

EditUser.propTypes = {
  editingUtilisateurId: PropTypes.number.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

const Users = () => {
  const [lastAction, setLastAction] = useState(DateTime.local());
  const { sendRestErrorMessage, sendMessage } = useInterface();
  const [editingUtilisateur, setEditingUtilisateur] = useState(null);
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState('');
  const [cat, setCat] = useState(null);

  const loggedUtilisateur = useSelector((state) => state.stateDataReducer.loggedUtilisateur);
  const [data] = useRestClient(`staff/utilisateurs?page=${page}&search=${search}&roles=${cat || ''}`, null, [page, search, cat, lastAction]);
  if (!data) {
    return <Chargement />;
  }

  const utilisateurs = data.results;

  const searchComponent = (
    <div>
      <SplitDiv>
        <Select options={arCategories.map((cat) => ({ label: cat, value: cat }))} onChange={setCat} value={cat} />
        <InputWithIcon icon="fleche" onChange={setSearch} placeholder="Search a user" value={search} />
      </SplitDiv>
    </div>
  );

  const toggleBlock = async (utilisateur) => {
    try {
      await axios.post(`/staff/utilisateurs/${utilisateur.id}/toggleBlock`);

      sendMessage(!utilisateur.isBlocked ? 'The user has been blocked' : 'The user has been unblocked');

      setLastAction(DateTime.local());
    } catch (err) {
      sendRestErrorMessage(err);
    }
  };
  const switchUser = async (utilisateur) => {
    try {
      const resp = await axios.get(`/staff/switchUser?id=${utilisateur.id}`);
      window.location.href = '/';
      sendMessage(`You are now connected as ${utilisateur.firstname} ${utilisateur.lastname}`);
    } catch (error) {
      sendRestErrorMessage(error);
    }
  };

  return (
    <StaffContenu
      titre={editingUtilisateur ? `Edit ${editingUtilisateur.firstname} ${editingUtilisateur.lastname}` : 'Users'}
      className="component-staffUsers"
      rightContent={searchComponent}
    >

      {editingUtilisateur ? <EditUser editingUtilisateurId={editingUtilisateur.id} onCancel={() => setEditingUtilisateur(null)} onSuccess={() => setEditingUtilisateur(null)} /> : (
        <>
          {utilisateurs.length ? (
            <>
              <div style={{ minHeight: '30rem' }}>
                <table className="stdTable table-100">
                  <tbody>
                    {utilisateurs.map((utilisateur) => (
                      <tr key={utilisateur.id} className={utilisateur.isBlocked && clsx('component-staffUsers-isBlocked')}>
                        <td>{`${utilisateur.firstname} ${utilisateur.lastname}`}</td>
                        <td>{utilisateur.category}</td>
                        <td className="stdSplitActions">
                          {/* <button type="button" onClick={() => setEditingUtilisateur(utilisateur)}>edit</button> */}
                          { utilisateur.id !== loggedUtilisateur.id && <button type="button" onClick={() => toggleBlock(utilisateur)}>{!utilisateur.isBlocked ? 'block' : 'unblock'}</button> }
                          {utilisateur.id !== loggedUtilisateur.id && utilisateur.category !== Category.STAFF && <button type="button" onClick={() => switchUser(utilisateur)}>switch</button> }
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="center bigPadding-y">
                {data.pagesCount > 1 && <Pagination nbPages={data.pagesCount} changePage={setPage} actPage={page} total={data.total} />}
              </div>
            </>
          ) : <div className="mid hugePadding center">No users corresponds to your search teamrs</div>}
        </>
      )}

    </StaffContenu>
  );
};

export default Users;
