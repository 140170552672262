import * as StateAction from '../actions/State';

const defaultStateState = {
  loggedUtilisateur: null,
  entreprisesActionnairesDe: null,
  selectedEntrepriseId: null,
  idSondagesRepondus: [],
};

function stateDataReducer(state = defaultStateState, action) {
  switch (action.type) {
    case StateAction.LOGIN: {
      return action.state;
    }
    case StateAction.SET_CONFIG: {
      return {
        ...state,
        config: action.config,
      };
    }
    case StateAction.SET_UTILISATEUR: {
      return {
        ...state,
        loggedUtilisateur: action.loggedUtilisateur,
      };
    }
    case StateAction.SET_EVENEMENT: {
      return {
        ...state,
        evenement: action.evenement,
      };
    }
    case StateAction.SET_SONDAGES_REPONDUS: {
      return {
        ...state,
        idSondagesRepondus: action.idSondagesRepondus,
      };
    }
    case StateAction.LOGOUT: {
      return {};
    }
    default:
      return state;
  }
}

export { defaultStateState };
export default stateDataReducer;
