import React from 'react';
import PropTypes from 'prop-types';

const MenuWithCallback = ({ children, onChoose }) => (
  <button type="button" onClick={onChoose}>{children}</button>
);

MenuWithCallback.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  onChoose: PropTypes.func.isRequired,
};

MenuWithCallback.defaultProps = {
};
export default MenuWithCallback;
