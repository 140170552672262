import React from 'react';
import PropTypes from 'prop-types';
import ClicPortal from '../Portal/ClicPortal';
import Menu from './Menu';
import HoverPortal from "../Portal/HoverPortal";

const DropdowMenu = ({ children, menu }) => (
  <HoverPortal attachRight portalComponent={<Menu items={menu} />}>
    {children}
  </HoverPortal>
);

DropdowMenu.propTypes = {
  menu: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
};

DropdowMenu.defaultProps = {
  // data: [],
};
export default DropdowMenu;
