import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import useClickOutside from '../../Lib/useClickOutsite';

const CLICKEDITTEXT_ERROR_CODE = 456;

const ClickEditText = ({
  value, onChange, className, customRenderValue,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [currentValue, setCurrentValue] = useState(value);
  const [previousValue, setPreviousValue] = useState(value);

  const refInput = useRef();

  const confirmCurrentValue = async () => {
    if (isEditing) {
      setIsEditing(false);
      if (currentValue !== value) {
        const result = await onChange(currentValue);
        if (result === CLICKEDITTEXT_ERROR_CODE) {
          setCurrentValue(previousValue);
        } else {
          setPreviousValue(currentValue);
        }
      }
    }
  };

  const [ref] = useClickOutside(() => {
    confirmCurrentValue();
  });

  const autoFocusAndSelect = () => {
    if (refInput.current) {
      refInput.current.focus();
      refInput.current.select();
    }
  };

  const keyDown = (event) => {
    if (event.which === 13 || event.keyCode === 13) {
      confirmCurrentValue();
      event.stopPropagation();
      return false;
    }
    return true;
  };

  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  useEffect(() => {
    window.addEventListener('keydown', keyDown);
    return () => {
      window.removeEventListener('keydown', keyDown);
    };
  });

  return (
    <div
      className={clsx(
        'stdClickEditText',
        isEditing && 'stdClickEditText-act',
        className,
      )}
      ref={ref}
      onClick={() => {
        if (onChange !== null && !isEditing) {
          setIsEditing(!isEditing);
          window.setTimeout(autoFocusAndSelect, 50);
          // autoFocusAndSelect();
        }
      }}
    >
      {isEditing ? (
        <input
          type="text"
          ref={refInput}
          value={currentValue || ''}
          onBlur={confirmCurrentValue}
          onChange={(e) => setCurrentValue(e.target.value)}
        />
      ) : <button type="button">{customRenderValue ? customRenderValue(currentValue) : currentValue}</button>}
    </div>
  );
};

ClickEditText.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  onChange: PropTypes.func,
  customRenderValue: PropTypes.func,
};

ClickEditText.defaultProps = {
  value: null,
  customRenderValue: null,
  className: null,
  onChange: null,
};

export { CLICKEDITTEXT_ERROR_CODE };
export default ClickEditText;
