import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { NavLink } from 'react-router-dom';
import AllPartners from './AllPartners';
import EditPartner from './EditPartner';
import Quiz from './Quiz';
import MgfLibrary from './MgfLibrary/MgfLibrary';
import Roadmap from './Roadmap';

const LearningSpace = () => (
  <div className="component-staffLearningSpace stdStaffSpaceWithMenu">
    <div className="stdStaffSpaceWithMenu-menu stdTab">
      <div className="stdTab-titre">
        Learning space
      </div>
      <div className="stdTab-content stdStaffStdMenu">
        <NavLink to="/learning-space/partners">All Partners</NavLink>
        {/* <NavLink to="/learning-space/partners/add">Add a New Partner</NavLink> */}
        <NavLink to="/learning-space/quiz">Create / Assign Quiz</NavLink>
        <NavLink to="/learning-space/certificates">Certificate Center</NavLink>
        <NavLink to="/learning-space/mgf-library">MGF Library</NavLink>
        <NavLink to="/learning-space/roadmap">Roadmap</NavLink>
      </div>
    </div>
    <div className="stdStaffSpaceWithMenu-content">
      <Switch>
        <Route exact path="/learning-space/partners" component={() => <AllPartners />} />
        <Route exact path="/learning-space/partners/add" component={() => <EditPartner />} />
        <Route exact path="/learning-space/partners/:partnerId" component={(props) => <EditPartner {...props} />} />
        <Route exact path="/learning-space/partners" component={() => <AllPartners />} />
        <Route exact path="/learning-space/quiz" component={() => <Quiz />} />
        <Route exact path="/learning-space/certificates" component={() => <div>certificates</div>} />
        <Route exact path="/learning-space/mgf-library" component={() => <MgfLibrary />} />
        <Route exact path="/learning-space/roadmap" component={() => <Roadmap />} />
        <Redirect from="*" to="/learning-space/partners" />
      </Switch>
    </div>
  </div>
);

export default LearningSpace;
