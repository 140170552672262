import React from 'react';
import PropTypes from 'prop-types';
import Button from './Button';

const ButtonAjouter = ({
  children, ...props
}) => (
  <Button {...props} small>
    {children} +
  </Button>
);

ButtonAjouter.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

ButtonAjouter.defaultProps = {
  children: 'Ajouter',
};

export default ButtonAjouter;
