import { useSelector } from 'react-redux';
import Category from './Category';

const useSecurity = () => {
  const loggedUtilisateur = useSelector((state) => state.stateDataReducer.loggedUtilisateur);

  const isGuest = () => loggedUtilisateur.category === Category.GUEST;
  const isVIP = () => loggedUtilisateur.category === Category.VIP;
  const isConcierge = () => loggedUtilisateur.category === Category.CONCIERGE;
  const isMedia = () => loggedUtilisateur.category === Category.MEDIA;
  const isSpeaker = () => loggedUtilisateur.category === Category.SPEAKER;
  const isStaff = () => loggedUtilisateur.category === Category.STAFF;

  return {
    isGuest,
    isVIP,
    isConcierge,
    isMedia,
    isSpeaker,
    isStaff,
  };
};

export default useSecurity;
