import React, { useState } from 'react';
import { DateTime } from 'luxon';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import axios from 'axios';
import StaffContenu from './StaffContenu';
import useRestClient from '../../Lib/useRestClient';
import Chargement from '../../components/UI/Chargement';
import EditSpeaker from './EditSpeaker';
import { PhotoForUtilisateur } from '../../components/UI/Photo';
import Pagination from '../../components/UI/Pagination';
import useInterface from '../../Lib/useInterface';

const DragHandle = SortableHandle(() => <div className="stdHandle" />);
const SortableItem = SortableElement(({
  key, utilisateur, setEditingUtilisateur,
}) => (
  <tr key={key}>
    <td><DragHandle /></td>
    <td>
      <button type="button" onClick={() => setEditingUtilisateur(utilisateur)}><PhotoForUtilisateur utilisateur={utilisateur} small /></button>
    </td>
    <td>
      <button type="button" onClick={() => setEditingUtilisateur(utilisateur)}>{utilisateur.firstname}</button>
    </td>
    <td>
      <button type="button" onClick={() => setEditingUtilisateur(utilisateur)}>{utilisateur.lastname}</button>
    </td>
    <td>{utilisateur.company}</td>
    <td>{utilisateur.position}</td>
    <td>{utilisateur.bCacherSpeaker ? 'yes' : 'no'}</td>

    <td className="stdSplitActions">
      <button type="button" onClick={() => setEditingUtilisateur(utilisateur)}>edit</button>
    </td>

  </tr>

));

const SortableList = SortableContainer(({
  utilisateurs, setEditingUtilisateur,
}) => (
  <tbody>
    {utilisateurs.map((utilisateur, k) => (
      <SortableItem
        key={utilisateur.id}
        index={k}
        utilisateur={utilisateur}
        setEditingUtilisateur={setEditingUtilisateur}
      />
    ))}
  </tbody>

));
const Speakers = () => {
  const [lastAction, setLastAction] = useState(DateTime.local());
  const [editingUtilisateur, setEditingUtilisateur] = useState(null);
  const [page, setPage] = useState(0);
  const [nbPerPage, setNbPerPage] = useState(50);
  const { sendRestErrorMessage, sendMessage } = useInterface();
  const [data] = useRestClient(`staff/utilisateurs/?roles=SPEAKER&page=${page}&nbPage=${nbPerPage}`, null, [lastAction, nbPerPage, page]);

  if (!data) {
    return <Chargement />;
  }
  const utilisateurs = data.results;

  const doSwapUtilisateur = async (from, to) => {
    try {
      await axios.get(`/staff/utilisateurs/swap/${from}/${to}`);
      setLastAction(DateTime.local());
    } catch (error) {
      sendRestErrorMessage(error);
    }
    return false;
  };

  return (
    <StaffContenu titre={!editingUtilisateur ? 'Speakers/Moderators' : `Edit : ${editingUtilisateur.firstname} ${editingUtilisateur.lastname}`}>
      {(editingUtilisateur) ? (
        <EditSpeaker
          utilisateurId={editingUtilisateur.id}
          onCancel={() => {
            setEditingUtilisateur(null);
          }}
          onSuccess={() => {
            setEditingUtilisateur(null);
            setLastAction(DateTime.local());
          }}
        />
      ) : (
        <>

          <table className="stdTable table-100">
            <thead>
              <tr>
                <th>&nbsp;</th>
                <th>Avatar</th>
                <th>First name</th>
                <th>Last name</th>
                <th>Company</th>
                <th>Position</th>
                <th>Hide list</th>
                <th />
              </tr>
            </thead>
            <SortableList
              utilisateurs={utilisateurs}
              onSortEnd={({ oldIndex, newIndex }) => {
                doSwapUtilisateur(utilisateurs[oldIndex].id, utilisateurs[newIndex].id);
              }}
              setEditingUtilisateur={setEditingUtilisateur}
              helperClass="stdTable-draggingHelper"
              useDragHandle
            />

          </table>
          <div className="center bigPadding-y">
            <Pagination nbPages={data.pagesCount} changePage={setPage} actPage={page} total={data.total} changeNbPage={setNbPerPage} nbPerPage={nbPerPage} />

          </div>

        </>
      )}
    </StaffContenu>
  );
};

export default Speakers;
