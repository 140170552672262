import React, { useEffect } from 'react';
import axios from 'axios';
import '../../less/main.less';
import { useDispatch, useSelector } from 'react-redux';
import Settings from 'luxon/src/settings';
import DateTime from 'luxon/src/datetime';
import ReactGA from 'react-ga';
import countries from 'i18n-iso-countries';
import * as StateAction from '../Redux/actions/State';
import ErrorBoundary from './ErrorBoundary/ErrorBoundary';
import { handleRestError } from '../Lib/misc/handleRestError';
import useInterface from '../Lib/useInterface';
import UnloggedApp from './UnloggedApp';
import LoggedApp from './LoggedApp';
import Bus from '../Lib/misc/Bus';
import FlashMessages from '../components/UI/FlashMessage/FlashMessages';

Settings.defaultLocale = 'en';
Settings.defaultZoneName = process.env.TIMEZONE;
axios.defaults.withCredentials = true;
axios.defaults.https = process.env.API_URL.search('https') > -1;
axios.defaults.baseURL = process.env.API_URL;

window.flash = (message) => Bus.emit('flash', ({ message }));
window.krpanoCommand = (message) => Bus.emit('krpanoCommand', ({ message }));

countries.registerLocale(require('i18n-iso-countries/langs/en.json'));

function transformResponses(obj) {
  if (!obj) {
    return;
  }
  const keys = Object.keys(obj);
  keys.forEach((key) => {
    const value = obj[key];
    if (typeof value === 'string') {
      if (value.length > 10 && value.search(/^\d{4}-\d{2}-\d{2}/i) > -1) {
        if (DateTime.fromISO(value).isValid) {
          obj[key] = DateTime.fromISO(value);
        }
      }
    } else if (typeof value === 'object') {
      transformResponses(obj[key]);
    }
  });
}

const App = () => {
  const loggedUtilisateur = useSelector((state) => state.stateDataReducer.loggedUtilisateur);
  const config = useSelector((state) => state.stateDataReducer.config);
  const dispatch = useDispatch();
  const { sendErrorMessage } = useInterface();

  useEffect(() => {
    if (process.env.SITENAME === 'www') {
      ReactGA.initialize('UA-188057848-1');
      ReactGA.pageview(window.location.pathname + window.location.search);
    }
  }, [config]);

  axios.interceptors.response.use((response) => {
    transformResponses(response);
    return response;
  }, (error) => {
    if (error.response && [474, 475].includes(error.response.status)) {
      if (error.response.status === 475) {
        sendErrorMessage('You have been blocked');
      }
      // Is no more logged
      dispatch({ type: StateAction.LOGOUT });
    }

    return Promise.reject(error);
  });

  const refreshState = async () => {
    try {
      const resp = await axios('state');
      if (resp.data.loggedUtilisateur) {
        dispatch({ type: StateAction.LOGIN, state: resp.data });
      }
      else if (loggedUtilisateur) { //on recoit une reponse de type "non logué", si l'utilisateur est tjrs dans le state, il faut le vider pour passer à l'état 'déconnecté'
        dispatch({ type: StateAction.LOGOUT });
      }
    } catch (err) {
      if (handleRestError(err).search('entreprise assignée') !== -1) {
        sendErrorMessage(handleRestError(err));
      }
      dispatch({ type: StateAction.LOGOUT });
      // is not logged
    }
  };

  useEffect(() => {
    refreshState();
  }, []);

  return (
    <ErrorBoundary>
      {!loggedUtilisateur ? <UnloggedApp /> : <LoggedApp />}
      <FlashMessages />
    </ErrorBoundary>
  );
};

export default App;
