import React, { useState } from 'react';
import axios from 'axios';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import useInterface from '../../Lib/useInterface';
import useRestClient from '../../Lib/useRestClient';
import Chargement from '../../components/UI/Chargement';
import truncate from '../../Lib/misc/truncate';
import ButtonAjouter from '../../components/UI/ButtonAjouter';
import Button from '../../components/UI/Button';
import Fieldset from '../../components/UI/Form/Fieldset';
import WysiwygTextarea from '../../components/UI/Form/Wysiwyg/WysiwygTextarea';

const InfoDeskFaqQuestionsEdit = ({ faqQuestion, onCancel, onSuccess }) => {
  const { sendRestErrorMessage, sendMessage } = useInterface();
  return (
    <>
      <h2 className="stdSubTitle midMargin-y">FAQ</h2>
      <Formik
        initialValues={faqQuestion || {
          question: '',
          reponse: '',
        }}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            if (faqQuestion && faqQuestion.id) {
              await axios.patch(`/staff/faq-questions/${faqQuestion.id}`, values);
              sendMessage('The question has been saved');
              onSuccess();
            } else {
              await axios.post('/staff/faq-questions', values);
              sendMessage('The question has been saved');
              onSuccess();
            }
          } catch (err) {
            sendRestErrorMessage(err);
          }
          setSubmitting(false);
        }}

        validationSchema={Yup.object().shape({
          question: Yup.string().required('You must provide this field'),
          reponse: Yup.string().required('You must provide this field'),
        })}
      >
        {({ values, isSubmitting }) => (
          <Form className="stdForm form-100">

            <Fieldset name="question" input={(otherProps) => <WysiwygTextarea {...otherProps} />}>Question</Fieldset>
            <Fieldset name="reponse" input={(otherProps) => <WysiwygTextarea {...otherProps} />}>Answer</Fieldset>

            <div className="rightAlign stdForm-submitLine">
              <Button type="submit" disabled={isSubmitting}>Save changes</Button>
            </div>
          </Form>
        )}
      </Formik>
      <Button variant="transparent" onClick={onCancel}>Cancel</Button>
    </>
  );
};

InfoDeskFaqQuestionsEdit.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  faqQuestion: PropTypes.shape({
    id: PropTypes.number.isRequired,
    question: PropTypes.string.isRequired,
    reponse: PropTypes.string.isRequired,
  }),
};
InfoDeskFaqQuestionsEdit.defaultProps = {
  faqQuestion: null,
};

const DragHandle = SortableHandle(() => <div className="stdHandle" />);
const SortableItem = SortableElement(({
  isDeleting, faqQuestion, doDeleteFaqQuestions, setDeletingFaqQuestion, setEditingFaqQuestion,
}) => (
  <tr key={faqQuestion.id}>
    <td><DragHandle /></td>
    <td>{truncate(faqQuestion.question.replace(/(<([^>]+)>)/gi, ''), 40)}</td>
    <td>{truncate(faqQuestion.reponse.replace(/(<([^>]+)>)/gi, ''), 40)}</td>

    {isDeleting ? (
      <td>
        <span>Are you sure ?</span>
        <div className="stdSplitActions stdSplitActions-confirm">
          <button onClick={() => setDeletingFaqQuestion(null)}><i className="ptl-close2" /> no</button>
          <button onClick={() => doDeleteFaqQuestions(faqQuestion)}><i className="ptl-valid" /> yes</button>
        </div>
      </td>
    ) : (
      <td className="stdSplitActions">
        <button type="button" onClick={() => setEditingFaqQuestion(faqQuestion)}>edit</button>
        <button type="button" onClick={() => setDeletingFaqQuestion(faqQuestion.id)}>delete</button>
      </td>
    )}
  </tr>
));

const SortableList = SortableContainer(({
  faqQuestions, setDeletingFaqQuestion, doDeleteFaqQuestions, setEditingFaqQuestion, deletingFaqQuestion,
}) => (
  <tbody>
    {faqQuestions.map((faqQuestion, index) => (
      <SortableItem
        key={faqQuestion.id}
        index={index}
        faqQuestion={faqQuestion}
        isDeleting={deletingFaqQuestion === faqQuestion.id}
        setDeletingFaqQuestion={setDeletingFaqQuestion}
        doDeleteFaqQuestions={doDeleteFaqQuestions}
        setEditingFaqQuestion={setEditingFaqQuestion}
      />
    ))}
  </tbody>
));

const InfoDeskFaqQuestions = () => {
  const { sendRestErrorMessage, sendMessage } = useInterface();
  const [lastAction, setLastAction] = useState(DateTime.local());
  const [deletingFaqQuestion, setDeletingFaqQuestion] = useState(null);
  const [editingFaqQuestion, setEditingFaqQuestion] = useState(null);
  const [adding, setAdding] = useState(false);
  const [faqQuestions] = useRestClient('/faq-questions', null, [lastAction]);

  const doDeleteFaqQuestions = async (faqQuestion) => {
    try {
      await axios.delete(`/staff/faq-questions/${faqQuestion.id}`);
      setDeletingFaqQuestion(null);
      setLastAction(DateTime.local());
      sendMessage('The question has been deleted');
    } catch (error) {
      sendRestErrorMessage(error);
    }
    return false;
  };
  const doSwapQuestions = async (from, to) => {
    try {
      await axios.get(`/staff/faq-questions/swap/${from}/${to}`);
      setLastAction(DateTime.local());
    } catch (error) {
      sendRestErrorMessage(error);
    }
    return false;
  };

  if (!faqQuestions) {
    return <Chargement />;
  }

  if (editingFaqQuestion || adding) {
    return (
      <InfoDeskFaqQuestionsEdit
        faqQuestion={adding ? null : editingFaqQuestion}
        onCancel={() => {
          setEditingFaqQuestion(null);
          setAdding(false);
        }}
        onSuccess={() => {
          setEditingFaqQuestion(null);
          setAdding(false);
          setLastAction(DateTime.local());
        }}
      />
    );
  }

  return (
    <>
      <ButtonAjouter className="floatRight" onClick={() => setAdding(true)}>Add more</ButtonAjouter>
      <h2 className="stdSubTitle midMargin-y">FAQ</h2>
      <table className="table-100 stdTable">
        <thead>
          <tr>
            <th>Question</th>
            <th>Answer</th>
            <th />
          </tr>
        </thead>
        <SortableList
          faqQuestions={faqQuestions}
          onSortEnd={({ oldIndex, newIndex }) => {
            doSwapQuestions(faqQuestions[oldIndex].id, faqQuestions[newIndex].id);
          }}
          setDeletingFaqQuestion={setDeletingFaqQuestion}
          setEditingFaqQuestion={setEditingFaqQuestion}
          doDeleteFaqQuestions={doDeleteFaqQuestions}
          deletingFaqQuestion={deletingFaqQuestion}
          helperClass="stdTable-draggingHelper"
          useDragHandle
        />
      </table>
    </>
  );
};

export default InfoDeskFaqQuestions;
