import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import DateTime from 'luxon/src/datetime';
import Input from './Input';

const InputDatetime = ({
  onChange, value, ...props
}) => {
  const strFormat = 'mm/dd/YYYY HH:mm';
  const format = 'LL/dd/yyyy HH:mm';

  // useEffect(() => {
  //   if (typeof value === 'object') {
  //     onChange(value.toFormat(format));
  //   }
  // }, [value]);

  return (
    <Input
      {...props}
      placeholder={strFormat}
      // value={value}
      value={typeof value === 'object' ? value.toFormat(format) : value}
      onChange={(newVal) => {
        const testDate = DateTime.fromFormat(newVal, format);
        if (testDate.invalid) {
          onChange(newVal);
          return;
        }
        onChange(testDate);
      }}
    />
  );
};

InputDatetime.propTypes = {
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  onChange: PropTypes.func.isRequired,
};

InputDatetime.defaultProps = {
  value: '',
};
export default InputDatetime;
