import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';


const Textarea = ({
  hasError, isTouched, onChange, ...props
}) => (
  <textarea
    {...props}
    onChange={(e) => onChange(e.target.value)}
    className={clsx(
      'stdTextarea',
      hasError && isTouched && 'stdTextarea-error',
      !hasError && isTouched && 'stdTextarea-correct',
    )}
  />
);


Textarea.propTypes = {
  onChange: PropTypes.func.isRequired,
  hasError: PropTypes.bool,
  isTouched: PropTypes.bool,
};

Textarea.defaultProps = {
  hasError: false,
  isTouched: false,
};

export default Textarea;
