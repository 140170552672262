import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import clsx from 'clsx';
import axios from 'axios';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import useRestClient from '../../../Lib/useRestClient';
import Chargement from '../../../components/UI/Chargement';
import Category from '../../../Lib/Category';
import useInterface from '../../../Lib/useInterface';
import Select from '../../../components/UI/Form/Select/Select';
import Button from '../../../components/UI/Button';
import NotificationMessageType from '../../../Lib/NotificationMessageType';
import usePubsub from "../../../Lib/usePubsub";

const GreenRoomNotificationsPickMessage = ({ value, onPickmessage }) => {
  const [notificationsMessages] = useRestClient(`notification-messages?type=${NotificationMessageType.ADMIN}`);

  if (!notificationsMessages) {
    return <Chargement />;
  }
  return (
    <Select
      value={value}
      options={(notificationsMessages).map(({ content }) => ({
        value: content,
        label: content,
      }))}
      onChange={onPickmessage}
    />
  );
};

GreenRoomNotificationsPickMessage.propTypes = {
  value: PropTypes.string,
  onPickmessage: PropTypes.func.isRequired,
};
GreenRoomNotificationsPickMessage.defaultProps = {
  value: null,
};

const GreenRoomNotifications = ({ speakers, programmeId }) => {
  const [lastAction, setLastAction] = useState(DateTime.local());
  const [formInitialValues, setFormInitialValues] = useState({
    dest: null,
    message: null,
  });
  const [notifications] = useRestClient(`staff/${programmeId}/notifications`, null, [lastAction]);

  const { sendRestErrorMessage, sendMessage } = useInterface();

  usePubsub(`programme/${programmeId}/notifications`, () => {
    setLastAction(DateTime.local());
  });

  const dummyEnd = useRef();
  useEffect(() => {
    if (dummyEnd.current) {
      dummyEnd.current.parentNode.scrollTop = dummyEnd.current.offsetTop;
    }
  }, [notifications && notifications.length]);

  if (!notifications) {
    return <Chargement />;
  }

  const deleteNotification = async (idNotification) => {
    try {
      await axios.delete(`/staff/notifications/${idNotification}`);
      sendMessage('The notification has been deleted');
    } catch (error) {
      sendRestErrorMessage(error);
    }
    return false;
  };
  const getFromLabel = (author, dest) => {
    if (author.category === Category.STAFF) {
      if (dest) {
        return (
          <>Staff to <button
            type="button"
            onClick={() => {
              setFormInitialValues({ dest: speakers.find(({ id }) => id === dest.id), message: null });
            }}
          >{dest.firstname}
          </button>
          </>
        );
      }
      return 'Staff';
    }

    return (
      <button
        type="button"
        onClick={() => {
          setFormInitialValues({ dest: speakers.find(({ id }) => id === author.id), message: null });
        }}
      >{author.firstname}
      </button>
    );
  };

  return (
    <div className="component-staffGreenRoom-notifications">
      <div>
        <h2>To speakers</h2>
        <div>
          <Formik
            enableReinitialize
            initialValues={formInitialValues}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              try {
                await axios.post(`/staff/${programmeId}/notifications`, values);
                sendMessage('The notification has been sent');
                resetForm();
              } catch (err) {
                sendRestErrorMessage(err);
              }
              setSubmitting(false);
            }}

            validationSchema={Yup.object().shape({
              // dest: Yup.object().required('You must provide this field'),
              message: Yup.string().required('You must provide this field'),
            })}
          >
            {({ values, setFieldValue, isSubmitting }) => (
              <Form className="stdForm form-100 leftAlign">
                <h3>Select destination speaker</h3>
                <Select
                  value={values.dest && values.dest.id}
                  options={speakers.map(({ id, firstname, lastname }) => ({
                    value: id,
                    label: `${firstname} ${lastname}`,
                  }))}
                  onChange={(newId) => setFieldValue('dest', speakers.find(({ id }) => id === newId))}
                />
                <br />
                <h3>Select message</h3>
                <GreenRoomNotificationsPickMessage onPickmessage={(message) => setFieldValue('message', message)} value={values.message} />

                <div className="midMargin-t rightAlign">
                  <Button type="submit" disabled={isSubmitting}>Envoyer</Button>
                </div>
              </Form>
            )}
          </Formik>

        </div>
      </div>
      <div>
        <h2>From speakers</h2>

        { notifications.length ? (
          <div className="component-staffGreenRoom-notifications-message-container">{notifications.map(({
            id, message, author, created, dest,
          }) => (
            <div key={id} className={clsx('component-staffGreenRoom-notifications-message', author.category === Category.STAFF && 'component-staffGreenRoom-notifications-message-fromStaff')}>
              <button type="button" onClick={() => deleteNotification(id)}><i className="ptl-close2" /></button>
              <span>{getFromLabel(author, dest)} {created && <>{created.toFormat('HH:mm')}</>} : </span>
              {message}
            </div>
          ))}
            <div style={{ float: 'left', clear: 'both' }} ref={dummyEnd} />
          </div>
        ) : <div className="center mid">No notifications so far</div>}

      </div>
    </div>
  );
};

GreenRoomNotifications.propTypes = {
  speakers: PropTypes.array.isRequired,
  programmeId: PropTypes.number.isRequired,
};

export default GreenRoomNotifications;
