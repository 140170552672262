import React, { useState } from 'react';
import { DateTime } from 'luxon';
import axios from 'axios';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import useInterface from '../../../../Lib/useInterface';
import useRestClient from '../../../../Lib/useRestClient';
import Chargement from '../../../../components/UI/Chargement';
import StaffContenu from '../../StaffContenu';
import ButtonAjouter from '../../../../components/UI/ButtonAjouter';
import truncate from '../../../../Lib/misc/truncate';
import Fieldset from '../../../../components/UI/Form/Fieldset';
import FieldsetFile from '../../../../components/UI/Form/FieldsetFile';
import Button from '../../../../components/UI/Button';

const MgfReportEdit = ({ mgfReport, onCancel, onSuccess }) => {
  const { sendRestErrorMessage, sendMessage } = useInterface();
  const [uploadingData, setUploadingData] = useState({
    file: null,
  });

  return (
    <Formik
      initialValues={mgfReport || {
        name: '',
        type: '',
      }}
      onSubmit={async (values, { setSubmitting }) => {
        const formData = new FormData();
        for (const key in values) {
          formData.set(key, values[key]);
        }
        for (const key in uploadingData) {
          if (uploadingData[key]) {
            formData.append(key, uploadingData[key]);
          }
        }

        try {
          if (mgfReport && mgfReport.id) {
            formData.append('_method', 'PATCH');
            await axios.post(`/staff/mgf-reports/${mgfReport.id}`, formData);
            sendMessage('The question has been saved');
            onSuccess();
          } else {
            await axios.post('/staff/mgf-reports', formData);
            sendMessage('The question has been saved');
            onSuccess();
          }
        } catch (err) {
          sendRestErrorMessage(err);
        }
        setSubmitting(false);
      }}

      validationSchema={Yup.object().shape({
        name: Yup.string().required('You must provide this field'),
        description: Yup.string().required('You must provide this field'),
      })}
    >
      {({ values, isSubmitting }) => (
        <Form className="stdForm form-100">

          <Fieldset placeholder="Title" name="name">Title</Fieldset>
          <Fieldset placeholder="Description" name="description">Description</Fieldset>
          <FieldsetFile name="report" value={values.file} onUpload={(file) => setUploadingData({ ...uploadingData, file })} extensions={['pdf']}>Upload report pdf</FieldsetFile>

          <div className="rightAlign stdForm-submitLine">
            <Button variant="transparent" onClick={onCancel}>Cancel</Button>
            <Button type="submit" disabled={isSubmitting || (!uploadingData.file && !values.file)}>Save changes</Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

MgfReportEdit.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  mgfReport: PropTypes.shape({
    id: PropTypes.number.isRequired,
    question: PropTypes.string.isRequired,
    reponse: PropTypes.string.isRequired,
  }),
};
MgfReportEdit.defaultProps = {
  mgfReport: null,
};
const Reports = () => {
  const { sendRestErrorMessage, sendMessage } = useInterface();
  const [lastAction, setLastAction] = useState(DateTime.local());
  const [deletingMgfReport, setDeletingMgfReport] = useState(null);
  const [editingMgfReport, setEditingMgfReport] = useState(null);
  const [adding, setAdding] = useState(false);
  const [mgfReports] = useRestClient('/mgf-reports', null, [lastAction]);

  const doDeleteMgfReports = async (mgfReport) => {
    try {
      await axios.delete(`/staff/mgf-reports/${mgfReport.id}`);
      setDeletingMgfReport(null);
      setLastAction(DateTime.local());
      sendMessage('The partner has been deleted');
    } catch (error) {
      sendRestErrorMessage(error);
    }
    return false;
  };

  if (!mgfReports) {
    return <Chargement />;
  }

  if (editingMgfReport || adding) {
    return (
      <StaffContenu titre="MGF Reports"><MgfReportEdit
        mgfReport={adding ? null : editingMgfReport}
        onCancel={() => {
          setEditingMgfReport(null);
          setAdding(false);
        }}
        onSuccess={() => {
          setEditingMgfReport(null);
          setAdding(false);
          setLastAction(DateTime.local());
        }}
      />
      </StaffContenu>
    );
  }

  return (
    <StaffContenu titre="MGF Reports" rightContent={!!mgfReports.length && <ButtonAjouter className="floatRight" onClick={() => setAdding(true)}>Add more</ButtonAjouter>}>

      {mgfReports.length ? (
        <table className="table-100 stdTable">
          <thead>
            <tr>
              <th>Name</th>
              <th>Description</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {mgfReports.map((mgfReport) => (
              <tr key={mgfReport.id}>
                <td>{truncate(mgfReport.name, 40)}</td>
                <td>{mgfReport.description}</td>

                {deletingMgfReport === mgfReport.id ? (
                  <td>
                    <span>Are you sure ?</span>
                    <div className="stdSplitActions stdSplitActions-confirm">
                      <button onClick={() => setDeletingMgfReport(null)}><i className="ptl-close2" /> no</button>
                      <button onClick={() => doDeleteMgfReports(mgfReport)}><i className="ptl-valid" /> yes</button>
                    </div>
                  </td>
                ) : (
                  <td className="stdSplitActions">
                    <button type="button" onClick={() => setEditingMgfReport(mgfReport)}>edit</button>
                    <button type="button" onClick={() => setDeletingMgfReport(mgfReport.id)}>delete</button>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <div className="center">
          <div className="mid midMargin-b">No report has been uploaded</div>
          <ButtonAjouter onClick={() => setAdding(true)}>Add a report</ButtonAjouter>
        </div>
      )}

    </StaffContenu>
  );
};
export default Reports;
