import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import doublesFlechesDroites from '../../../img/FII/doublesFlechesDroites.svg';
import getWindowSize from '../../Lib/misc/DOM/getWindowSize';
import iOS from '../../Lib/misc/isOs';

const Carousel = ({
  children, className, count, ...props
}) => {
  const [curPage, setCurPage] = useState(0);

  let correctedChildren = children;
  if (!Array.isArray(correctedChildren)) {
    correctedChildren = [correctedChildren];
  }
  const getCurrentCount = () => {
    console.log(getWindowSize().width);

    switch (count) {
      case 4: {
        if (getWindowSize().width <= 480) {
          return 1;
        }
        if (getWindowSize().width <= 820) {
          return 2;
        }
        return count;
        break;
      }
      default:
        return count;
    }
  };

  const setPage = (newPage) => {
    let correctedNewPage = newPage;
    const nbPage = correctedChildren.length / getCurrentCount();
    if (correctedNewPage >= nbPage) {
      correctedNewPage = 0;
    }
    if (correctedNewPage < 0) {
      correctedNewPage = nbPage - 1;
    }
    setCurPage(correctedNewPage);
  };

  const isScrollable = () => correctedChildren.length / getCurrentCount() > 1;

  const left = !iOS() ? `calc(-${curPage * 100}% - ${curPage}rem)` : `calc(-${curPage * 100}% - ${curPage}rem)`;

  return (
    <div
      className={clsx(
        'stdCarousel',
        `stdCarousel-${count}`,
        className,
      )}
      {...props}
    >

      <button disabled={!isScrollable()} type="button" onClick={() => setPage(curPage - 1)}><img src={doublesFlechesDroites} /></button>
      <div className="stdCarousel-container"><div style={{ left }}>
        {correctedChildren.map((child) => <div className="stdCarousel-el">{child}</div>)}
      </div>
      </div>
      <button disabled={!isScrollable()} type="button" onClick={() => setPage(curPage + 1)}><img src={doublesFlechesDroites} /></button>
    </div>
  );
};

Carousel.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  className: PropTypes.string,
  count: PropTypes.number,
};

Carousel.defaultProps = {
  className: null,
  count: 6,
};

export default Carousel;
