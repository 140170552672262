import React from 'react';
import PropTypes from 'prop-types';
import Textarea from './Textarea';
import Fieldset from './Fieldset';


const FieldsetTextarea = ({
  children, ...props
}) => (

  <Fieldset {...props} input={(inputProps) => <Textarea {...inputProps} {...props} />}>{children}</Fieldset>
);


FieldsetTextarea.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

FieldsetTextarea.defaultProps = {
  children: null,
};

export default FieldsetTextarea;
