import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import ButtonClose from '../../components/UI/ButtonClose';
import useBackButton from '../../Lib/useBackButton';
import icon from '../../../img/FII2/esg/FII Institute s Annual Report 2022 Charting A Course For Global Impact-1 copy.jpg';
import report from '../../../img/FII2/esg/FII Institute s Annual Report 2022 Charting A Course For Global Impact.pdf';

const LibraryESG = ({ onClose }) => {
  useBackButton(onClose);

  return (
    <div className={clsx('component-libraryESG')}>

      <div>
        <ButtonClose backgrounded onClick={onClose} />
        <a className="report" href={report} target="_blank" rel="noopener"><img src={icon} /></a>
      </div>
    </div>
  );
};

LibraryESG.propTypes = {
  onClose: PropTypes.func.isRequired,
};

LibraryESG.defaultProps = {};

export default LibraryESG;
