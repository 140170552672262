import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import * as ReactDOM from 'react-dom';
import clsx from 'clsx';
import useKey from '../../../Lib/useKey';
import ButtonClose from '../ButtonClose';

const Popup = ({
  children, withoutCloseBtn, variant, onClosePopup, className,
}) => {
  const poupRef = useRef();

  const doClosePopup = () => {
    poupRef.current.classList.add('stdPopup-disappear');
  };

  useKey({
    keyCode: 27,
    callback: doClosePopup,
  });

  useEffect(() => {
    if (poupRef.current) {
      poupRef.current.addEventListener('animationend', (e) => {
        if (e.target === poupRef.current && e.animationName === 'fade-reverse') {
          onClosePopup();
        }
      });
    }
  });

  return ReactDOM.createPortal(
    <div
      className={clsx(
        'stdPopup',
        'stdPopup-appear',
        variant && variant.split(' ').map((el) => `stdPopup-${el}`),
        className,
      )}
      ref={poupRef}
    >
      <div>
        { !withoutCloseBtn && <ButtonClose onClick={onClosePopup} backgrounded />}
        { children }
      </div>
    </div>, document.getElementById('popupContainer'),
  );
};

Popup.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  withoutCloseBtn: PropTypes.bool,
  onClosePopup: PropTypes.func.isRequired,
  className: PropTypes.string,
  variant: PropTypes.string,
};

Popup.defaultProps = {
  icon: null,
  withoutCloseBtn: false,
  children: '',
  className: null,
  variantClose: null,
  variant: null,
};

export default Popup;
