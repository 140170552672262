import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import Button from '../../../components/UI/Button';
import Fieldset from '../../../components/UI/Form/Fieldset';

const AddQuizPopup = ({ onFinish }) => (
  <>
    <h2 className="stdSubTitle midMargin-b">Adding quiz</h2>
    <Formik
      initialValues={{
        date: null,
      }}
      onSubmit={async (values, { setSubmitting }) => {
        onFinish(values);
        setSubmitting(false);
      }}

      validationSchema={Yup.object().shape({
        date: Yup.string().required('You must provide this field').typeError('You must provide this field').matches(/^\d{2}\/\d{2}\/\d{4}$/, 'The format should be mm/dd/yyyy'),
      })}
    >

      {({ values, setFieldValue, isSubmitting }) => (
        <Form className="stdForm form-100 leftAlign">
          <Fieldset placeholder="mm/dd/yyyy" name="date" />
          <div className="rightAlign midMargin-t stdForm-submitLine">
            <Button type="submit" disabled={isSubmitting}>Add a quiz</Button>
          </div>
        </Form>
      )}
    </Formik>
  </>
);

AddQuizPopup.propTypes = {
  onFinish: PropTypes.func.isRequired,

};

export default AddQuizPopup;
