import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Redirect, Route, Switch } from 'react-router';
import PropTypes from 'prop-types';
import axios from 'axios';
import { DateTime } from 'luxon';
import useRestClient from '../../../Lib/useRestClient';
import Chargement from '../../../components/UI/Chargement';
import StaffContenu from '../StaffContenu';
import useInterface from '../../../Lib/useInterface';
import EditProgramme from './EditProgramme';
import ButtonAjouter from '../../../components/UI/ButtonAjouter';
import truncate from '../../../Lib/misc/truncate';

import { range } from 'lodash';
const ProgramDay = ({ evenementId, nbDay }) => {
  const { sendRestErrorMessage, sendMessage } = useInterface();
  const [lastAction, setLastAction] = useState(-1);
  const [deletingProgramme, setDeletingProgramme] = useState(null);
  const [editingProgramme, setEditingProgramme] = useState(null);
  const [adding, setAdding] = useState(false);
  const [evenement] = useRestClient(`evenements/${evenementId}?jour=${nbDay - 1}`, null, [lastAction]);

  const doDeleteProgramme = async (programme) => {
    try {
      await axios.delete(`/staff/programmes/${programme.id}`);
      setDeletingProgramme(null);
      setLastAction(DateTime.local());
      sendMessage('The partner has been deleted');
    } catch (error) {
      sendRestErrorMessage(error);
    }
    return false;
  };

  const createZoom = async (programmeId) => {
    try {
      await axios.get(`/staff/programmes/create-zoom/${programmeId}`);
      setLastAction(DateTime.local());
      sendMessage('The zoom room has been created');
    } catch (error) {
      sendRestErrorMessage(error);
    }
    return false;
  };

  if (!evenement) {
    return <Chargement />;
  }

  if ((!editingProgramme && !adding && !evenement.programmes.length)) {
    return (
      <StaffContenu titre={`Day : ${nbDay}`}>
        <div className="center">
          <div className="mid smallMargin-b">You have created no events so far.</div>
          <ButtonAjouter onClick={() => setAdding(true)}>Add an event</ButtonAjouter>
          <br />
        </div>
      </StaffContenu>
    );
  }

  return (
    <StaffContenu titre={`Day : ${nbDay}`} rightContent={(!editingProgramme && !adding) && <ButtonAjouter onClick={() => setAdding(true)}>Add more</ButtonAjouter>}>
      {(editingProgramme || adding) ? (
        <EditProgramme
          nbDay={nbDay}
          evenementId={evenement.id}
          programmeId={editingProgramme ? editingProgramme.id : null}
          onCancel={() => {
            setEditingProgramme(null);
            setAdding(false);
          }}
          onSuccess={() => {
            setEditingProgramme(null);
            setAdding(false);
            setLastAction(DateTime.local());
          }}
        />
      ) : (
        <table className="stdTable table-100">
          <thead>
            <tr>
              <th>Name</th>
              <th>Time</th>
              <th>Speak/mod</th>
              <th>Zoom</th>
              <th />
            </tr>
          </thead>
          <tbody>
            { evenement.programmes.map((programme) => (
              <tr key={programme.id}>
                <td style={{ wordBreak: 'break-all' }}><button type="button" onClick={() => setEditingProgramme(programme)}>{programme.bHideInProgram && '(hidden)'} {truncate(programme.name, 40)}</button></td>
                <td>{programme.heure} <br /> {programme.duree}min</td>
                <td>
                  { !!programme.speakersNames.length && <div><b className="bold">Sp :</b> {programme.speakersNames.join(', ')}</div>}
                  { !!programme.moderatorsNames.length && <div><b className="bold">Mod :</b> {programme.moderatorsNames.join(', ')}</div>}

                </td>
                <td>{programme.zoomReunionId ? <>{programme.zoomReunionId} <br />   {programme.zoomPassword}</> : <button type="button" onClick={() => createZoom(programme.id)} disabled={programme.zoomReunionId}>create</button>}</td>
                <td />

                {deletingProgramme === programme.id ? (
                  <td>
                    <span>Are you sure ?</span>
                    <div className="stdSplitActions stdSplitActions-confirm">
                      <button onClick={() => setDeletingProgramme(null)}><i className="ptl-close2" /> no</button>
                      <button onClick={() => doDeleteProgramme(programme)}><i className="ptl-valid" /> yes</button>
                    </div>
                  </td>
                ) : (
                  <td className="stdSplitActions stdSplitActions-small">
                    <button type="button" onClick={() => setEditingProgramme(programme)}>edit</button>
                    <button type="button" onClick={() => setDeletingProgramme(programme.id)}>delete</button>
                  </td>
                )}

              </tr>
            ))}
          </tbody>
        </table>
      ) }
    </StaffContenu>
  );
};

ProgramDay.propTypes = {
  evenementId: PropTypes.number.isRequired,
  nbDay: PropTypes.number.isRequired,
};

const Program = ({ evenement }) => (
  <div className="component-staffProgram stdStaffSpaceWithMenu">
    <div className="stdStaffSpaceWithMenu-menu stdTab">
      <div className="stdTab-titre"><span>Program</span></div>
      <div className="stdTab-content stdStaffStdMenu">
        {range(evenement.duree).map((key) => <NavLink key={key} to={`/program/${key + 1}`}>{ key < 3 ? `Day ${key + 1}` : 'Workshops'}<div>{evenement.date.plus({ days: key }).toFormat('LL/dd/yyyy')} <span className="small">{evenement.programmePerDay[key]} events</span></div></NavLink>)}

      </div>
    </div>
    <div className="stdStaffSpaceWithMenu-content">
      <Switch>
        <Route exact path="/program/1" render={() => <ProgramDay evenementId={evenement.id} nbDay={1} />} />
        <Route exact path="/program/2" render={() => <ProgramDay evenementId={evenement.id} nbDay={2} />} />
        <Route exact path="/program/3" render={() => <ProgramDay evenementId={evenement.id} nbDay={3} />} />
        <Redirect from="*" to="/program/1" />
      </Switch>

    </div>
  </div>
);

Program.propTypes = {
  evenement: PropTypes.shape({
    date: PropTypes.object.isRequired,
    programmePerDay: PropTypes.array.isRequired,
  }).isRequired,
};
export default Program;
