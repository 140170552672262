import React from 'react';
import useRestClient from '../../Lib/useRestClient';
import Chargement from '../../components/UI/Chargement';
import Program from './Program/Program';

const Programs = () => {
  const [evenements] = useRestClient('evenements');
  if (!evenements) {
    return <Chargement />;
  }

  // eslint-disable-next-line react/jsx-no-undef
  return <Program evenement={evenements[0]} />;
};

export default Programs;
