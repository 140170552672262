import React from 'react';
import PropTypes from 'prop-types';
import Select from './Select';
import useRestClient from '../../../../Lib/useRestClient';
import Chargement from '../../Chargement';

const SelectUtilisateur = ({
  byRole, value, onChange, ...props
}) => {
  let formattedByRole = byRole;
  if (formattedByRole && typeof (formattedByRole) !== 'object') {
    formattedByRole = [formattedByRole];
  }

  const url = formattedByRole ? `staff/utilisateurs?roles=${formattedByRole.join(',')}&dontPaginate=1` : 'utilisateurs';
  const [utilisateurs] = useRestClient(url);
  if (!utilisateurs) {
    return <Chargement />;
  }


  return (
    <Select
      withFilter
      value={value ? value.map(({ id }) => id) : []}
      onChange={(arNewValuesInt) => {
        onChange(utilisateurs.filter(({ id }) => arNewValuesInt.includes(id)));
      }}
      multiple
      options={utilisateurs.map((utilisateur) => ({
        value: utilisateur.id,
        label: `${utilisateur.firstname} ${utilisateur.lastname}`,
      }))}
    />
  );
};

SelectUtilisateur.propTypes = {
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  onChange: PropTypes.func.isRequired,
  byRole: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
};

SelectUtilisateur.defaultProps = {
  value: null,
  byRole: null,
};

export default SelectUtilisateur;
