import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import book1 from '../../../img/FII/Bookselves/Cancer Vaccine Fairness - Tumor No More-1 copy.jpg';
import book1pdf from '../../../img/FII/Bookselves/Cancer Vaccine Fairness - Tumor No More.pdf';
import book2 from '../../../img/FII/Bookselves/Circular-Carbon-1 copy.jpg';
import book2pdf from '../../../img/FII/Bookselves/Circular-Carbon.pdf';
import book3 from '../../../img/FII/Bookselves/Impact June 2022 -1 copy.jpg';
import book3pdf from '../../../img/FII/Bookselves/Impact June 2022 .pdf';
import book4 from '../../../img/FII/Bookselves/Lifting The Language Barrier-1 copy.jpg';
import book4pdf from '../../../img/FII/Bookselves/Lifting The Language Barrier.pdf';
import book5 from '../../../img/FII/Bookselves/Powering Up - A Revolution In Robotics And Artificial Intelligence-1 copy.jpg';
import book5pdf from '../../../img/FII/Bookselves/Powering Up - A Revolution In Robotics And Artificial Intelligence.pdf';
import book6 from '../../../img/FII/Bookselves/Spotlight June 2022 - Teaching Robots-1 copy.jpg';
import book6pdf from '../../../img/FII/Bookselves/Spotlight June 2022 - Teaching Robots.pdf';
import book7 from '../../../img/FII/Bookselves/Spotlight Series - Calling Flood And Fire-1 copy.jpg';
import book7pdf from '../../../img/FII/Bookselves/Spotlight Series - Calling Flood And Fire.pdf';
import book8 from '../../../img/FII/Bookselves/Spotlight Series - Disease Data-1 copy.jpg';
import book8pdf from '../../../img/FII/Bookselves/Spotlight Series - Disease Data.pdf';
import book9 from '../../../img/FII/Bookselves/Spotlight Series - Toilets For The Future-1 copy.jpg';
import book9pdf from '../../../img/FII/Bookselves/Spotlight Series - Toilets For The Future.pdf';
import book10 from '../../../img/FII/Bookselves/Sustainable Fashion - Radical Rethink Needed On Global Clothing Trade-1 copy.jpg';
import book10pdf from '../../../img/FII/Bookselves/Sustainable Fashion - Radical Rethink Needed On Global Clothing Trade.pdf';
import book11 from '../../../img/FII/Bookselves/The Nuanced Application Of ESG-1 copy.jpg';
import book11pdf from '../../../img/FII/Bookselves/The Nuanced Application Of ESG.pdf';
import book12 from '../../../img/FII/Bookselves/The Right To Nutrition - How To Feed The World-1.jpg';
import book12pdf from '../../../img/FII/Bookselves/The Right To Nutrition - How To Feed The World.pdf';

import book2017pdf from '../../../img/FII/Books/2017_coffeetable.pdf';
import book2018pdf from '../../../img/FII/Books/2018_coffeetable.pdf';
import book2019pdf from '../../../img/FII/Books/2019_coffeetable.pdf';
import book2020pdf from '../../../img/FII/Books/2020_coffeetable.pdf';
import book2022pdf from '../../../img/FII/Books/2022.pdf';
import book2017 from '../../../img/FII/Books/2017.jpg';
import book2018 from '../../../img/FII/Books/2018.jpg';
import book2019 from '../../../img/FII/Books/2019.jpg';
import book2020 from '../../../img/FII/Books/2020.jpg';
import book2022 from '../../../img/FII/Books/2022-1 copy.jpg';

import pdf1j from '../../../img/FII2/postEvents/pdf1-1.jpg';
import pdf2j from '../../../img/FII2/postEvents/pdf2-1.jpg';
import pdf3j from '../../../img/FII2/postEvents/pdf3-1.jpg';
import pdf4j from '../../../img/FII2/postEvents/pdf4-1.jpg';
import pdf1 from '../../../img/FII2/postEvents/pdf1.pdf';
import pdf2 from '../../../img/FII2/postEvents/pdf2.pdf';
import pdf3 from '../../../img/FII2/postEvents/pdf3.pdf';
import pdf4 from '../../../img/FII2/postEvents/pdf4.pdf';


import ButtonClose from '../../components/UI/ButtonClose';
import useBackButton from '../../Lib/useBackButton';
import {
  PANORAMA_PAGE_LIBRARY_MAGAZINE,
  PANORAMA_PAGE_LIBRARY_POSTEVENT_REPORTS,
  PANORAMA_PAGE_LIBRARY_REPORTS,
} from '../Panorama';

const LibraryBooks = ({ type, onClose }) => {
  useBackButton(onClose);
  return (
    <div className={clsx('component-libraryBooks', `component-libraryBooks-${type}`)}>
      <div>
        <ButtonClose backgrounded onClick={onClose} />
        <div>
          { ((() => {
            switch (type) {
              case PANORAMA_PAGE_LIBRARY_REPORTS: {
                return (
                  <>
                    <a className="report" href={book2017pdf} target="_blank" rel="noopener"><img src={book2017} /></a>
                    <a className="report" href={book2018pdf} target="_blank" rel="noopener"><img src={book2018} /></a>
                    <a className="report" href={book2019pdf} target="_blank" rel="noopener"><img src={book2019} /></a>
                    <a className="report" href={book2020pdf} target="_blank" rel="noopener"><img src={book2020} /></a>
                    <a className="report" href={book2022pdf} target="_blank" rel="noopener"><img src={book2022} /></a>
                    {/*<a className="report" href={book2021pdf} target="_blank" rel="noopener"><img src={book2021} /></a>*/}
                  </>
                );
              }
              default:
              case PANORAMA_PAGE_LIBRARY_MAGAZINE: {
                return (
                  <>
                    <a href={book7pdf} target="_blank" rel="noopener"><img src={book7} /></a>
                    <a href={book8pdf} target="_blank" rel="noopener"><img src={book8} /></a>
                    <a href={book9pdf} target="_blank" rel="noopener"><img src={book9} /></a>
                    <a href={book10pdf} target="_blank" rel="noopener"><img src={book10} /></a>

                    <a href={book11pdf} target="_blank" rel="noopener"><img src={book11} /></a>
                    <a href={book12pdf} target="_blank" rel="noopener"><img src={book12} /></a>
                    <a href={book1pdf} target="_blank" rel="noopener"><img src={book1} /></a>
                    <a href={book2pdf} target="_blank" rel="noopener"><img src={book2} /></a>

                    <a href={book3pdf} target="_blank" rel="noopener"><img src={book3} /></a>
                    <a href={book4pdf} target="_blank" rel="noopener"><img src={book4} /></a>
                    <a href={book5pdf} target="_blank" rel="noopener"><img src={book5} /></a>
                    <a href={book6pdf} target="_blank" rel="noopener"><img src={book6} /></a>
                  </>
                );
              }
              case PANORAMA_PAGE_LIBRARY_POSTEVENT_REPORTS: {
                return (
                  <>
                    <a className="postEvent" href={pdf1} target="_blank" rel="noopener"><img src={pdf1j} /></a>
                    <a className="postEvent" href={pdf2} target="_blank" rel="noopener"><img src={pdf2j} /></a>
                    {/*<a className="postEvent" href={pdf3} target="_blank" rel="noopener"><img src={pdf3j} /></a>*/}
                    <a className="postEvent" href={pdf4} target="_blank" rel="noopener"><img src={pdf4j} /></a>
                  </>
                );
              }
            }
          })())}

        </div>
      </div>
    </div>
  );
};

LibraryBooks.propTypes = {
  type: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

LibraryBooks.defaultProps = {
};

export default LibraryBooks;
