import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Chargement from '../components/UI/Chargement';
import useInterface from '../Lib/useInterface';
import { PAGE_LIVE } from './Header/Header';
import Bus from "../Lib/misc/Bus";

const PANORAMA_PAGE_LOBBY_WELCOME = 'lobby_welcome';
const PANORAMA_PAGE_LOBBY_PLENARY = 'lobby_plenary';
const PANORAMA_PAGE_LOBBY_HELPDESK = 'lobby_helpDesk';
const PANORAMA_PAGE_LOBBY_LOUNGE = 'lobby_lounge';


const PANORAMA_PAGE_LIBRARY_MAGAZINE = 'library_magazine';
const PANORAMA_PAGE_LIBRARY_QUOTES = 'library_quotes';
const PANORAMA_PAGE_LIBRARY_HEALTHCARE = 'library_healthcare';
const PANORAMA_PAGE_LIBRARY_ESG = 'library_esg';
const PANORAMA_PAGE_LIBRARY_PRESENTATION = 'library_presentation';
const PANORAMA_PAGE_LIBRARY_REPORTS = 'library_reports';
const PANORAMA_PAGE_LIBRARY_POSTEVENT_REPORTS = 'library_postEventReports';


const PANORAMA_PAGE_LOBBY_FIIINSTUTELOUNGE = 'lobby_fiiInstituteLounge';
const PANORAMA_PAGE_LIBRARY_VIDEOGALLERY = 'library_videoGallery';
const PANORAMA_PAGE_LIBRARY_CONTENTCOFFEETAB = 'library_contentCoffeeTab';
const PANORAMA_PAGE_LIBRARY_CONTENTBOOK = 'library_contentBook';
const PANORAMA_PAGE_INSTITUTE_AI = 'institute_ai';
const PANORAMA_PAGE_INSTITUTE_ROBOTICS = 'institute_robotics';
const PANORAMA_PAGE_INSTITUTE_SUSTAINABILITY = 'institute_sustainability';

const Panorama = ({ onSetPanoramaPage }) => {
  const [isReady, setIsReady] = useState(false);
  const { changePage } = useInterface();
  useEffect(() => {
    window.sendBackEvent = (eventName) => {
      if (eventName === PANORAMA_PAGE_LOBBY_PLENARY) {
        changePage(PAGE_LIVE);
      } else {
        onSetPanoramaPage(eventName);
      }
      // console.log('sendBackEvent:', eventName);
    };
    window.setTimeout(() => {
      setIsReady(true);
      if (typeof (document.getElementById('pano')) !== 'undefined' && document.getElementById('pano') != null) {
        embedpano({
          swf: 'krpano/tour.swf', xml: 'krpano/tour.xml', target: 'pano', html5: 'auto', mobilescale: 1.0, passQueryParameters: true, debugmode: true,
        });
      }
    }, 500);
    return () => {
      window.sendBackEvent = null;
    };
  }, []);


  Bus.addListener('krpanoCommand', ({ message }) => {
    const krpano = document.getElementById('krpanoSWFObject');
    krpano.call(`changeSceneFromOutside(${message})`);
  });

  return (
    <>
      <div id="pano" className="component-panorama" />
    </>
  );
};

Panorama.propTypes = {
  onSetPanoramaPage: PropTypes.func.isRequired,
};

Panorama.defaultProps = {
};

export {
  PANORAMA_PAGE_LOBBY_WELCOME,
  PANORAMA_PAGE_LOBBY_HELPDESK,
  PANORAMA_PAGE_LOBBY_PLENARY,
  PANORAMA_PAGE_LOBBY_FIIINSTUTELOUNGE,
  PANORAMA_PAGE_LIBRARY_VIDEOGALLERY,
  PANORAMA_PAGE_LIBRARY_CONTENTCOFFEETAB,
  PANORAMA_PAGE_LIBRARY_CONTENTBOOK,
  PANORAMA_PAGE_INSTITUTE_AI,
  PANORAMA_PAGE_INSTITUTE_ROBOTICS,
  PANORAMA_PAGE_INSTITUTE_SUSTAINABILITY,

  PANORAMA_PAGE_LOBBY_LOUNGE,
  PANORAMA_PAGE_LIBRARY_MAGAZINE,
  PANORAMA_PAGE_LIBRARY_QUOTES,
  PANORAMA_PAGE_LIBRARY_HEALTHCARE,
  PANORAMA_PAGE_LIBRARY_ESG,
  PANORAMA_PAGE_LIBRARY_PRESENTATION,
  PANORAMA_PAGE_LIBRARY_REPORTS,
  PANORAMA_PAGE_LIBRARY_POSTEVENT_REPORTS,
};

export default Panorama;
