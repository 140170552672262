import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { DateTime } from 'luxon';
import axios from 'axios';
import Button from '../../components/UI/Button';
import useRestClient from '../../Lib/useRestClient';
import Chargement from '../../components/UI/Chargement';
import InterviewType from '../../Lib/InterviewType';
import Interview from './Interview';
import useInterface from '../../Lib/useInterface';

const Interviews = ({ isConclave }) => {
  const [idInterview, setIdInterview] = useState(null);
  const defaultElementLabel = isConclave ? 'conclave' : 'interview';
  const { sendRestErrorMessage } = useInterface();

  const [interviews] = useRestClient(`interviews?type=${isConclave ? InterviewType.CONCLAVE : InterviewType.INTERVIEW}`);

  const doJoinExternal = async (id) => {
    try {
      const result = await axios.get(`/interviews/${id}/getJoinUrl`);
      window.open(result.data.join_url, '_blank');
    } catch (error) {
      sendRestErrorMessage(error);
    }
    return false;
  };

  if (!interviews) {
    return <Chargement />;
  }

  if (idInterview) {
    return <Interview idInterview={idInterview} doJoinExternal={doJoinExternal} onBack={() => setIdInterview(null)} />;
  }

  return (
    <div className={clsx('component-interviews')}>
      <h1 className="stdTitle stdTitle-withBorder stdTitle-withMarginBot">Your upcoming {defaultElementLabel}s</h1>
      {/* <h1 className="stdTitle stdTitle-withBorder stdTitle-withMarginBot">Your upcoming {defaultElementLabel} {DateTime.local().setZone(process.env.TIMEZONE).toFormat('DD - HH:mm ZZZZ')}</h1> */}

      { interviews.length ? (
        <div className="component-interviews-el-container">
          { interviews.map(({
            id, titre, date, duree,
          }) => {
            const now = DateTime.local();
            const dateFin = date.plus({ minutes: duree });
            const disabled = now.minus({ minutes: 30 }) > dateFin || now.plus({ minutes: 30 }) < date;

            return (
              <div key={id} className={clsx('component-interviews-el', disabled && 'component-interviews-el-disabled')}>
                <div>{titre}</div>
                <div>{date.toFormat('DD - HH:mm')}</div>
                {/* <div>{date.toFormat('DD - HH:mm')} to {dateFin.toFormat('HH:mm ZZZZ')}</div> */}
                {/* <div>{now.toFormat('DD - HH:mm ZZZZ')}</div> */}
                <Button
                  onClick={() => {
                    // setIdInterview(id);
                    doJoinExternal(id);
                  }}
                  disabled={disabled}
                >Join the {defaultElementLabel}
                </Button>
              </div>
            );
          })}

        </div>
      ) : (
        <div className="stdNoElements" style={{ minHeight: '10rem' }}>
          You have no upcoming {defaultElementLabel}s
        </div>
      ) }
    </div>
  );
};

Interviews.propTypes = {
  isConclave: PropTypes.bool,
};

Interviews.defaultProps = {
  isConclave: false,
};

export default Interviews;
