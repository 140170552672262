import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Formik } from 'formik';
import axios from 'axios';
import * as Yup from 'yup';
import { useHistory } from 'react-router';
import StaffContenu from '../StaffContenu';
import useRestClient from '../../../Lib/useRestClient';
import useInterface from '../../../Lib/useInterface';
import Chargement from '../../../components/UI/Chargement';
import Fieldset from '../../../components/UI/Form/Fieldset';
import Button from '../../../components/UI/Button';
import FieldsetTextarea from '../../../components/UI/Form/FieldsetTextarea';
import NavButton from '../../../components/UI/NavButton';
import FieldsetFile from '../../../components/UI/Form/FieldsetFile';
import WysiwygTextarea from "../../../components/UI/Form/Wysiwyg/WysiwygTextarea";

const EditPartner = ({ match }) => {
  const partnerId = match && match.params ? match.params.partnerId : null;
  const [uploadingData, setUploadingData] = useState({
    logo: null,
  });

  const history = useHistory();
  const { sendRestErrorMessage, sendMessage } = useInterface();

  const [partner] = partnerId ? useRestClient(`partners/${partnerId}`) : [{
    name: '',
    description: '',
    logo: null,
  }];

  if (!partner) {
    return <Chargement />;
  }

  return (
    <StaffContenu titre={partnerId ? `Edit partner : ${partner.name}` : 'Add partner'}>

      <Formik
        initialValues={partner}
        onSubmit={async (values, { setSubmitting }) => {
          const formData = new FormData();
          for (const key in values) {
            formData.set(key, values[key]);
          }
          for (const key in uploadingData) {
            if (uploadingData[key]) {
              formData.set(key, uploadingData[key]);
            }
          }

          try {
            if (partnerId) {
              formData.append('_method', 'PATCH');
              await axios.post(`partners/${partnerId}`, formData);
              sendMessage('The partner has been modified');
            } else {
              await axios.post('partners', formData);
              sendMessage('The partner has been created');
            }
            history.push('/learning-space/partners');
          } catch (err) {
            sendRestErrorMessage(err);
          }
          setSubmitting(false);
        }}

        validationSchema={Yup.object().shape({
          name: Yup.string().required('You must provide this field'),
          description: Yup.string().required('You must provide this field'),
        })}
      >
        {({ isSubmitting }) => (
          <Form className="stdForm form-100">
            <Fieldset placeholder="Name" name="name">Name</Fieldset>
            <Fieldset placeholder="Certificate title" name="certificatTitre">Certificate title</Fieldset>
            <FieldsetFile name="logo" value={partner.logo} onUpload={(logo) => setUploadingData({ ...uploadingData, logo })} extensions={['jpg', 'png']}>Upload logo</FieldsetFile>

            <Fieldset name="description" input={(otherProps) => <WysiwygTextarea {...otherProps} />}>Description</Fieldset>

            <div className="rightAlign stdForm-submitLine">
              <NavButton to="/learning-space/partners" variant="transparent">Back to partners list</NavButton>
              <Button type="submit" disabled={isSubmitting}>Save and quit</Button>
            </div>
          </Form>
        )}
      </Formik>
    </StaffContenu>
  );
};

EditPartner.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      partnerId: PropTypes.string,
    }),
  }),
};
EditPartner.defaultProps = {
  match: null,
};
export default EditPartner;
