import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';

const MenuWithLink = ({ children, to, href }) => {
  const history = useHistory();
  return (
    <button
      type="button"
      onClick={() => {
        if (to) {
          history.push(to);
        }
        if (href) {
          window.location.href = href;
        }
      }}
    >{children}
    </button>
  );
};








MenuWithLink.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  to: PropTypes.string,
  href: PropTypes.string,
};

MenuWithLink.defaultProps = {
  to: null,
  href: null,
};
export default MenuWithLink;
